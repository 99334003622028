<template>
  <div
    class="form-group input-slider-wrapper"
    style="position: relative"
  >
    <QuestionTitle
      :question-title="questionTitle"
      :is-required="isRequired"
      :current-number="currentQuestionDisplayNumber"
      :total-number="totalQuestionNumber"
    />
    <div>
      <LanguageSelector :to-be-selected="questionSubtitle" />
    </div>
    <div
      :style="borderColor"
      class="mt-5 p-1 d-flex justify-content-center align-items-center question-border"
    >
      <div
        class="container-fluid row-fluid slider-wrapper justify-content-center ps-0 pe-0"
      >
        <div
          id="slider-value-container"
          :style="{ opacity: toolTipOpacity }"
          class="row-fluid ps-5 pe-5"
        >
          <div id="slider-value-subcontainer">
            <p
              :style="{ left: positionSliderDisplay + '%' }"
              style="user-select: none"
            >
              {{ currentValue }}<span />
            </p>
          </div>
        </div>
        <input
          v-model="currentValue"
          :min="minSliderValue"
          :max="maxSliderValue"
          type="range"
          class="row-fluid question-border ms-auto me-auto w-100 styled-slider"
          @pointerup="userHasChosenAnswer(currentValue)"
          @touchend="userHasChosenAnswer(currentValue)"
          @pointerdown="
            updateSliderTrack();
            showToolTip();
          "
        >
        <div class="row flex-nowrap px-0 mx-0 w-100 mb-2">
          <label class="slider-scale text-left col-6 ps-0">
            <span>
              {{ scale[0] }}
            </span></label>
          <label class="slider-scale col-6 pe-0 d-flex justify-content-end">
            <span>{{ scale[1] }}</span>
          </label>
        </div>
      </div>
    </div>
    <div
      :style="textOpacity"
      class="alert-required-message my-2"
    >
      <LanguageSelector :to-be-selected="validationLabel" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import log from 'loglevel';
import QuestionTitle from './QuestionTitle.vue';
import { mapState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    QuestionTitle,
  },
  props: {
    currentQuestionData: {
      type: Object,
      required: true,
    },
    showUserInputIsRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentQuestionNumber: {
      type: Number,
      required: true,
    },
    currentQuestionDisplayNumber: {
      type: Number,
      required: true,
    },
    totalQuestionNumber: {
      type: Number,
      required: true,
    },
    currentUserData: {
      type: Object,
      required: true,
    },
    validationLabel: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  emits: ['sendCurrentUserData'],

  data() {
    return {
      answerData: {},
      currentValue: 0,
      startingPosition: null,
      afterCorrection: false,
      offSetDisplay: 0,
      minValue: null,
      maxValue: null,
      valueRange: null,
      minSliderValue: null,
      maxSliderValue: null,
      rangeIsFromNegativeToPositive: null,
      toolTipOpacity: 0,
    };
  },
  computed: {
    ...mapState(useConsultationStore, [
      'isRtlScript',
    ]),

    returnCurrentValue() {
      return this.currentValue;
    },

    questionTitle() {
      return this.currentQuestionData.title;
    },

    questionSubtitle() {
      if (this.currentQuestionData.hasOwnProperty('description')) {
        return this.currentQuestionData.description;
      } else {
        return false;
      }
    },

    scale() {
      if (this.currentQuestionData.hasOwnProperty('scale')) {
        return this.currentQuestionData.scale;
      } else {
        return false;
      }
    },

    positionSliderDisplay() {
      const startingPosition = this.minSliderValue;
      const maxV = this.maxValue;
      const currentV = this.currentValue;

      let positionV = 0;

      const relV = 98 / maxV;
      positionV = 0 + currentV * relV + 1;

      positionV = 0 + 50;

      let positionDisplay;
      let startingPoint;
      let ratio;
      let offSett;

      if (this.rangeIsFromNegativeToPositive) {
        ratio = (this.currentValue - this.minSliderValue) / this.sliderRange;
        offSett = 99 * ratio + 1;
      } else {
        ratio =
          (this.currentValue - this.minSliderValue) /
          (this.maxSliderValue - this.minSliderValue);
        offSett = 99 * ratio + 1;
      }

      //	to prevent tooltip has a negative offset (which happens on creating this component)
      if (offSett < 0) {
        offSett = 1;
      }

      if (!this.isRtlScript) {
        return offSett;
      }
      return 100 - offSett;
    },

    isRequired() {
      return this.currentQuestionData.required;
    },

    borderColor() {
      return this.showUserInputIsRequired
        ? { borderColor: '#D40005' }
        : { borderColor: '#E6E6E6' };
    },

    textOpacity() {
      return this.showUserInputIsRequired ? { opacity: '1' } : { opacity: '0' };
    },
  },

  created() {
    this.answerData = this.currentQuestionData;

    //  initializing slider range
    this.minSliderValue = this.answerData.scale[0];
    this.sliderRange = Math.abs(
      this.answerData.scale[1] - this.answerData.scale[0]
    );
    this.maxSliderValue = this.answerData.scale[1];
    this.rangeIsFromNegativeToPositive =
      Math.sign(this.minSliderValue) === -1 ? true : false;

    //	make a switch to set a logical starting point of the slider
    if (this.rangeIsFromNegativeToPositive) {
      this.startingPosition = 0;
    } else {
      this.startingPosition = Math.round(
        (this.maxSliderValue - this.minSliderValue) / 2 + this.minSliderValue
      );
    }

    //  this should always be the case since in the store we create the array with userData for questions
    //  with at least an id and user_answered: false
    if (this.currentUserData) {
      //  in this case the user has chosen an answer before and has to retrigger the chosen answer
      if (
        this.currentUserData.user_has_answered &&
        this.currentUserData.raw_input_slider !== null
      ) {
        //	to make sure a chosen neutral position still counts as a choice
        this.afterCorrection = true;
        this.userHasChosenAnswer(this.currentUserData.raw_input_slider);
        //  current value needs also to be set here otherwise the slider won't visually show  it's previous state
        this.currentValue = this.currentUserData.raw_input_slider;
        this.updateSliderTrack();
      } else {
        this.answerData.user_has_answered = false;
        this.answerData.user_answered_string = null;
        this.answerData.raw_input_slider = null;
        this.currentValue = this.startingPosition;
      }
    } else {
      log.warn(
        'InputTypeSlider: Somethings wrong with the creation of userDataQuestions'
      );
      this.answerData.user_has_answered = false;
      this.answerData.user_answered_string = null;
      this.answerData.raw_input_slider = null;
      this.currentValue = this.startingPosition;
    }

    this.$emit('sendCurrentUserData', this.answerData);
  },

  mounted() {
    this.updateSliderTrack();
  },

  methods: {
    userHasChosenAnswer(value) {
      this.hideToolTip();

      //  when slider is in the start position / neutral position
      if (this.startingPosition === this.currentValue) {
        //  the correction is set to true after the slider has been moved from its neutral / start position
        //  with this we can detect if the user actually wants to choose the neutral value
        if (this.afterCorrection) {
          this.answerData.user_answered_string = value;
          this.answerData.raw_input_slider = value;
          this.answerData.question_number = this.currentQuestionNumber;
          this.answerData.user_has_answered = true;
          this.alertUser = false;
          this.$emit('sendCurrentUserData', this.answerData);
        } else {
          //  else neutral position stands for that the user has not chosen a value yet
          this.answerData.user_answered_string = null;
          this.answerData.raw_input_slider = null;
          this.answerData.user_has_answered = false;
          this.$emit('sendCurrentUserData', this.answerData);
        }
      } else {
        //  in all other cases it's clear that the user made an choice with the slider
        this.afterCorrection = true;
        this.answerData.user_answered_string = value;
        this.answerData.raw_input_slider = value;
        this.answerData.question_number = this.currentQuestionNumber;
        this.answerData.user_has_answered = true;
        this.alertUser = false;
        this.$emit('sendCurrentUserData', this.answerData);
      }
    },

    showToolTip() {
      this.toolTipOpacity = 1;
    },

    hideToolTip() {
      this.toolTipOpacity = 0;
    },

    updateSliderTrack() {
      for (const e of document.querySelectorAll(
        'input[type="range"].slider-progress'
      )) {
        e.style.setProperty('--value', e.value);
        e.style.setProperty('--min', e.min == '' ? '0' : e.min);
        e.style.setProperty('--max', e.max == '' ? '100' : e.max);
        e.addEventListener('input', () => e.style.setProperty('--value', e.value));
      }
    },
  },
});
</script>

<style scoped>
input {
  min-width: 1px;
}

.slider-scale {
  position: relative;
  font-weight: bold;
  user-select: none;
  background-color: transparent;
}

.lines-wrapper {
  position: relative;
  width: 100%;
  height: 30px;
}

p {
  color: #899;
  font-size: 20px;
  font-weight: 800;
}

p:after {
  content: '';
  font-size: 30px;
  font-weight: 800;
  padding-left: 1px;
}

#slider-value-container {
  display: block;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
  bottom: 50px;
  pointer-events: none;
  transition: opacity 0.2s;
}

#slider-value-subcontainer p {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 65px;
  height: 30px;
  color: #017cf7 !important;
  font-size: 18px;
  transform-origin: center -10px;
  transform: translateX(-50%);
  transition: margin-top 0.15s ease-in-out, opacity 0.15s ease-in-out;
  z-index: 1;
}

#slider-value-subcontainer span {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--bg-color-app);
  border-style: solid;
  border-color: grey;
  border-width: 1px;
  border-radius: 2px;
  z-index: -1;
}

#slider-value-subcontainer span:before {
  content: '';
  position: absolute;
  left: 26px;
  bottom: 0px;
  top: 24px;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-bottom: 10px solid var(--bg-color-app);
  transform: rotate(180deg);
}

input:not(:active) + #slider-value-container p {
  opacity: 0;
  pointer-events: none;
}
</style>
