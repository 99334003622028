<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import AppNavigation from './AppNavigation.vue';
import { useConsultationStore } from '../stores/useConsultationStore';
import CardPage from './CardPage.vue';

const consultationStore = useConsultationStore();

const nextDisabled = ref(consultationStore.nextDisabled);
const browserBackConfirm = ref(consultationStore.browserBackConfirm);

const isPanelParticipant = computed(() => consultationStore.isPanelParticipant);
const getPanelRejectLink = computed(() => consultationStore.getPanelRejectLink);

browserBackConfirm.value = false;

if (isPanelParticipant.value && getPanelRejectLink.value) {
  nextDisabled.value = false;
}

onMounted(() => {
  if (isPanelParticipant.value && getPanelRejectLink.value) {
    setTimeout(() => {
      window.open(getPanelRejectLink.value, '_self');
    }, 4000);
  }
});
</script>

<template>
  <div class="page-container">
    <CardPage>
      <div class="page-title">
        <h1>
          {{ $t('pagination.consent_declined_page_title') }}
        </h1>
      </div>
      <div
        v-if="!isPanelParticipant"
        class="page-content"
      >
        {{ $t('pagination.consent_declined_page_content') }}
      </div>
      <div
        v-else-if="isPanelParticipant"
        class="page-content mt-4 pt-2"
        style="border-top-style: solid; border-width: 1px"
      >
        <span v-if="getPanelRejectLink">
          {{ $t('pagination.panel_declined_page_panel_info') }}
        </span>
      </div>
      <div
        v-if="getPanelRejectLink"
        class="page-navigation"
      >
        <AppNavigation />
      </div>
    </CardPage>
  </div>
</template>
