<template>
  <div>
    <section
      :class="{ mobile: getMobileView }"
      class="choice-set-table"
    >
      <table
        ref="choiceSetHeader"
        :style="returnColumnWidth"
      >
        <thead>
          <tr class="first-row">
            <th
              :style="titleColspan"
              class="first-column"
            />
            <DiscreteChoiceHeader
              v-for="(option, index) in stageOptions"
              :key="option.id"
              :index="index"
              :option="option"
              :mobile-scroll-to-function="scrollToHeader"
            />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(attribute, index) in stageProperties"
            :key="attribute.id"
            :class="{
              'last-row': index === stageProperties.length - 1,
              odd: index % 2 === 0,
              even: index % 2 !== 0,
            }"
          >
            <td
              :style="titleColspan"
              class="first-column description-column"
            >
              <div
                :class="{ 'd-flex': !getMobileView }"
                class="cell-content justify-content-start align-items-center"
              >
                <div
                  :class="{ 'me-3': !getMobileView }"
                  class="info-button-wrapper ps-0"
                >
                  <font-awesome-layers
                    class="info-button button-hover-animation"
                    @click="openAttributeModal(attribute)"
                  >
                    <font-awesome-icon icon="square" />
                    <font-awesome-icon
                      class="info-button-i"
                      icon="info"
                      transform=""
                    />
                  </font-awesome-layers>
                </div>
                <LanguageSelector
                  :to-be-selected="getAttributeTitle(attribute)"
                  class="dce-attribute-title"
                  style="text-align: left"
                />
              </div>
            </td>
            <td
              v-for="option in stageOptions"
              :key="option.id"
            >
              <div
                class="cell-content d-flex justify-content-center align-items-center color-text-dark content-cell"
              >
                <div class="content-value">
                  <AttributeValueConverter
                    :current-attribute-data="attribute"
                    :option-id="option.id"
                  />
                </div>
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td
              :style="titleColspan"
              class="first-column"
            />
            <td
              v-for="(option, index) in stageOptions"
              :key="option.id"
            >
              <div class="cell-content">
                <div
                  :style="borderColor"
                  style="height: fit-content"
                  class="ps-0 radio-answer-option question-border d-flex flex-nowrap align-items-center"
                  @click="userHasChosenAnswer(index)"
                >
                  <div>
                    <input
                      :checked="checkedArray[index]"
                      :value="$t('pagination.dce_select_option_label')"
                      class="radio-answer-pseudo-button"
                      type="radio"
                    >

                    <div
                      class="mx-3 radio-answer-button d-flex justify-content-center align-items-center"
                    >
                      <span
                        :class="{ 'radio-button-active': checkedArray[index] === true }"
                        class="radio-answer-button-inner-circle"
                      />
                    </div>
                  </div>
                  <label
                    class="answer-option answer-option-radio-table-label d-flex align-items-center dce-option-label"
                  >
                    {{ $t('pagination.dce_select_option_label') }}
                  </label>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
      <div
        :style="textOpacity"
        class="alert-required-message alert-required-dce mt-2"
      >
        <LanguageSelector
          :to-be-selected="labelNegativeValidationMessage"
          :style="doubleResponseWidth"
        />
      </div>

      <div
        v-if="getMobileView"
        ref="scrollHint"
        class="scroll-hint"
      >
        <button
          class="btn"
          @click="scrollToHeader"
        >
          <font-awesome-icon
            icon="chevron-down"
            class="scroll-icon-down"
          />
        </button>
      </div>
    </section>

    <section
      v-if="showDoubleResponse"
      class="mt-4 choice-set-double-response"
    >
      <div :style="doubleResponseWidth">
        <InputTypeRadioTable
          ref="options"
          :key="doubleResponseOptions.id"
          :current-question-data="doubleResponseOptions"
          :show-user-input-is-required="alertUserForRequiredB"
          :current-question-number="0"
          :current-question-display-number="1"
          :total-question-number="doubleResponseOptions.length"
          :consent-mode="false"
          :show-required="false"
          :current-user-data="doubleResponseUserData"
          :validation-label="$t('pagination.input_validation_required')"
          @send-current-user-data="updateDoubleResponseData"
        />
      </div>
    </section>

    <section class="choice-set-attribute-detail-modal">
      <div
        v-if="showAttributeModal"
        class="dialog-backdrop"
        @click="showAttributeModal = !showAttributeModal"
      />
      <transition name="dialog-transition">
        <div
          v-if="showAttributeModal"
          class="p-3 dialog-modal-container"
        >
          <div
            class="p-0 ms-0 container-fluid row justify-content-end dialog-closing-button-container"
          >
            <font-awesome-layers
              class="dialog-closing-button-wrapper"
              @click="showAttributeModal = !showAttributeModal"
            >
              <font-awesome-icon
                icon="square"
                class="closing-square-icon"
              />
              <font-awesome-icon
                icon="times"
                class="closing-cross-icon"
              />
            </font-awesome-layers>
          </div>
          <div
            class="dialog-content container-fluid ps-3 ps-3 pb-3 pt-0 pt-md-3 p-md-5"
          >
            <div class="dialog-title row p-0 m-0">
              <LanguageSelector
                :to-be-selected="getAttributeTitle(chosenAttributeData)"
              />
            </div>
            <div class="mt-4 ms-0 me-0 dialog-description row color-text-dark">
              <p>
                <LanguageSelector :to-be-selected="chosenAttributeData.description" />
              </p>
            </div>
          </div>
        </div>
      </transition>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InputTypeRadioTable from './InputTypeRadioTable.vue';
import DiscreteChoiceHeader from './DiscreteChoiceHeader.vue';
import { mapState } from 'pinia';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    InputTypeRadioTable,
    DiscreteChoiceHeader,
  },
  props: {
    alertUserForRequiredA: Boolean,
    alertUserForRequiredB: Boolean,
  },

  emits: ['sendCurrentUserData'],

  data() {
    return {
      answerData: {},
      alertUser: null,
      checkedArray: [false, false],
      checkedArrayDoubleResponse: [false, false],
      columnWidth: null,
      screenWidth: null,
      showAttributeModal: false,
      chosenAttributeData: null,
    };
  },

  computed: {
    ...mapState(useInterfaceStore, [
      'getMobileView',
    ]),

    ...mapState(useConsultationStore, [
      'stage',
      'stageId',
      'getAnswerData',
      'stageOptions',
      'stageProperties',
      'stageOptions',
      'isRtlScript',
      'locale',
    ]),

    choiceSituationId() {
      return this.stage.fields.slug;
    },

    currentUserData() {
      if (this.getAnswerData[this.stageId] === undefined) {
        return {};
      }

      return this.getAnswerData[this.stageId].answers;
    },

    titleColspan() {
      if (this.getMobileView) {
        return {
          gridColumn: `span ${this.stageOptions.length} / span ${this.stageOptions.length}`,
        };
      }

      return {};
    },
    returnColumnWidth() {
      if (this.getMobileView) {
        return {
          gridTemplateColumns: `repeat(${this.stageOptions.length}, 1fr)`,
        };
      }

      if (this.stageOptions.length === 2) {
        return {
          gridTemplateColumns: `${this.widthColumn} repeat(${this.stageOptions.length}, ${this.widthColumn})`,
        };
      }

      return {
        gridTemplateColumns: `${this.widthColumn} repeat(${this.stageOptions.length}, minmax(0, 1fr))`,
      };
    },

    widthColumn() {
      return this.columnWidth + 'px';
    },

    doubleResponseWidth() {
      if (this.screenWidth > 999) {
        if (!this.isRtlScript) {
          return {
            width: this.columnWidth * 2 + 'px',
            marginLeft: this.columnWidth + 18 + 'px',
          };
        }
        return {
          width: this.columnWidth * 2 + 'px',
          marginRight: this.columnWidth + 'px',
        };
      }

      return {
        width: '100%',
        paddingLeft: '18px',
        paddingRight: '18px',
      };
    },

    doubleResponseUserData() {
      return {
        user_has_answered: this.answerData.user_has_answered_double_response,
        user_answered_string: this.answerData.user_answered_double_response_string,
        raw_input_radio_table: this.answerData.raw_input_double_response,
      };
    },

    doubleResponseDefaultTrue() {
      return this.stage.fields.doubleResponseDefaultTrue;
    },

    showDoubleResponse() {
      return this.stage.fields.doubleResponse;
    },

    doubleResponseOptions() {
      const questionData = {
        id: 1,
        title: {
          [this.locale]: this.$t('pagination.dce_options_header'),
        },
        answer_options: [
          {
            id: 'opt-in',
            title: { [this.locale]: this.$t('pagination.dce_opt_in_title') },
          },
          {
            id: 'opt-out',
            title: { [this.locale]: this.$t('pagination.dce_opt_out_title') },
          },
        ],
        required: this.showDoubleResponse ? true : false,
      };

      return questionData;
    },

    borderColor() {
      if (
        this.answerData.user_has_answered_choice_situation === true &&
        this.alertUser === false
      ) {
        return {
          borderColor: '#E6E6E6',
        };
      } else if (
        this.answerData.user_has_answered_choice_situation === false &&
        this.alertUser === true
      ) {
        return {
          borderColor: '#D40005',
        };
      }

      return null;
    },

    textOpacity() {
      if (
        this.answerData.user_has_answered_choice_situation === true &&
        this.alertUser === false
      ) {
        return {
          opacity: '0',
        };
      } else if (
        this.answerData.user_has_answered_choice_situation === false &&
        this.alertUser === true
      ) {
        return {
          opacity: '1',
        };
      }

      return null;
    },

    labelNegativeValidationMessage() {
      return this.$t('pagination.input_validation_no_selection_message');
    },
  },

  watch: {
    alertUserForRequiredA(boolean) {
      this.alertUser = this.alertUserForRequiredA;
    },
  },

  created() {
    this.setupUserDataChoiceSet();

    this.alertUser = false;
    this.screenWidth = window.outerWidth;

    if (window.outerWidth < 600) {
      this.columnWidth = 193;
    }
    if (window.outerWidth < 1140 && window.outerWidth >= 600) {
      this.columnWidth = window.outerWidth / 3.1;
    } else if (window.outerWidth >= 1400) {
      this.columnWidth = 340;
    } else if (window.outerWidth >= 1140) {
      this.columnWidth = 280;
    }
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    window.addEventListener('scroll', this.handleScroll);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleScroll(event) {
      if (!this.getMobileView) {
        return;
      }

      const OFFSET = 5;
      const headerElement = this.$refs.choiceSetHeader;
      const scrollHint = this.$refs.scrollHint;
      const distanceTop = headerElement.getBoundingClientRect().top;

      if (distanceTop < OFFSET && this.stageOptions.length <= 2) {
        headerElement.classList.add('stick-header-to-top');
      } else {
        headerElement.classList.remove('stick-header-to-top');
      }

      if (distanceTop < 50) {
        scrollHint.classList.add('hidden');
      } else {
        scrollHint.classList.remove('hidden');
      }
    },

    scrollToHeader() {
      if (!this.getMobileView) {
        return;
      }

      const headerElement = this.$refs.choiceSetHeader;
      const headerPosition = headerElement.getBoundingClientRect();
      window.scrollTo({
        top: window.pageYOffset + headerPosition.y,
        behavior: 'smooth',
      });
    },

    getAttributeTitle(attribute) {
      if (attribute.short_title && attribute.short_title[this.locale]) {
        return attribute.short_title[this.locale];
      }

      return attribute.slug;
    },

    setupUserDataChoiceSet() {
      const answerData = this.currentUserData ? { ...this.currentUserData } : {};

      if (answerData.user_has_answered_choice_situation === true || answerData.user_has_answered_double_response === true) {
        this.checkedArray = answerData.raw_input_choice_situation;
        this.checkedArrayDoubleResponse = answerData.raw_input_double_response;
      } else {
        answerData.user_has_chosen_project = null;
        answerData.user_has_answered_choice_situation = false;
        answerData.user_has_answered_double_response = this.doubleResponseDefaultTrue;
        answerData.user_answered_double_response_string = null;
        answerData.raw_input_choice_situation = null;
        answerData.raw_input_double_response = [this.doubleResponseDefaultTrue, false];
      }

      answerData.choice_situation_id = this.choiceSituationId;

      if (this.showDoubleResponse && this.doubleResponseDefaultTrue) {
        answerData.user_has_answered_double_response = true;
        answerData.raw_input_double_response = [true, false];
      }

      this.answerData = answerData;

      this.$emit('sendCurrentUserData', this.answerData);
    },

    userHasChosenAnswer(index) {
      for (let i = 0; i < this.checkedArray.length; i++) {
        this.checkedArray[i] = false;
      }

      this.checkedArray[index] = true;

      const value = this.stageOptions[index].slug;

      this.answerData.user_has_chosen_project = value;
      this.answerData.user_has_answered_choice_situation = true;
      this.answerData.raw_input_choice_situation = this.checkedArray;
      this.$emit('sendCurrentUserData', this.answerData);
      this.alertUser = false;
    },

    updateDoubleResponseData(data) {
      this.answerData.user_answered_double_response_string =
        data.user_answered_string;
      this.answerData.user_has_answered_double_response = data.user_has_answered;
      this.answerData.raw_input_double_response = data.raw_input_radio_table;

      this.$emit('sendCurrentUserData', this.answerData);
    },

    openAttributeModal(data) {
      this.chosenAttributeData = data;

      if (this.showAttributeModal) {
        this.showAttributeModal = false;
      } else {
        this.showAttributeModal = true;
      }
    },

    handleResize() {
      this.screenWidth = window.outerWidth;

      if (window.outerWidth < 600) {
        this.columnWidth = 193;
      }
      if (window.outerWidth < 1140 && window.outerWidth >= 600) {
        this.columnWidth = window.outerWidth / 3.1;
      } else if (window.outerWidth >= 1400) {
        this.columnWidth = 340;
      } else if (window.outerWidth >= 1140) {
        this.columnWidth = 280;
      }
    },
  },
});
</script>

<style>
.dce-project-title-wrapper {
  background-color: white;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  text-align: center;
  padding: 16px;
}

.mobile .cell-content .dce-project-title-wrapper {
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-top: none;
  border-radius: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 12px;
  background-color: transparent;
}

.mobile .dce-project-title-value {
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 2px solid #dee2e6;
  background-color: var(--bg-color-app);
}

.mobile .info-button-wrapper {
  margin-bottom: 10px;
}

.dce-project-title {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.8px;
}

.dce-attribute-title {
  font-size: 1rem;
  color: var(--text-color-heading);
}

.mobile .dce-attribute-title {
  font-weight: bold;
  color: var(--text-color-content);
}

.dce-attribute-value {
  font-size: 0.9rem;
  color: var(--text-color-content);
}

.choice-set-table {
  position: relative;
  max-width: 1600px;
}

.dce-option-label {
  height: auto;
  cursor: pointer;
  color: var(--text-color-content);
}

table {
  display: grid;
  margin-top: 30px;
  overflow-x: auto;
}

th,
td {
  padding-left: 18px;
}

.mobile th,
.mobile td {
  padding-left: 0px;
}

.even td,
tbody tr.even .first-column .cell-content {
  background-color: var(--bg-color-app);
}

.odd td,
tbody tr.odd .first-column .cell-content {
  background-color: #e8edf5;
}

.mobile .even td,
.mobile tbody tr.even .first-column .cell-content,
.mobile .odd td,
.mobile tbody tr.odd .first-column .cell-content {
  border: none;
}

.first-column {
  background-color: var(--bg-color-app);
  padding-left: 0px;
}

.mobile .stick-header-to-top thead th {
  position: fixed;
  top: 0px;
  z-index: 5;
  width: 50%;
}

.mobile .stick-header-to-top thead th:first-child {
  left: 0px;
}

.mobile .stick-header-to-top thead th:last-child {
  right: 0px;
}

.mobile .stick-header-to-top .dce-project-title-wrapper {
  margin-top: 5px;
}

.mobile .stick-header-to-top tbody tr:first-child td:first-child {
  padding-top: 94px;
}

.mobile td,
.mobile tbody tr.even .first-column .cell-content,
.mobile td,
.mobile tbody tr.odd .first-column .cell-content,
.mobile tbody tr.even .cell-content,
.mobile tfoot td .cell-content,
.mobile .first-column {
  background-color: var(--bg-color-app);
}

.mobile tbody tr.even .cell-content,
.mobile tfoot td .cell-content,
.mobile .first-column {
  border-left: none;
  border-right: none;
  border-top: none;
}

.mobile tbody tr .cell-content:first-child {
  border-right: 1px solid #dee2e6;
}

.mobile tbody tr .cell-content:last-child {
  border-left: 1px solid #dee2e6;
}

.mobile .description-column {
  text-align: center;
}

.mobile .content-cell {
  border-bottom: 2px solid #dee2e6;
}

@media (min-width: 1000px) {
  .first-column {
    position: relative;
  }
}

th .cell-content {
  width: 100%;
  height: 100%;
}

td .cell-content {
  padding: 20px 16px;
  width: 100%;
  height: 100%;
}

.mobile td .cell-content {
  padding-top: 24px;
  padding-bottom: 20px;
}

.mobile .last-row td .content-cell {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 12px;
  border-bottom: none;
}

.mobile .last-row td .content-value {
  width: 100%;
  height: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 2px solid #dee2e6;
  background-color: var(--bg-color-app);
}

thead th {
  display: flex;
}

thead,
tbody,
tfoot,
a,
tr {
  display: contents;
}

td {
  text-align: center;
}

tbody tr.odd .cell-content {
  background-color: #f2f6f8;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

tbody tr.even .cell-content {
  background-color: #ffffff;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

.mobile tbody tr.odd .cell-content,
.mobile tbody tr.even .cell-content {
  background-color: var(--bg-color-app);
}

tbody tr.even .first-column .cell-content,
tbody tr.odd .first-column .cell-content {
  border: none;
}

tfoot td {
  padding-left: 18px;
}

tfoot td.first-column {
  padding: 16px;
  background-color: var(--bg-color-app);
  border-radius: 0px;
}
tfoot td .cell-content {
  border-radius: 0px 0px 25px 25px;
  background-color: #ffffff;
  padding: 16px;
  border-left: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}
.mobile tfoot td .cell-content {
  border-bottom: none;
}

.attribute-modal-header {
  text-align: right;
}

.mobile .scroll-hint {
  position: fixed;
  bottom: 56px;
  width: 100%;
  text-align: center;
  padding-top: 45px;
  padding-bottom: 5px;
  background: linear-gradient(
    0deg,
    var(--bg-color-app),
    var(--bg-color-app),
    rgb(244 246 252 / 70%),
    rgb(244 246 252 / 60%),
    rgb(244 246 252 / 0%)
  );
  z-index: 10;
}

.mobile .scroll-hint button {
  font-size: 1.5rem;
}

.mobile .scroll-hint.hidden {
  display: none;
}

.scroll-icon-down {
  color: var(--text-color-heading);
}
</style>
