<template>
  <h2
    v-if="laPostaEmbed || mailchimpEmbed || shareConsultation"
    class="mb-5"
  >
    <LanguageSelector :to-be-selected="pageActionsTitleLabel" />
  </h2>
  <div
    v-if="mailchimpEmbed"
    :style="{ 'border-bottom-style': borderBottomStyleMailChimp }"
    class="more-detail-expandable-wrapper-double-border d-flex flex-column"
  >
    <div
      class="more-detail-expandable-trigger d-flex align-items-center pt-2 pb-2"
      @click="toggleMailChimpEmbed()"
    >
      <div
        class="more-detail-expandable-icon-wrapper more-detail-arrows pt-auto pb-auto ps-0"
      >
        <font-awesome-icon
          :style="returnRotateIconFirst()"
          class="more-detail-expandable-arrow-icon"
          icon="chevron-right"
        />
      </div>
      <div class="more-detail-expandable-title-wrapper ms-4">
        <LanguageSelector
          :to-be-selected="subscribeMailingListTitleLabel"
          class="more-detail-expandable-title"
        />
      </div>
    </div>
    <div class="more-detail-content-outer-wrapper">
      <transition name="dropdown">
        <div class="more-detail-content-container d-flex flex-row">
          <div
            v-if="isMailchimpEmbedded"
            class="project-detail-full-description-wrapper col ms-3 mt-2 mb-3"
          >
            <OutroMailchimpEmbed />
          </div>
        </div>
      </transition>
    </div>
  </div>
  <div
    v-else-if="laPostaEmbed"
    :style="{ 'border-bottom-style': borderBottomStyleMailChimp }"
    class="more-detail-expandable-wrapper-double-border d-flex flex-column"
  >
    <div
      class="more-detail-expandable-trigger d-flex align-items-center pt-2 pb-2"
      @click="toggleLaPostaEmbed()"
    >
      <div
        class="more-detail-expandable-icon-wrapper more-detail-arrows pt-auto pb-auto ps-0"
      >
        <font-awesome-icon
          :style="returnRotateIconFirst()"
          class="more-detail-expandable-arrow-icon"
          icon="chevron-right"
        />
      </div>
      <div class="more-detail-expandable-title-wrapper ms-4">
        <LanguageSelector
          :to-be-selected="subscribeMailingListTitleLabel"
          class="more-detail-expandable-title"
        />
      </div>
    </div>
    <div class="more-detail-content-outer-wrapper">
      <transition name="dropdown">
        <div class="more-detail-content-container d-flex flex-row">
          <div
            v-show="isLaPostaEmbedded"
            class="project-detail-full-description-wrapper col ms-3 mt-2 mb-3"
          >
            <OutroLaPostaEmbed />
          </div>
        </div>
      </transition>
    </div>
  </div>
  <div
    v-if="shareConsultation"
    class="more-detail-expandable-wrapper-double-border d-flex flex-column"
  >
    <div
      class="more-detail-expandable-trigger d-flex align-items-center pt-2 pb-2"
      @click="toggleShareConsultation()"
    >
      <div
        class="more-detail-expandable-icon-wrapper more-detail-arrows pt-auto pb-auto ps-0"
      >
        <font-awesome-icon
          :style="returnRotateIconSecond()"
          class="more-detail-expandable-arrow-icon"
          icon="chevron-right"
        />
      </div>
      <div class="more-detail-expandable-title-wrapper ms-4">
        <LanguageSelector
          :to-be-selected="shareConsultationTitleLabel"
          class="more-detail-expandable-title"
        />
      </div>
    </div>
    <div class="more-detail-content-outer-wrapper">
      <transition name="dropdown">
        <div class="more-detail-content-container d-flex flex-row">
          <div class="pseudo-col" />
          <div
            v-if="isShareConsultation"
            class="project-detail-full-description-wrapper col ms-4 mt-2 mb-5"
          >
            <p>
              <LanguageSelector :to-be-selected="shareConsultationDescriptionLabel" />
            </p>
            <button
              class="navigation-link-button px-3 py-1"
              @click="openShareConsultationModal()"
            >
              <font-awesome-icon
                class="me-2"
                icon="share-alt"
              />
              <LanguageSelector
                class="me-2"
                :to-be-selected="shareConsultationButtonLabel"
              />
            </button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import OutroMailchimpEmbed from './OutroMailchimpEmbed.vue';
import OutroLaPostaEmbed from './OutroLaPostaEmbed.vue';
import log from 'loglevel';
import { useOutroStore } from '../stores/useOutroStore';
import { mapState, mapActions } from 'pinia';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    OutroMailchimpEmbed,
    OutroLaPostaEmbed,
  },

  data() {
    return {
      isMailchimpEmbedded: false,
      isLaPostaEmbedded: false,
      isShareConsultation: false,
    };
  },

  computed: {
    ...mapState(useOutroStore, [
      'getUrlToCopy',
    ]),

    ...mapState(useInterfaceStore, [
      'getMobileView',
    ]),

    ...mapState(useConsultationStore, [
      'stage',
    ]),

    mailchimpEmbed() {
      return this.stage.fields.mailchimpEmbedCode;
    },

    laPostaEmbed() {
      return this.stage.fields.lapostaEmbedLink;
    },

    shareConsultation() {
      return this.stage.fields.socialShare;
    },

    pageActionsTitleLabel() {
      return this.$t('pagination.thank_you_page_actions_title');
    },

    subscribeMailingListTitleLabel() {
      return this.$t('pagination.thank_you_page_subscribe_mailing_list_title');
    },

    shareConsultationTitleLabel() {
      return this.$t('pagination.thank_you_page_share_consultation_title');
    },

    shareConsultationDescriptionLabel() {
      return this.$t('pagination.thank_you_page_share_consultation_description');
    },

    shareConsultationButtonLabel() {
      return this.$t('pagination.thank_you_page_share_consultation_button');
    },

    borderBottomStyleMailChimp() {
      return this.shareConsultation ? 'none' : 'solid';
    },
  },

  methods: {
    ...mapActions(useOutroStore, ['setShareConsultationModalOpen']),

    toggleMailChimpEmbed() {
      this.isMailchimpEmbedded = !this.isMailchimpEmbedded;
    },

    toggleLaPostaEmbed() {
      this.isLaPostaEmbedded = !this.isLaPostaEmbedded;
    },

    toggleShareConsultation() {
      this.isShareConsultation = !this.isShareConsultation;
    },

    returnRotateIconFirst() {
      if (this.isMailchimpEmbedded || this.isLaPostaEmbedded) {
        return {
          transform: 'rotate(90deg)',
        };
      }
    },

    returnRotateIconSecond() {
      if (this.isShareConsultation) {
        return {
          transform: 'rotate(90deg)',
        };
      }
    },

    openShareConsultationModal() {
      if (this.getMobileView && navigator.share) {
        this.showNativeMobileModal();
        return;
      }
      this.setShareConsultationModalOpen(true);
    },

    showNativeMobileModal() {
      navigator
        .share({
          title: document.title,
          url: this.getUrlToCopy,
        })
        .catch((error) => {
          if (error.name === 'AbortError') {
            return;
          }
          log.error(error);
          //  fallback
          this.setShareConsultationModalOpen(true);
          return;
        });
      return;
    },
  },
});
</script>

<style scoped>
.page-content {
  overflow: auto;
}

.more-detail-expandable-trigger {
  cursor: pointer;
  user-select: none;
}

.more-detail-expandable-wrapper-double-border {
  border-top-style: solid;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}

.more-detail-arrows {
  color: var(--text-color-heading);
}

.more-detail-expandable-title {
  font-size: 1rem;
  font-weight: 700;
  color: var(--text-color-heading);
}

.pseudo-col {
  width: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}
</style>
