<template>
  <div class="dashboard-points-container col ms-0 me-0 p-0">
    <div class="ms-0 me-0">
      <div
        class="attribute-value-restrictive-title-wrapper row m-0 dashboard-m-28"
      >
        <div v-show="attributeTitle">
          <LanguageSelector
            :to-be-selected="attributeTitle"
            class="dashboard-headers"
          />
        </div>
      </div>
      <div class="circle-wrapper row ms-auto me-auto">
        <PointsProgressVisualisation
          :circle-radius="70"
          :circle-color="circleColor"
          :circle-progress="pointsProgress"
          :stroke-density="10"
          :circle-width="3"
          :path-width="10"
        />
        <div class="icon-value-container d-flex flex-column">
          <div class="icon-value-wrapper d-flex flex-column mt-auto mb-auto">
            <font-awesome-icon
              v-if="pointsIcon"
              :style="{ color: pointsIconColor }"
              :icon="pointsIcon"
              class="ms-auto me-auto"
              style="height: 30px; width: 30px"
            />
            <div class="d-flex flex-row ms-auto me-auto stay-ltr">
              <span
                :style="{ color: valueColor }"
                class="dashboard-attribute-value"
              >
                {{ totalSpendPoints }}
              </span>
              <span
                :style="{ color: valueColor }"
                class="dashboard-attribute-value"
              >
                /{{ targetValue }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PointsProgressVisualisation from './PointsProgressVisualisation.vue';
import { useConsultationStore } from '../stores/useConsultationStore';
import { mapState } from 'pinia';

export default defineComponent({
  components: {
    PointsProgressVisualisation,
  },
  props: {
    currentColor: {
      required: true,
      type: String,
    }
  },
  data() {
    return {
      overRestrictiveState: {
        stroke: 'red',
        transition: 'all 1s ease-out',
      },
    };
  },

  computed: {
    ...mapState(useConsultationStore, [
      'stagePointsData',
      'totalSpendPoints',
    ]),

    pointsProgress() {
      const percentage = this.totalSpendPoints / this.targetValue;

      return percentage <= 1 ? percentage : 1;
    },

    targetValue() {
      return this.stagePointsData.points_target;
    },

    attributeTitle() {
      if (this.stagePointsData.hasOwnProperty('short_title')) {
        return this.stagePointsData.short_title;
      }

      return false;
    },

    pointsIcon() {
      if (this.stagePointsData.hasOwnProperty('points_icon') && this.stagePointsData.points_icon !== null) {
        return this.stagePointsData.points_icon;
      }

      return false;
    },

    pointsIconColor() {
      if (this.stagePointsData.hasOwnProperty('points_icon_color') && this.stagePointsData.points_icon_color !== null) {
        return this.stagePointsData.points_icon_color;
      }

      return false;
    },

    circleColor() {
      if (this.targetReached) {
        return 'rgb(146, 195, 1)';
      }

      return this.currentColor;
    },

    valueColor() {
      if (this.targetReached) {
        return 'rgb(146, 195, 1)';
      }

      return 'black';
    },

    targetReached() {
      return this.targetValue === this.totalSpendPoints;
    },
  },
});
</script>

<style scoped>
.dashboard-points-container {
  height: 100%;
  width: 100%;
  background-color: white;
}

.circle-wrapper {
  height: 100%;
  width: 100%;
  max-width: 180px;
  overflow: visible;
  position: relative;
}

#circle {
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  transform-origin: center center;
  transform: rotate(-90deg);
  transition: stroke-dashoffset 1s ease-out, stroke 1s ease-out;
}

.icon-value-container {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
}

svg {
  text-align: center;
  stroke: lightgrey;
  fill: none;
  stroke-width: 10;
  height: 100%;
  width: 100%;
}

.colorRed {
  stroke: red;
  color: red;
  transition: color 0.5s ease-out;
}
</style>
