<template>
  <div class="page-container p-0">
    <div class="card page">
      <section
        v-if="!getMobileView"
        class="navigation-wrapper mb-4 ms-0 me-0"
      >
        <AppNavigation :navigation-panel-top="true" />
      </section>
      <div class="research-motivation-wrapper card-page-content-width">
        <h1 class="motivation-header mt-0">
          {{ $t('pagination.motivation') }}
        </h1>
        <p class="motivation-explanation">
          {{ $t('pagination.motivation_explanation') }}
        </p>
        <h3
          v-if="!skipChoiceTask"
          class="motivation-subheader"
        >
          {{ $t('pagination.motivation_slidermode_subtitle') }}
        </h3>
        <div v-if="skipChoiceTask">
          <ChoiceTaskMotivationQuestion
            v-if="lastQuestion"
            :current-question-data="lastQuestion"
            :last-question="true"
            :current-user-data="currentUserDataMotivation(lastQuestion.id)"
            class="motivation-item"
            @send-current-user-data="updateData"
          />
        </div>
        <div v-else>
          <div
            v-for="question in selectedOptions"
            :key="question.id"
          >
            <ChoiceTaskMotivationQuestion
              v-if="currentOption(question.id)"
              :current-question-data="currentOption(question.id)"
              :last-question="false"
              :current-user-data="currentUserDataMotivation(question.id)"
              class="motivation-item"
              @send-current-user-data="updateData"
            />
          </div>
        </div>

        <AppNavigation :submit-function="submit" />
      </div>
    </div>

    <OptionDetailModal :show="showOptionDetailModal !== false" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import OptionDetailModal from './OptionDetailModal.vue';
import ChoiceTaskMotivationQuestion from './ChoiceTaskMotivationQuestion.vue';
import AppNavigation from './AppNavigation.vue';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { mapState, mapWritableState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    ChoiceTaskMotivationQuestion,
    OptionDetailModal,
    AppNavigation,
  },

  setup() {
    const consultationStore = useConsultationStore();
    return { consultationStore };
  },

  computed: {
    ...mapState(useInterfaceStore, [
      'getMobileView',
    ]),

    ...mapWritableState(useConsultationStore, [
      'nextDisabled',
      'showOptionDetailModal',
    ]),

    ...mapState(useConsultationStore, [
      'stage',
      'stageId',
      'stages',
      'getAnswerData',
      'skipChoiceTask',
    ]),

    selectedOptions() {
      const stages = this.stages;
      const id = `choice-task-${this.stage.blockId}`;
      const choiceTaskStage = stages.find((stage) => stage.id === id);

      return choiceTaskStage ? choiceTaskStage.fields.options : [];
    },

    lastQuestion() {
      if (this.skipChoiceTask && this.stage.fields.skippedMotivation === true) {
        return {
          // should be shown when the user skipped the choice task part
          title: this.$t('pagination.motivation_skip_choicetask'),
          id: -1,
        };
      }

      return false;
    },
  },

  created() {
    this.nextDisabled = false;
  },

  methods: {
    submit() {
      this.consultationStore.submitChoiceTaskMotivationAnswerData(this.stageId);
    },

    updateData(data) {
      const answerData = this.getAnswerData[this.stageId] ? this.getAnswerData[this.stageId].answers : {};

      answerData[data.id] = data;

      const payload = {
        stageId: this.stageId,
        data: answerData
      };

      this.consultationStore.setAnswerData(payload);
    },

    currentOption(optionId) {
      if (optionId === -1) {
        return false;
      }

      const id = `choice-task-${this.stage.blockId}`;

      if (this.getAnswerData[id].answers[optionId]) {
        return this.getAnswerData[id].answers[optionId];
      }

      const option = this.selectedOptions.find((option) => option.id === optionId);

      return option;
    },

    getUserDataChoiceTaskMotivation() {
      if (this.getAnswerData[this.stageId] === undefined) {
        return {};
      }

      return this.getAnswerData[this.stageId].answers;
    },

    currentUserDataMotivation(optionId) {
      return this.getUserDataChoiceTaskMotivation()[optionId];
    },
  },
});
</script>

<style scoped>
.row {
  margin: 0;
  padding: 0;
}

.col {
  margin: 0;
  padding: 0;
}

.motivation-explanation {
  margin: 1em 0;
}

.motivation-item {
  margin-top: 1em;
  margin-bottom: 2em;
}
</style>
