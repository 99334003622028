import { Condition, ConditionOperation } from "../../types/Condition";
import { isNumeric } from "./isNumeric";

export function compareAnswerToCondition(condition: Condition, answer) {
  let match: string | number = condition.value;
  const operation: ConditionOperation = condition.operator;

  if (isNumeric(answer) && isNumeric(match)) {
    answer = parseInt(answer);
    match = parseInt(match);
  }

  if (Array.isArray(answer)) {
    if (operation === 'equal_to') {
      return answer.includes(match);
    } else if (operation === 'not_equal_to') {
      return !answer.includes(match);
    }
  } else if (typeof answer === 'string' || typeof answer === 'number') {
    if (operation === 'equal_to') {
      return answer === match;
    } else if (operation === 'not_equal_to') {
      return answer !== match;
    } else if (operation === 'greater_than') {
      return answer > match;
    } else if (operation === 'lower_than') {
      return answer < match;
    }
  }

  return false;
}
