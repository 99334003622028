<template>
  <BaseModal
    :show="getShareConsultationModalOpen"
    :close-function="closeModal"
  >
    <template #header>
      <h1 class="mt-0 mb-4">
        {{ $t('pagination.share_consultation_modal_title') }}
      </h1>
      <div class="social-media-buttons-container d-flex flex-wrap">
        <OutroSocialMediaShareButton
          v-for="([name, value]) in Object.entries(getSharingPlatforms)"
          :key="name"
          :type="name"
          :name="value.name"
          :button-color="value.color"
          :icon="value.icon"
          :share-url="value.share_url"
        />
      </div>
    </template>
    <template #content>
      <h3>{{ $t('pagination.share_consultation_modal_content_title') }}</h3>
      <div class="copy-address-wrapper d-flex">
        <div
          class="copy-address-inner-wrapper p-2 d-flex flex-wrap align-items-center justify-content-center justify-content-md-between"
        >
          <input
            id="copy-address-input"
            ref="url-to-copy"
            readonly
            :value="getUrlToCopy"
            class="my-0 me-2"
          >
          <button
            id="copy-address-button"
            class="navigation-link-button px-3 py-1"
            @click="copyAddressFunction()"
          >
            <font-awesome-icon
              class="me-2"
              icon="copy"
            />
            <span>{{ $t('pagination.share_consultation_copy_button_title') }}</span>
          </button>
        </div>
        <div class="copied-validation-wrapper d-flex align-items-center ms-2">
          <font-awesome-icon
            id="copiedValidationFeedbackIcon"
            :style="{ opacity: showCopiedValidationFeedback }"
            icon="check-circle"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseModal from './BaseModal.vue';
import OutroSocialMediaShareButton from './OutroSocialMediaShareButton.vue';
import { mapState, mapActions } from 'pinia';
import { useOutroStore } from '../stores/useOutroStore';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    BaseModal,
    OutroSocialMediaShareButton,
  },
  data() {
    return {
      showCopiedValidationFeedback: '0',
    };
  },
  computed: {
    ...mapState(useOutroStore, [
      'getShareConsultationModalOpen',
      'getUrlToCopy',
    ]),

    ...mapState(useConsultationStore, [
      'stage'
    ]),

    getSharingPlatforms() {
      return this.stage.fields.sharing;
    },
  },
  methods: {
    ...mapActions(useOutroStore, ['setShareConsultationModalOpen']),

    closeModal() {
      this.setShareConsultationModalOpen(false);
    },

    async copyAddressFunction() {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(this.getUrlToCopy);
        this.startCopyValidationFeedback();
        this.highlightSelectedUrl();
      }
      return;
    },

    async startCopyValidationFeedback() {
      this.showCopiedValidationFeedback = '1';
      setTimeout(() => {
        this.showCopiedValidationFeedback = '0';
      }, 1000);
      return;
    },

    highlightSelectedUrl() {
      const urlToHighlight = this.$refs['url-to-copy'];
      if (!urlToHighlight) {
        return;
      }
      urlToHighlight.select();
      //	mobile devices fallback
      urlToHighlight.setSelectionRange(0, 99999);
    },
  },
});
</script>

<style scoped>
.social-media-buttons-container {
  column-gap: 10px;
}

.copy-address-wrapper {
  min-width: 100px;
}

.copy-address-inner-wrapper {
  min-width: 100px;
  border: solid 1px var(--border-color-light);
  border-radius: 10px;
}

@media (min-width: 768px) {
  .copy-address-inner-wrapper {
    min-width: 370px;
  }
  .copy-address-wrapper {
    min-width: 370px;
  }
}

#copy-address-input {
  border-style: none;
  color: var(--text-color-content);
  font-size: var(--p-size);
}

#copiedValidationFeedbackIcon {
  transition: opacity 0.5s;
  font-size: 1.2rem;
  color: var(--bs-success);
}
</style>
