import { Condition } from "../../types/Condition";

export function getQuestionAnswer(condition: Condition, answerData) {
  let answer;

  if (
    answerData.field_type === 'matrix' &&
    condition.conditional_id !== answerData.id
  ) {
    const index = answerData.matrix_theses.findIndex((row) => {
      return row.id === condition.conditional_id;
    });

    if (index === -1) {
      return false;
    }

    answer = answerData.user_answered_array[index];
  } else {
    answer = answerData.user_answered_string || answerData.user_answered_array;
  }

  return answer;
}
