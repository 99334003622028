<template>
  <output
    :ref="toolTipId"
    :style="getToolTipPositionStyle"
    class="slider-tooltip w-auto"
    :class="{ 'slider-tooltip-down': sliderTooltipDown }"
  >
    <AttributeValueConverter
      v-if="!softPropertyMode"
      :current-attribute-data="connectedAttribute"
      :raw-value="currentValueDisplay"
      class="tooltip-display-value col w-100 m-0 p-0 d-flex justify-content-center flex-nowrap flex-row"
      :class="{ 'tooltip-display-down-value': sliderTooltipDown }"
    />
    <AttributeValueConverter
      v-else
      :current-attribute-data="connectedAttribute"
      :raw-value="currentValueDisplay"
      class="tooltip-display-soft col w-100 m-0 p-0 d-flex justify-content-center flex-row"
      :class="{
        'tooltip-display-soft-wrap': !sliderTooltipDown,
        'tooltip-display-down-soft': sliderTooltipDown,
      }"
    />
  </output>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  props: {
    connectedAttribute: {
      type: Object,
      required: true,
    },
    currentValueDisplay: {
      type: Number,
      required: true,
    },
    toolTipId: {
      type: String,
      required: true,
    },
    sliderRatio: {
      type: Number,
      required: true,
    },
    toolTipActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    sliderTrackWidth: {
      type: Number,
      required: true,
    },
    sliderTooltipDown: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      sliderToolTipElement: undefined,
      sliderToolTipWidth: 0,
    };
  },

  computed: {
    ...mapState(useConsultationStore, [
      'locale',
      'isRtlScript',
    ]),

    softPropertyMode() {
      const dict = this.connectedAttribute.attribute_value_dictionary;

      if (dict !== null && Object.keys(dict).length > 0) {
        let dictionaryData;

        if (Array.isArray(dict)) {
          dictionaryData = dict;
        } else if (this.locale && dict.hasOwnProperty(this.locale)) {
          //	check if selected language is available in the dictionary otherwise use the first available language
          dictionaryData = dict[this.locale]
            ? dict[this.locale]
            : dict[Object.keys(dict)[0]];
        } else {
          dictionaryData = dict[Object.keys(dict)[0]];
        }

        if (dictionaryData !== null && dictionaryData.length > 0) {
          return new Boolean(
            dictionaryData[0].hasOwnProperty('icon_quantity') ||
              dictionaryData[0].hasOwnProperty('label')
          );
        }
      }

      return false;
    },

    getSliderRatio() {
      if (!this.isRtlScript) {
        return this.sliderRatio;
      }
      return 1 - this.sliderRatio;
    },

    getSliderToolTipWidth() {
      if (this.sliderToolTipElement) {
        return this.sliderToolTipWidth;
      }

      return false;
    },

    getToolTipPositionStyle() {
      return {
        left: this.getTooltipPosition,
        transform: 'translateX(' + this.getTooltipOffset + ')',
      };
    },
    getTooltipPosition() {
      return `${this.getSliderRatio * 100}%`;
    },

    getTooltipOffset() {
      if (this.sliderTooltipDown === false) {
        //	In the upper tooltip the tooltip is always centered around the slider thumb
        return '-50%';
      }
      //	Here is the part were it gets tricky
      //	Since the tooltip position is set as an absolute positioned element it is not aware of what the borders are of the slider container
      //	So in here we check if the slider tooltip value is not spilling out of the left or right side of the slider container
      //	First we get the width of the tooltip element that would be sticking out from the slider thumb
      //	Calling it offSet width since it's hard to find a better word for it
      let offSetWidth = 0;
      if (this.getSliderToolTipWidth) {
        offSetWidth = this.getSliderToolTipWidth / 2;
      }
      //	Here we get the ratio of that offSetwidth compared to the whole slider track
      const offSetRatio = offSetWidth / this.sliderTrackWidth;
      //	Transform it to percentage so is more logical to compare it
      const offSetPercentage = offSetRatio * 100;
      //	SliderPercentage is the value of where the current positioned slider thumb is in percentage
      const sliderPercentage = this.getSliderRatio * 100;
      //	Here are the zones of the slider track each of the zones has it' own offset position
      //	The zones are from left to right over the slider track
      //	So here we can check if the tooltip would spill out of the slider container if the tooltip would be centered
      //	If that is the case we adjust the position of the tooltip
      //	<zone1-zone2-zone3- part of the slider where the tooltip value does not spill out -zone4-zone5-zone6>
      const zone1 = offSetPercentage / 4;
      const zone2 = offSetPercentage / 2;
      const zone3 = offSetPercentage;
      const zone4 = 100 - offSetPercentage;
      const zone5 = 100 - offSetPercentage / 2;
      const zone6 = 100 - offSetPercentage / 4;
      if (sliderPercentage < zone1) {
        return `${-5}%`;
      } else if (sliderPercentage >= zone1 && sliderPercentage < zone2) {
        return `${-12.5}%`;
      } else if (sliderPercentage >= zone2 && sliderPercentage < zone3) {
        return `${-25}%`;
      } else if (sliderPercentage >= zone3 && sliderPercentage < zone4) {
        return `${-50}%`;
      } else if (sliderPercentage >= zone4 && sliderPercentage < zone5) {
        return `${-75}%`;
      } else if (sliderPercentage >= zone5 && sliderPercentage < zone6) {
        return `${-87.5}%`;
      }

      return `${-95}%`;
    },
  },

  watch: {
    toolTipActive(bool) {
      this.updateSliderToolTipWidth();
    },
  },

  mounted() {
    this.setSliderTooltipElement();
  },

  methods: {
    returnTooltipOffsetCalibrated(value) {
      return `calc(${value}% + (${8 - value * 0.15}px))`;
    },

    setSliderTooltipElement() {
      this.sliderToolTipElement = this.$refs[this.toolTipId];
      this.updateSliderToolTipWidth();
    },

    updateSliderToolTipWidth() {
      if (this.sliderToolTipElement) {
        this.sliderToolTipWidth = this.sliderToolTipElement.offsetWidth;
      }
    },
  },
});
</script>

<style scoped>
.slider-tooltip {
  display: flex;
  bottom: 20px;
  left: 0px;
  white-space: nowrap;
  color: #0e4876;
  background-color: #f4f7f9;
  border: 1px solid #e9eff4;
  border-radius: 2px;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  padding: 4px 10px;
  text-align: center;
  user-select: none;
  transition: opacity 0.1s ease-out;
  pointer-events: none;
  min-height: 25px;
  margin-right: -9999999px;
}
.slider-tooltip-down {
  top: 7px;
  padding: 0px;
  background: transparent;
  border: none;
  font-weight: 400;
  max-width: 100%;
  display: none;
}

.tooltip-display-value,
.tooltip-display-soft {
  max-width: 90px;
}

@media (min-width: 1000px) {
  .tooltip-display-value,
  .tooltip-display-soft {
    max-width: 120px;
  }
}

@media (min-width: 1024px) {
  .tooltip-display-value,
  .tooltip-display-soft {
    max-width: 160px;
  }
}

@media (min-width: 2560px) {
  .tooltip-display-value,
  .tooltip-display-soft {
    max-width: 200px;
  }
}

.tooltip-display-down-value,
.tooltip-display-down-soft {
  max-width: initial;
}

@media (min-width: 576px) {
  .slider-tooltip-down {
    display: inline-block;
  }
}
</style>

<style>
.tooltip-display-soft > span.avc-soft-property-icons > svg.soft-property-icon {
  font-size: 16px;
}

.tooltip-display-soft-wrap > span.avc-soft-property-icons {
  display: inline-block;
  flex-wrap: wrap;
}

.tooltip-display-value span.avc-value {
  word-break: break-word;
  hyphens: auto;
}

.tooltip-display-soft span.avc-value {
  word-break: break-word;
  hyphens: auto;
}

@media (min-width: 2560px) {
  .tooltip-display-soft span.avc-value {
    word-break: initial;
    hyphens: initial;
  }
}
</style>
