<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { useConsultationStore } from '../stores/useConsultationStore';
import CardPage from './CardPage.vue';
import AppNavigation from './AppNavigation.vue';
import RenderString from './RenderString.vue';

const interfaceStore = useInterfaceStore();
const consultationStore = useConsultationStore();

const showAudioDescription = ref(false);

const getMobileView = computed(() => interfaceStore.getMobileView);
const nextDisabled = computed({
  get: () => consultationStore.nextDisabled,
  set: (value) => (consultationStore.nextDisabled = value),
});

const stage = computed(() => consultationStore.stage);
const locale = computed(() => consultationStore.locale);
const title = computed(() => stage.value.fields.title);

const content = computed(() =>
  stage.value.fields.content ? stage.value.fields.content[locale.value] : undefined
);

const videoDesktopUrl = computed(() => {
  const video = stage.value.fields.video;
  return video && video[locale.value] ? video[locale.value] : undefined;
});

const videoMobileUrl = computed(() => {
  const videoMobile = stage.value.fields.videoMobile;
  return videoMobile && videoMobile[locale.value] ? videoMobile[locale.value] : undefined;
});

const showMobileVideo = computed(() => videoMobileUrl.value && getMobileView.value);
const isVimeoVideoAvailable = computed(() => videoDesktopUrl.value || showMobileVideo.value);
const currentVideoUrl = computed(() => (showMobileVideo.value ? videoMobileUrl.value : videoDesktopUrl.value));
const useFullWidthForVideo = computed(() => !showMobileVideo.value);

const audioDescription = computed(() => {
  const audioDescription = stage.value.fields.audioDescription;

  if (audioDescription && audioDescription[locale.value]) {
    return audioDescription;
  }

  return false;
});

const hasAudioDescription = computed(() => !!audioDescription.value);

const toggleAudioDescription = () => {
  showAudioDescription.value = !showAudioDescription.value;
};

const rotateIconStyle = computed(() => {
  return showAudioDescription.value ? { transform: 'rotate(90deg)' } : {};
});

const vimeoVideoId = computed(() => {
  const url = currentVideoUrl.value;
  return url ? url.split('/')[3] : undefined;
});

onMounted(() => {
  nextDisabled.value = false;
});
</script>

<template>
  <div class="page-container">
    <CardPage>
      <section
        v-if="!getMobileView"
        class="navigation-wrapper row mb-4 ms-0 me-0"
      >
        <AppNavigation :navigation-panel-top="true" />
      </section>
      <div>
        <div class="page-title">
          <h1 v-show="title">
            <LanguageSelector :to-be-selected="title" />
          </h1>
        </div>
        <div class="page-content">
          <RenderString
            v-if="content"
            :string="content"
          />
        </div>
        <div class="page-video mt-5 mb-5">
          <VimeoPlayer
            v-if="isVimeoVideoAvailable"
            ref="player"
            :video-url="currentVideoUrl"
            :video-id="vimeoVideoId"
            :options="{
              dnt: true,
              responsive: true,
              byline: false,
              title: false,
              controls: true,
              portrait: false,
              keyboard: false,
            }"
            class="instruction-video-vimeo-player mx-auto"
            :class="{ 'w-100': useFullWidthForVideo }"
          />
          <div
            v-if="hasAudioDescription"
            class="audio-description-wrapper"
          >
            <button
              class="audio-button"
              @click="toggleAudioDescription"
            >
              <font-awesome-icon
                :style="rotateIconStyle"
                class="more-detail-expandable-arrow-icon"
                style="font-size: 0.8rem"
                icon="chevron-right"
              />
              {{ $t('pagination.audio_description_video') }}
            </button>

            <div
              v-show="showAudioDescription"
              class="audio-description-content"
            >
              <LanguageSelector
                :to-be-selected="audioDescription"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="page-navigation">
        <AppNavigation />
      </div>
    </CardPage>
  </div>
</template>

<style scoped>
.instruction-video-vimeo-player {
  width: 70%;
}

.audio-description-wrapper {
  width: 100%;
  background: #f2f2f2;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.audio-button {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 8px 16px;
  text-align: left;
  color: var(--text-color-heading);
}

.audio-description-content {
  padding: 16px;
  text-align: left;
  color: var(--text-color-heading);
}
</style>
