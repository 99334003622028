import router from '../router';
import { Environments, getCurrentEnvironment } from '../settings';

const MATOMO_HOST = 'https://populytics.matomo.cloud';

function getMatomoTrackingId() {
  return getCurrentEnvironment() === Environments.PRODUCTION ? 1 : 5;
}

export const MatomoConfig = {
  host: MATOMO_HOST,
  siteId: getMatomoTrackingId(),
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: [],
};
