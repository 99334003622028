<template>
  <div class="dashboard-attributes-wrapper container text-start ps-0 pe-0">
    <div class="pt-5 pb-2 dashboard-container">
      <div
        v-if="includePoints"
        class="attribute-points-wrapper"
      >
        <AttributeValuePoints
          current-color="grey"
        />
      </div>
      <div
        v-if="showAttributeValueExact"
        class="attribute-points-wrapper"
      >
        <AttributeValueExact
          :current-attribute-data="targetValueAttributeData"
          :attribute-target-value="getExactTargetValue"
        />
      </div>
      <div
        v-if="restrictiveAttributeData.length > 0"
        class="attribute-restrictive-wrapper"
      >
        <div
          v-if="showRestrictionHeader"
          class="dashboard-m-28 d-flex justify-content-start"
        >
          <span class="dashboard-headers">
            {{ $t('pagination.choice_task_restrictions') }}
          </span>
        </div>
        <AttributeValueRestrictiveRange
          v-for="currentRestrictiveAttributeData in restrictiveAttributeData"
          :key="currentRestrictiveAttributeData.id"
          class="dashboard-m-48"
          :current-attribute-data="currentRestrictiveAttributeData"
        />
      </div>
      <div
        v-if="normalAttributeData.length > 0"
        class="attribute-normal-wrapper mb-2"
      >
        <div class="dashboard-header-title-wrapper dashboard-m-28">
          <div class="dashboard-headers">
            {{ $t('pagination.total_effects') }}
          </div>
        </div>
        <AttributeValueNormal
          v-for="(currentNormalAttributeData, index) in normalAttributeData"
          :key="currentNormalAttributeData.id"
          class="dashboard-m-24"
          :current-attribute-data="currentNormalAttributeData"
          :current-attribute-value="
            currentNormalAttributeData.current_calculated_value
          "
          :component-in-dashboard="true"
          :array-index="index"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AttributeValuePoints from './AttributeValuePoints.vue';
import AttributeValueExact from './AttributeValueExact.vue';
import AttributeValueNormal from './AttributeValueNormal.vue';
import AttributeValueRestrictiveRange from './AttributeValueRestrictiveRange.vue';
import { mapState } from 'pinia';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    AttributeValuePoints,
    AttributeValueExact,
    AttributeValueNormal,
    AttributeValueRestrictiveRange,
  },

  computed: {
    ...mapState(useInterfaceStore, [
      'getMobileView',
    ]),

    ...mapState(useConsultationStore, [
      'stage',
      'isSliderMode',
      'stageProperties',
      'stagePointsData',
    ]),

    includePoints() {
      if (this.stagePointsData && !this.getMobileView) {
        return true;
      }

      return false;
    },

    getExactTargetValue() {
      if (!this.isSliderMode) {
        return false;
      }

      return this.stage.fields.slideToMaxData.exact_target_property_value;
    },

    targetValueAttributeData() {
      if (!this.isSliderMode) {
        return false;
      }

      return this.stageProperties.find(
        ({ id }) => id === this.stage.fields.slideToMaxData.exact_target_property
      );
    },

    showRestrictionHeader() {
      return this.stage.fields.hideDashboardRestrictionLabel !== true;
    },

    restrictiveAttributeData() {
      return this.stageProperties.filter(
        (p) => p.restrictive && p.visibility.visible_in_right_bar
      );
    },

    normalAttributeData() {
      return this.stageProperties.filter(
        (p) => p.restrictive === false && p.visibility.visible_in_right_bar
      );
    },

    showAttributeValueExact() {
      if (!this.isSliderMode) {
        return false;
      }

      return this.targetValueAttributeData && this.getExactTargetValue;
    },
  },
});
</script>

<style scoped>
.dashboard-container {
  transition: all 0.2s ease-in;
}
</style>

<style>
.dashboard-m-48 {
  margin-bottom: 48px;
}

.dashboard-m-28 {
  margin-bottom: 28px;
}

.dashboard-m-24 {
  margin-bottom: 24px;
}

.dashboard-m-12 {
  margin-bottom: 12px;
}

.attribute-value-height {
  max-height: 24px;
}
</style>
