<template>
  <div
    :class="getMobileView ? 'compare-table-wrapper-mobile' : 'compare-table-wrapper-desktop'"
  >
    <table
      :style="`grid-template-columns: 260px repeat(${stageOptions.length}, 240px)`"
      class="mt-3"
    >
      <thead>
        <tr class="first-row">
          <th class="first-column">
            <div class="cell-content" />
          </th>
          <th
            v-for="option in stageOptions"
            :key="option.id"
          >
            <div class="cell-content">
              <ChoiceTaskCompareRowHeader :option="option" />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(attribute, index) in properties"
          :key="attribute.id"
          :class="(index + 1) % 2 ? 'odd' : 'even'"
        >
          <td class="first-column">
            <div class="cell-content">
              <LanguageSelector :to-be-selected="attribute.title" />

              <font-awesome-icon
                v-if="attribute.restrictive_icon"
                :icon="attribute.restrictive_icon"
              />
            </div>
          </td>
          <td
            v-for="option in stageOptions"
            :key="option.id"
          >
            <div class="cell-content border-left border-right">
              <AttributeValueConverter
                :current-attribute-data="attribute"
                :option-id="option.id"
              />
            </div>
          </td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <td class="first-column" />
          <td
            v-for="option in stageOptions"
            :key="option.id"
          >
            <div class="cell-content border-left border-right border-bottom" />
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ChoiceTaskCompareRowHeader from './ChoiceTaskCompareRowHeader.vue';
import { comparableProperties } from '../consultation/Properties/comparableProperties';
import { mapState } from 'pinia';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    ChoiceTaskCompareRowHeader,
  },
  computed: {
    ...mapState(useInterfaceStore, [
      'getMobileView',
    ]),

    ...mapState(useConsultationStore, [
      'stageOptions',
      'stageProperties',
    ]),

    properties() {
      return comparableProperties(this.stageProperties);
    },
  },
});
</script>

<style scoped>
.compare-table-wrapper-desktop {
  max-width: 100%;
}

.compare-table-wrapper-mobile {
  max-width: 100vw;
}

table {
  display: grid;
  overflow-x: scroll;
}

h2.compare-options-title {
  padding: 20px;
}

th,
td {
  padding-left: 18px;
}

.even td,
tbody tr.even .first-column .cell-content {
  background-color: var(--bg-color-app);
}

.odd td,
tbody tr.odd .first-column .cell-content {
  background-color: #e8edf5;
}

.first-column {
  position: relative;
  z-index: 1;
  left: 0px;
  color: var(--text-color-heading);
  background-color: var(--bg-color-app);
  padding-left: 0px;
}

@media (min-width: 1000px) {
  html[dir='ltr'] .first-column {
    position: sticky;
  }
}

th .cell-content {
  width: 100%;
  height: 100%;
}

td .cell-content {
  padding: 20px;
  width: 100%;
  height: 100%;
}

thead th {
  display: flex;
}

.first-column .cell-content {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

td.first-column svg {
  color: #b7b7b7;
}

thead,
tbody,
tfoot,
a,
tr {
  display: contents;
}

td {
  text-align: center;
}

tbody tr.odd .cell-content {
  background-color: #f2f6f8;
}

tbody tr.even .cell-content {
  background-color: #ffffff;
}

tfoot td {
  padding-left: 18px;
}

tfoot td.first-column {
  padding: 16px;
  background-color: var(--bg-color-app);
  border-radius: 0px;
}
tfoot td .cell-content {
  border-radius: 0px 0px 25px 25px;
  background-color: #ffffff;
  padding: 16px;
}
</style>

<style>
.cell-content .avc-soft-property-icons {
  justify-content: center;
}
</style>
