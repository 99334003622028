<template>
  <div class="research-top-section-wrapper mt-2 mt-md-5">
    <section
      v-if="canNavigateBack && !getMobileView"
      class="navigation-wrapper mb-4"
    >
      <AppNavigation :navigation-panel-top="true" />
    </section>
    <section class="instruction-cues-section">
      <ChoiceTaskHeaderInstructions />
    </section>
    <ChoiceTaskHeaderActions class="mt-3 mt-xl-0" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ChoiceTaskHeaderInstructions from './ChoiceTaskHeaderInstructions.vue';
import ChoiceTaskHeaderActions from './ChoiceTaskHeaderActions.vue';
import AppNavigation from './AppNavigation.vue';
import { mapState } from 'pinia';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    ChoiceTaskHeaderActions,
    ChoiceTaskHeaderInstructions,
    AppNavigation,
  },

  computed: {
    ...mapState(useInterfaceStore, [
      'getMobileView',
    ]),

    ...mapState(useConsultationStore, [
      'canNavigateBack',
    ]),
  },
});
</script>
