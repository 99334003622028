<template>
  <div class="min-vh-100 d-flex flex-column">
    <header>
      <nav class="navbar">
        <div class="container-fluid">
          <div class="navbar-brand">
            <img
              src="@/assets/img/logo-desktop.svg"
              class=""
              alt="Wevaluate Logo"
              aria-label="Wevaluate"
            >
          </div>
        </div>
      </nav>
    </header>

    <div class="flex-grow-1" />

    <footer class="text-white container-fluid text-center">
      {{ footerText }}
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    footerText() {
      const currentYear = new Date().getFullYear();
      return 'Wevaluate - ' + currentYear;
    },
  },
});
</script>

<style scoped>
header {
	height: 56px;
	box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
	background-color: var(--primary-custom-header);
}
.navbar {
  padding: 0.75rem 0;
}
.navbar-brand img{
  height: 32px;
  width: auto;
}
footer {
  font-size: 0.8rem;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: -6px -8px 11px rgba(0,0,0,.16);
	background-color: var(--primary-custom-footer);
}
</style>
