<template>
  <div :class="`${inCompareMode ? 'research-topbar-pseudo-wrapper' : ''}`">
    <div
      class="d-flex justify-content-center justify-content-md-end align-items-center"
    >
      <div
        v-show="showSortDropdown"
        class="research-topbar-dropdown-overlay"
        @click="showSortDropdown = false"
      />
      <div
        v-if="sortableProperties.length > 0"
        class="research-topbar-dropdown-container"
      >
        <button
          :aria-haspopup="true"
          :aria-expanded="showSortDropdown ? 'true' : 'false'"
          :class="`btn research-topbar-button ${
            highlightFilterButton ? 'active' : ''
          }`"
          @click="showSortDropdown = !showSortDropdown"
        >
          <span class="me-2">{{ $t('pagination.order_by') }}</span>
          <font-awesome-icon icon="chevron-down" />
        </button>
        <div
          v-if="showSortDropdown"
          class="research-topbar-options-wrapper"
        >
          <ul
            class="research-topbar-options row ms-0 me-0 p-0 mt-1"
            role="listbox"
            tabindex="-1"
          >
            <li
              :class="{ active: sortingPropertyLocal == null }"
              class="ms-0 me-0"
              role="option"
              tabindex="0"
              @click="sortOnProperty(null)"
              @keydown.enter="sortOnProperty(null)"
            >
              <span class="ps-0 pe-0">
                {{ defaultSortingLabel }}
              </span>
            </li>
            <li
              v-for="(property, index) in sortableProperties"
              :key="index"
              :class="{ active: sortingPropertyLocal == property.id }"
              role="option"
              tabindex="0"
              class="row ms-0 me-0"
              @click="sortOnProperty(property)"
              @keydown.enter="sortOnProperty(property)"
            >
              <span class="col ps-0 pe-0">
                <LanguageSelector :to-be-selected="getPropertyTitle(property)" /> ({{ $t('pagination.ascending') }})
                <font-awesome-icon
                  v-if="property.restrictive_icon"
                  :icon="property.restrictive_icon"
                />
              </span>
            </li>
          </ul>
        </div>
      </div>
      <button
        v-if="showCompareButton"
        :class="`btn research-topbar-button ms-3 ${inCompareMode ? 'active' : ''}`"
        @click="toggleCompareMode"
      >
        <span
          v-if="inCompareMode"
          class="me-2"
        >
          {{ $t('pagination.cancel_compare') }}
        </span>
        <span
          v-else
          class="me-2"
        >{{ $t('pagination.compare') }}</span>
        <font-awesome-icon
          v-if="inCompareMode"
          icon="redo-alt"
          flip="horizontal"
        />
        <font-awesome-icon
          v-else
          icon="exchange-alt"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { comparableProperties } from '../consultation/Properties/comparableProperties';
import { sortableProperties } from '../consultation/Properties/sortableProperties';
import { useAnalyticsStore } from '../stores/useAnalyticsStore';
import { mapState, mapWritableState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  setup() {
    const analyticsStore = useAnalyticsStore();
    return { analyticsStore };
  },

  data() {
    return {
      showSortDropdown: false,
      sortingPropertyLocal: undefined,
    };
  },

  computed: {
    ...mapWritableState(useConsultationStore, [
      'inCompareMode',
      'sortingProperty',
    ]),

    ...mapState(useConsultationStore, [
      'locale',
      'stage',
      'stageProperties',
      'stageOptions',
    ]),

    showCompareButton() {
      return comparableProperties(this.stageProperties).length > 0;
    },

    sortableProperties() {
      return sortableProperties(this.stageProperties);
    },

    highlightFilterButton() {
      if (this.showSortDropdown || this.sortingPropertyLocal) {
        return true;
      }

      return false;
    },

    defaultSortingLabel() {
      if (this.stage.fields.randomOrder) {
        return this.$t('pagination.choice_task_sorting_default_random');
      }

      return this.$t('pagination.choice_task_sorting_default_normal');
    },
  },

  created() {
    this.sortingPropertyLocal = this.getSortingProperty;
  },

  methods: {
    sortOnProperty(property) {
      if (!property) {
        this.sortingPropertyLocal = null;
        this.showSortDropdown = false;
        this.sortingProperty = null;
        return;
      }

      this.sortingPropertyLocal = property.id;
      this.showSortDropdown = false;
      this.sortingProperty = property.id;

      const sortProperty =
        this.getPropertyTitle(property) &&
        this.getPropertyTitle(property)[this.locale]
          ? this.getPropertyTitle(property)[this.locale]
          : 'NA';

      this.analyticsStore.trackUserAction(['Sort', sortProperty, 'asc']);
    },

    getPropertyTitle(attributeData) {
      return attributeData.title || attributeData.short_title;
    },

    toggleCompareMode() {
      if (!this.inCompareMode) {
        this.analyticsStore.trackUserAction(['Compare']);
      }

      this.showSortDropdown = false;
      this.inCompareMode = !this.inCompareMode;
    },
  },
});
</script>

<style scoped>
.research-options-compare button {
  display: inline-block;
  margin: auto;
  background-color: #fd1f9b;
  width: 174px;
  height: 36px;
  border-radius: 15px;
  border-style: none;
  box-shadow: 0 5px 16px rgba(253, 31, 155, 0.19);
}

.research-options-compare img {
  display: inline-block;
  margin: 0;
  float: left;
  position: relative;
  right: 20px;
  top: 2px;
}

.research-options-compare span {
  /*  border-style: solid;*/
  width: 200px;
  font-style: 14px;
  font-weight: 700px;
  letter-spacing: 0.5px;
  margin: 0;
  display: inline-block;
  float: right;
  position: relative;
  bottom: 14px;
  left: 52px;
}

.research-topbar-button {
  background-color: transparent;
  border: 2px solid #0066a2;
  border-radius: 5px;
  padding: 6px 24px;
  font-size: 16px;
  color: #0066a2;
  display: flex;
  align-items: center;
  z-index: 2;
  cursor: pointer;
}

.research-topbar-dropdown-overlay {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 2;
}

.research-topbar-button:focus,
.research-topbar-button:hover,
.research-topbar-button.active {
  background-color: #0066a2;
  color: white;
}

@media (max-width: 999px) {
  .research-topbar-button {
    border: none;
    padding: 3px 8px;
  }
}

.research-topbar-dropdown-container {
  position: relative;
  z-index: 2;
}

.research-topbar-options-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
}

.research-topbar-options {
  overflow: visible;
  z-index: 11;
  background-color: #e7eaf2;
  box-shadow: 3px 3px 6px #00000055;
  border-radius: 5px;
  list-style-type: none;
  width: 280px;
}

@media (min-width: 768px) {
  .research-topbar-options {
    min-width: 280px;
    max-width: 350px;
  }
}

.research-topbar-options li {
  white-space: nowrap;
  font-size: 16px;
  padding: 8px 24px;
  color: var(--text-color-heading);
  cursor: pointer;
  border-bottom: 2px solid #73a8ca;
  user-select: none;
  width: 100%;
}

.research-topbar-options li:last-child {
  border-bottom: none;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.research-topbar-options li:first-child {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.research-topbar-options li.active {
  font-weight: bold;
}

.research-topbar-options li:hover {
  color: white;
  background-color: #0066a2;
}
</style>
