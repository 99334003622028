<template>
  <div
    :class="{ 'mt-0': getMobileView, 'mt-5': !getMobileView }"
    class="choice-situations-container"
  >
    <section
      v-if="!getMobileView"
      class="navigation-wrapper ps-3"
    >
      <AppNavigation :navigation-panel-top="true" />
    </section>
    <section class="ps-3 pe-3">
      <div
        :class="{ mobile: getMobileView }"
        class="section-title"
      >
        <h2
          :class="{ 'mb-2': !getMobileView }"
          style="padding: 0px"
        >
          {{ $t('pagination.dce_title') }}
        </h2>
        <span class="dce-counter">
          <LanguageSelector :to-be-selected="getDCECounter" />
        </span>
      </div>
      <p class="section-subtitle">
        {{ $t('pagination.dce_subtitle') }}
      </p>
    </section>
    <section class="mt-5">
      <DiscreteChoiceSet
        :alert-user-for-required-a="getAlertUserForRequiredA"
        :alert-user-for-required-b="getAlertUserForRequiredB"
        @send-current-user-data="updateUserDataDce"
      />
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DiscreteChoiceSet from './DiscreteChoiceSet.vue';
import AppNavigation from './AppNavigation.vue';
import { mapState } from 'pinia';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    DiscreteChoiceSet,
    AppNavigation,
  },

  props: {
    showUserInputIsRequired: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['sendCurrentUserData'],

  computed: {
    ...mapState(useInterfaceStore, [
      'getMobileView',
    ]),

    ...mapState(useConsultationStore, [
      'stage',
      'stageId',
      'getAnswerData',
      'stageOptions',
      'stageProperties',
      'canNavigateBack',
    ]),

    showBackButton() {
      return this.canNavigateBack;
    },

    currentUserData() {
      if (this.getAnswerData[this.stageId] === undefined) {
        return {};
      }

      return this.getAnswerData[this.stageId].answers;
    },

    getAlertUserForRequiredA() {
      return this.showUserInputIsRequired && this.currentUserData.user_has_answered_choice_situation === false;
    },

    getAlertUserForRequiredB() {
      return this.showUserInputIsRequired && this.currentUserData.user_has_answered_double_response === false;
    },

    getDCECounter() {
      const current = this.stage.fields.situationNumber;
      const total = this.stage.fields.situationCount;
      return `${this.$t('pagination.dce_question_count_label')} ${current} ${this.$t('pagination.of')} ${total}`;
    },
  },

  methods: {
    updateUserDataDce(data) {
      this.$emit('sendCurrentUserData', data);
    },
  },
});
</script>

<style scoped>
.choice-situations-container {
  width: 100%;
}

@media (min-width: 1020px) {
  .choice-situations-container {
    min-width: 1020px;
  }
}

.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile.section-title {
  display: block;
}

.mobile.section-title h2 {
  margin-bottom: 0px;
}

.section-subtitle {
  color: var(--text-color-content);
  font-weight: 600;
  max-width: 900px;
}

.dce-counter {
  color: rgb(72, 72, 72);
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: all 0.4s ease;
}

.component-fade-enter {
  transform: translateX(1000px);
  opacity: 0;
}

.component-fade-leave-to {
  transform: translateX(-1000px);
  opacity: 0;
}
</style>
