import { AxiosRequestConfig } from 'axios';
import { HttpClient } from '../Http/HttpClient';
import { CMS_BASE_URL } from '../../settings';

export class ConsultationSource {
  public static fetch(slug: string) {
    const http = new HttpClient(this.settings());
    return http.get(slug, {});
  }

  protected static settings(): AxiosRequestConfig<any> {
    return {
      baseURL: `${CMS_BASE_URL()}public/instance/`,
    };
  }
}
