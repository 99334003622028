function minLength(value: string, length: number) {
  return value.length >= length;
}

function maxLength(value: string, length: number) {
  return value.length <= length;
}

function fixedLength(value: string, length: number) {
  return value.length === length;
}

function isNumber(value: string, _: any) {
  return !isNaN(Number(value));
}

function pattern(value: string, pattern: string) {
  if (value.length !== pattern.length) {
    return false;
  }

  for (let i = 0; i < value.length; i++) {
    if (pattern[i] === 'l' && value[i].match(/[a-z]/i) === null) {
      return false;
    }

    if (pattern[i] === 'n' && isNaN(Number(value[i]))) {
      return false;
    }

    if (pattern[i] === '-' && value[i] !== '-') {
      return false;
    }
  }

  return true;
}

const RULES = {
  min: minLength,
  max: maxLength,
  size: fixedLength,
  numeric: isNumber,
  regex: pattern,
}

export function validateAnswer(answer: string | null, question: any, locale: string) {
  if (answer === null || question.rules === undefined || question.rules === null) {
    question.validation = undefined;
    return;
  }

  for (const [rule, value] of Object.entries(question.rules)) {
    const result = RULES[rule](answer, value);

    if (result === false) {
      const messages = question.messages[locale];

      if (messages && messages[rule]) {
        question.validation = messages[rule];
        return false;
      }
    }
  }

  question.validation = undefined;
}
