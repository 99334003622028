<template>
  <QuestionWrapper
    :question="currentQuestionData"
    :current-number="currentQuestionDisplayNumber"
    :total-number="totalQuestionNumber"
  >
    <div class="answer">
      <label>
        <textarea
          v-model="answerData.user_answered_string"
          :name="questionTitle"
          :style="borderColor"
          :placeholder="questionPlaceHolder"
          :maxLength="limitOfCharacters"
          autocomplete="off"
          rows="15"
          class="input-text-area-large question-border"
          @blur="userHasChosenAnswer(answerData.user_answered_string)"
          @click="triggerCheckPreviousRequired()"
          @keyup="checkCharacterLength(answerData.user_answered_string)"
        />
      </label>
    </div>
    <div
      :style="textOpacityB"
      class="alert-required-message my-2"
    >
      <LanguageSelector :to-be-selected="validationLabel" />
    </div>
    <div
      v-if="maxLimitReached"
      :style="textOpacityA"
      class="alert-required-message"
    >
      <LanguageSelector :to-be-selected="labelMaxCharacter" />
    </div>
  </QuestionWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import log from 'loglevel';
import QuestionWrapper from './QuestionWrapper.vue';

export default defineComponent({
  components: {
    QuestionWrapper,
  },
  props: {
    currentQuestionData: {
      type: Object,
      required: true,
    },
    showUserInputIsRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentQuestionNumber: {
      type: Number,
      required: true,
    },
    currentQuestionDisplayNumber: {
      type: Number,
      required: true,
    },
    totalQuestionNumber: {
      type: Number,
      required: true,
    },
    currentUserData: {
      type: Object,
      required: true,
    },
    validationLabel: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  emits: ['sendCurrentUserData'],

  data() {
    return {
      answerData: {},
      maxLimitReached: false,
    };
  },

  computed: {
    questionTitle() {
      return this.currentQuestionData.title;
    },

    questionPlaceHolder() {
      return '';
    },

    textOpacityA() {
      if (this.maxLimitReached) {
        return { opacity: 1 };
      }

      return { opacity: 0 };
    },

    textOpacityB() {
      return this.showUserInputIsRequired ? { opacity: '1' } : { opacity: '0' };
    },

    borderColor() {
      return this.showUserInputIsRequired
        ? { borderColor: '#D40005' }
        : { borderColor: '#E6E6E6' };
    },

    limitOfCharacters() {
      if (this.currentQuestionData.max_characters_allowed) {
        return this.currentQuestionData.max_characters_allowed;
      }

      return 10000;
    },

    labelMaxCharacter() {
      return this.$t('pagination.input_validation_max_amount_characters');
    },
  },

  created() {
    this.answerData = this.currentQuestionData;
    //  this should always be the case since in the store we create the array with userData for questions
    //  with at least an id and user_answered: false
    if (this.currentUserData) {
      if (
        this.currentUserData.user_has_answered &&
        this.currentUserData.raw_input_text
      ) {
        this.userHasChosenAnswer(this.currentUserData.raw_input_text);
      } else {
        this.answerData.user_has_answered = false;
        this.answerData.user_answered_string = null;
        this.answerData.raw_input_text = null;
      }
    } else {
      log.warn(
        'InputTypeTextAreaLarge: Something is wrong with the creation of userDataQuestions'
      );
      this.answerData.user_has_answered = false;
      this.answerData.user_answered_string = null;
      this.answerData.raw_input_text = null;
    }

    this.$emit('sendCurrentUserData', this.answerData);
  },

  methods: {
    userHasChosenAnswer(value) {
      let chosenAnswer = value || '';
      chosenAnswer = chosenAnswer.trim();

      this.answerData.user_has_answered = false;

      if (chosenAnswer === '') {
        this.answerData.user_has_answered = false;
        this.answerData.raw_input_text = null;
        this.$emit('sendCurrentUserData', this.answerData);
      } else {
        this.answerData.user_has_answered = true;
        this.answerData.user_answered_string = chosenAnswer;
        this.answerData.raw_input_text = value;
        this.$emit('sendCurrentUserData', this.answerData);
      }
    },

    triggerCheckPreviousRequired() {
      this.answerData.question_number = this.currentQuestionNumber;
      this.$emit('sendCurrentUserData', this.answerData);
    },

    checkCharacterLength(value) {
      //  value is initialy set to null in the created hook
      if (value !== null) {
        if (value.length >= this.limitOfCharacters) {
          this.maxLimitReached = true;
        } else {
          this.maxLimitReached = false;
        }
      }
    },
  },
});
</script>
