<template>
  <div class="dashboard-points-container col ms-0 me-0 p-0">
    <div class="ms-0 me-0">
      <div
        class="attribute-value-restrictive-title-wrapper row mt-0 ms-0 me-0 dashboard-m-28"
        style="position: relative"
      >
        <div
          v-show="attributeTitle"
          class="p-0"
        >
          <LanguageSelector
            :to-be-selected="attributeTitle"
            class="dashboard-headers"
          />
        </div>
      </div>
      <div class="pb-1 dashboard-restrictive-range d-flex align-items-center">
        <LanguageSelector
          :to-be-selected="exactLabel"
        />&nbsp;<AttributeValueConverter
          :current-attribute-data="attributeData"
          :hide-icons="true"
          :raw-value="targetValue"
        />
      </div>
      <div class="circle-wrapper row ms-auto me-auto">
        <svg
          id="svgWindow"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 200 200"
          width="100%"
          height="100%"
        >
          <g>
            <circle
              :r="sizeCircle"
              cx="100"
              cy="100"
              fill="nofill"
              stroke-dasharray="10"
              stroke-width="3"
            />
            <path
              id="circle"
              :d="circleProperties"
              :stroke="circleColor"
              :style="{
                strokeDasharray: circleLength,
                strokeDashoffset: circleValue,
              }"
              stroke-width="10"
              fill="none"
            />
          </g>
        </svg>
        <div class="icon-value-container d-flex flex-column">
          <div class="icon-value-wrapper d-flex flex-column mt-auto mb-auto">
            <div class="d-flex flex-row ms-auto me-auto">
              <span
                :style="{ color: valueColor }"
                class="dashboard-attribute-value"
              >
                <AttributeValueConverter
                  :current-attribute-data="attributeData"
                  :raw-value="tweened"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="p-0 flexible-range-warning-wrapper d-flex align-items-center flex-nowrap mb-4"
      >
        <font-awesome-icon
          :style="{ color: '#D30000', opacity: opacityFlexibleWarning }"
          class="flexible-range-warning-logo"
          icon="exclamation-circle"
        />
        <span class="ms-2 me-2 flexible-range-warning-message">
          <LanguageSelector
            :to-be-selected="message"
            :style="{ opacity: opacityFlexibleWarning }"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { gsap } from 'gsap';

export default defineComponent({
  props: {
    attributeTargetValue: {
      required: true,
      type: Number,
    },
    currentAttributeData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      circleSize: 70,
      tweened: 0,
      circleLength: 0,
      overRestrictiveState: {
        stroke: 'red',
        transition: 'all 1s ease-out',
      },
    };
  },

  computed: {
    currentColor() {
      return 'grey';
    },

    message() {
      if (this.currentAttributeValue > this.targetValue) {
        return this.$t('pagination.choice_task_over_target_warning_message');
      }

      return false;
    },

    opacityFlexibleWarning() {
      return this.message ? '1' : '0';
    },

    attributeData() {
      return this.currentAttributeData;
    },

    attributeTitle() {
      if (this.currentAttributeData.hasOwnProperty('short_title')) {
        return this.currentAttributeData.short_title;
      }

      return false;
    },

    currentAttributeValue() {
      return this.attributeData.current_calculated_value;
    },

    targetValue() {
      return this.attributeTargetValue;
    },

    exactLabel() {
      return this.$t('pagination.distribute');
    },

    circleColor() {
      if (this.targetReached) {
        return 'rgb(146, 195, 1)';
      } else if (this.overRestrictiveValue) {
        return 'rgb(212, 0, 5)';
      }

      return this.currentColor;
    },

    valueColor() {
      if (this.targetReached) {
        return 'rgb(146, 195, 1)';
      } else if (this.overRestrictiveValue) {
        return 'rgb(212, 0, 5)';
      }

      return 'black';
    },

    sizeCircle() {
      return this.circleSize;
    },

    overRestrictiveValue() {
      if (this.currentAttributeValue <= this.targetValue) {
        return false;
      }

      return true;
    },

    targetReached() {
      return this.targetValue === this.currentAttributeValue;
    },

    circleValue() {
      let percentage = this.currentAttributeValue / this.targetValue;

      if (percentage >= 1) {
        percentage = 1;
      }

      let value = percentage * this.circleLength;

      value = this.circleLength - value;

      return value;
    },

    circlePosX() {
      const posX = 200 / 2 + this.sizeCircle;
      return posX;
    },

    circlePosY() {
      const posY = 200 / 2;
      return posY;
    },

    circleProperties() {
      const d = [
        'M',
        this.circlePosX,
        this.circlePosY,
        'a',
        this.sizeCircle,
        this.sizeCircle,
        0,
        1,
        1,
        0,
        -0.00001,
      ].join(' ');

      return d;
    },
  },

  watch: {
    currentAttributeValue(n) {
      gsap.to(this, { duration: 0.5, tweened: Number(n) || 0 });
    },
  },

  created() {
    this.tweened = this.currentAttributeValue;
  },

  mounted() {
    const circle = document.querySelector('#circle');
    this.circleLength = circle.getTotalLength();
  },
});
</script>

<style scoped>
.dashboard-points-container {
  height: 100%;
  width: 100%;
  background-color: white;
}

.dashboard-restrictive-range {
  font-size: 16px;
  color: var(--text-color-content);
  line-height: 20px;
  font-weight: normal;
}

.flexible-range-warning-wrapper {
  transition: all 0.3s ease;
  font-size: 15px;
  color: var(--text-color-content);
  line-height: 20px;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.circle-wrapper {
  height: 100%;
  width: 100%;
  max-width: 180px;
  overflow: visible;
  position: relative;
}

#circle {
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  transform-origin: center center;
  transform: rotate(-90deg);
  transition: stroke-dashoffset 1s ease-out, stroke 1s ease-out;
}

.icon-value-container {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
}

svg {
  text-align: center;
  stroke: lightgrey;
  fill: none;
  stroke-width: 10;
  height: 100%;
  width: 100%;
}

.colorRed {
  stroke: red;
  color: red;
  transition: color 0.5s ease-out;
}
</style>
