<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue';

const props = defineProps<{
  show: boolean;
  closeFunction: () => void;
}>();

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    if (props.show) {
      props.closeFunction();
    }
  }
};

onMounted(() => {
  window.addEventListener('keydown', handleKeyDown);
});

onUnmounted(() => {
  window.removeEventListener('keydown', handleKeyDown);
});
</script>

<template>
  <div>
    <div
      v-if="show"
      class="backdrop"
      @click="closeFunction"
    />

    <transition name="dialog">
      <dialog
        v-if="show"
        open
      >
        <div class="dialog-closing-button-container d-flex justify-content-end p-3">
          <font-awesome-layers
            class="dialog-closing-button-wrapper"
            @click="closeFunction"
          >
            <font-awesome-icon
              icon="square"
              aria-hidden="true"
              class="closing-square-icon"
            />
            <font-awesome-icon
              icon="times"
              aria-hidden="true"
              class="closing-cross-icon"
            />
            <span class="visually-hidden">{{ $t('close') }}</span>
          </font-awesome-layers>
        </div>
        <div class="dialog-content-wrapper">
          <header class="dialog-title">
            <slot name="header" />
          </header>
          <main class="dialog-content mb-5">
            <slot name="content" />
          </main>
          <footer>
            <slot name="footer" />
          </footer>
        </div>
      </dialog>
    </transition>
  </div>
</template>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 50;
  transform: translate3d(0, 0, 50px);
  -webkit-transform: translate3d(0, 0, 50px);
}

dialog {
  position: fixed;
  z-index: 100;
  transform: translate3d(0, 0, 100px);
  -webkit-transform: translate3d(0, 0, 100px);
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0 auto;
  overflow: auto;
  background-color: white;
  top: calc(50% - 35vh);
  left: 0;
  width: 96%;
  max-height: 80vh;
  overflow-x: hidden;
}

@media (min-width: 1000px) {
  dialog {
    margin: auto;
    width: 95%;
    max-width: 900px;
  }
}

.dialog-content-wrapper {
  padding: 1rem 4rem 4rem 4rem;
}

.dialog-title {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: var(--text-color-heading);
}

.dialog-subtitle {
  font-weight: normal;
}

.dialog-content {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: var(--text-color-content);
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3s ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}
</style>
