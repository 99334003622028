import { Property } from "../../types/Property";

function isMinRestriction(property: Property) {
  return property.restrictive_min_value !== null;
}

function isMaxRestriction(property) {
  return property.restrictive_max_value !== null;
}

function rangeMinValue(property: Property) {
  if (isMinRestriction(property)) {
    return property.restrictive_min_value;
  }

  if (Number.isInteger(property.restrictive_min_scale)) {
    return property.restrictive_min_scale;
  }

  return property.total_possible_min_value;
}

function absoluteMinValue(property: Property) {
  if (Number.isInteger(property.restrictive_min_scale)) {
    return property.restrictive_min_scale;
  }

  return property.total_possible_min_value;
}

function absoluteMaxValue(property: Property) {
  if (Number.isInteger(property.restrictive_max_scale)) {
    return property.restrictive_max_scale;
  }

  return property.total_possible_max_value;
}

function isMinFlexRestriction(property: Property) {
  return property.flexible_min_value !== null && isMinRestriction(property);
}

function isMaxFlexRestriction(property: Property) {
  return property.flexible_max_value !== null && isMaxRestriction(property);
}

function speedoMinValue(property: Property) {
  if (Number.isInteger(property.restrictive_min_scale)) {
    return property.restrictive_min_scale;
  }

  // See explanation at speedoMaxValue (same principle but then with the minimum values / restrictions)
  if (isMinRestriction(property)) {
    if (absoluteMinValue(property) < rangeMinValue(property)) {
      return absoluteMinValue(property);
    } else {
      const rangeMin = rangeMinValue(property);
      if (rangeMin > 0) {
        return Math.floor(rangeMin / 2);
      } else {
        return rangeMin * 2;
      }
    }
  }

  return absoluteMinValue(property);
}

function rangeMaxValue(property: Property) {
  if (isMaxRestriction(property)) {
    return property.restrictive_max_value;
  }

  if (Number.isInteger(property.restrictive_max_scale)) {
    return property.restrictive_max_scale;
  }

  return property.total_possible_max_value;
}

function speedoMaxValue(property: Property) {
  if (Number.isInteger(property.restrictive_max_scale)) {
    return property.restrictive_max_scale;
  }

  //	When there is a max restriction the maximum displayed value (in the speedometer the right end) is either:
  //	The maximum reachable value (in normal cases) or
  //	When the editor set the max restriction above the reachable max value (could occur and is valid) then the speedometer range is extended
  //	(Hence the rangeMaxValue * 2).
  //	This has been set otherwise the red segment looks out of proportion small and the user won't get an indication of the max limit
  if (isMaxRestriction(property)) {
    if (absoluteMaxValue(property) >= rangeMaxValue(property)) {
      return absoluteMaxValue(property);
    } else {
      return rangeMaxValue(property) * 2;
    }
  }

  return absoluteMaxValue(property);
}

function rangeFlexMinValue(property: Property) {
  if (isMinFlexRestriction(property)) {
    return property.flexible_min_value;
  }

  return null;
}

function rangeFlexMaxValue(property: Property) {
  if (isMaxFlexRestriction(property)) {
    return property.flexible_max_value;
  }

  return null;
}

export function restrictiveSegments(property: Property) {
  if (isMinRestriction(property) && isMaxRestriction(property)) {
    // When the minimum restriction is lower then the user can reach
    if (rangeMinValue(property) < absoluteMinValue(property)) {
      if (isMinFlexRestriction(property) && !isMaxFlexRestriction(property)) {
        return [
          speedoMinValue(property),
          rangeMinValue(property),
          rangeFlexMinValue(property),
          rangeMaxValue(property),
          absoluteMaxValue(property),
        ];
      } else if (isMaxFlexRestriction(property) && !isMinFlexRestriction(property)) {
        return [
          speedoMinValue(property),
          rangeMinValue(property),
          rangeFlexMaxValue(property),
          rangeMaxValue(property),
          absoluteMaxValue(property),
        ];
      } else if (isMaxFlexRestriction(property) && isMinFlexRestriction(property)) {
        return [
          speedoMinValue(property),
          rangeMinValue(property),
          rangeFlexMinValue(property),
          rangeFlexMaxValue(property),
          rangeMaxValue(property),
          absoluteMaxValue(property),
        ];
      } else {
        return [
          speedoMinValue(property),
          rangeMinValue(property),
          rangeMaxValue(property),
          absoluteMaxValue(property),
        ];
      }
    } else if (rangeMaxValue(property) > absoluteMaxValue(property)) {
      //	When the maximum restriction is higher then the user can reach
      if (isMinFlexRestriction(property) && !isMaxFlexRestriction(property)) {
        return [
          absoluteMinValue(property),
          rangeMinValue(property),
          rangeFlexMinValue(property),
          rangeMaxValue(property),
          speedoMaxValue(property),
        ];
      } else if (isMaxFlexRestriction(property) && !isMinFlexRestriction(property)) {
        return [
          absoluteMinValue(property),
          rangeMinValue(property),
          rangeFlexMaxValue(property),
          rangeMaxValue(property),
          speedoMaxValue(property),
        ];
      } else if (isMaxFlexRestriction(property) && isMinFlexRestriction(property)) {
        return [
          absoluteMinValue(property),
          rangeMinValue(property),
          rangeFlexMinValue(property),
          rangeFlexMaxValue(property),
          rangeMaxValue(property),
          speedoMaxValue(property),
        ];
      } else {
        return [
          absoluteMinValue(property),
          rangeMinValue(property),
          rangeMaxValue(property),
          speedoMaxValue(property),
        ];
      }
    } else {
      if (isMinFlexRestriction(property) && !isMaxFlexRestriction(property)) {
        return [
          absoluteMinValue(property),
          rangeMinValue(property),
          rangeFlexMinValue(property),
          rangeMaxValue(property),
          absoluteMaxValue(property),
        ];
      } else if (isMaxFlexRestriction(property) && !isMinFlexRestriction(property)) {
        return [
          absoluteMinValue(property),
          rangeMinValue(property),
          rangeFlexMaxValue(property),
          rangeMaxValue(property),
          absoluteMaxValue(property),
        ];
      } else if (isMinFlexRestriction(property) && isMaxFlexRestriction(property)) {
        return [
          absoluteMinValue(property),
          rangeMinValue(property),
          rangeFlexMinValue(property),
          rangeFlexMaxValue(property),
          rangeMaxValue(property),
          absoluteMaxValue(property),
        ];
      } else {
        return [
          absoluteMinValue(property),
          rangeMinValue(property),
          rangeMaxValue(property),
          absoluteMaxValue(property),
        ];
      }
    }
  } else if (isMinRestriction(property) && !isMaxRestriction(property)) {
    if (absoluteMinValue(property) < rangeMinValue(property)) {
      if (isMinFlexRestriction(property)) {
        return [
          absoluteMinValue(property),
          rangeMinValue(property),
          rangeFlexMinValue(property),
          absoluteMaxValue(property),
        ];
      } else {
        return [absoluteMinValue(property), rangeMinValue(property), absoluteMaxValue(property)];
      }
    } else {
      if (isMinFlexRestriction(property)) {
        return [
          speedoMinValue(property),
          rangeMinValue(property),
          rangeFlexMinValue(property),
          absoluteMaxValue(property),
        ];
      } else {
        return [speedoMinValue(property), rangeMinValue(property), absoluteMaxValue(property)];
      }
    }
  } else if (isMaxRestriction(property) && !isMinRestriction(property)) {
    if (absoluteMaxValue(property) > rangeMaxValue(property)) {
      if (isMaxFlexRestriction(property)) {
        return [
          absoluteMinValue(property),
          rangeFlexMaxValue(property),
          rangeMaxValue(property),
          absoluteMaxValue(property),
        ];
      } else {
        return [absoluteMinValue(property), rangeMaxValue(property), absoluteMaxValue(property)];
      }
    } else {
      if (isMaxFlexRestriction(property)) {
        return [
          absoluteMinValue(property),
          rangeFlexMaxValue(property),
          rangeMaxValue(property),
          speedoMaxValue(property),
        ];
      } else {
        return [absoluteMinValue(property), rangeMaxValue(property), speedoMaxValue(property)];
      }
    }
  }

  return [absoluteMinValue(property), absoluteMaxValue(property)];
}
