import qs from 'query-string';

export const Norstat = {
  NAME: 'Norstat',

  isPanelUser(queryString) {
    const paramKeys = Object.keys(qs.parse(queryString.toLowerCase()));

    return (paramKeys.includes('source') && paramKeys.includes('state')) || paramKeys.includes('id');
  },

  getPanelParams(queryString) {
    const parsed = qs.parse(queryString.toLowerCase());

    if (parsed.id !== undefined) {
      return { id: parsed.id };
    }

    return { source: parsed.source, state: parsed.state };
  },
};
