import { LocaleString } from "../../../types/LocaleString";
import { Property } from "../../../types/Property";
import { calculatePropertyValues } from "../../Properties/calculatePropertyValues";

export function ChoiceTaskAdapter(data) {
  if (data.options.length === 0) {
    return [];
  }

  const segments = [];
  const componentMap = {
    'choice-task': 'SegmentChoiceTask',
    'motivate-all': 'SegmentChoiceTaskMotivateAll',
    'motivate-selection': 'SegmentChoiceTaskSelection',
    'motivate-non-selected': 'SegmentChoiceTaskUnchosen',
  };

  const versionId = Object.keys(data.versions)[(Math.random() * Object.keys(data.versions).length) | 0];
  const mode = data.settings.selection_mode;

  const randomOrder = data.settings.randomize_projects;
  let options = randomOrder ? data.options.sort(() => Math.random() - 0.5) : data.options;
  options = options.map(adaptOption)

  const versionData = data.versions[versionId] || {};
  let properties = data.properties || [];
  properties = properties.map((p) => adaptProperty(p, versionData, mode, data)).filter((property) => property !== null);

  for (const stage of data.stages) {
    let exactTargetProperty = false;

    if (data.settings.slide_to_max_data) {
      exactTargetProperty = data.settings.slide_to_max_data.exact_target_property;
    }

    const fields = {
      title: stage.title,
      subtitle: data.subtitle,
      version: versionId === undefined ? -1 : versionId,
      versionData: data.versions[versionId] || {},
      linearPropertyValues: data.settings.non_linear_property_values === false,
      options: options,
      properties: properties,
      mode: mode,
      exactTargetProperty: exactTargetProperty,
      allowSkipChoiceTask: data.settings.skip_choice_task,
      allowNoSelection: data.settings.allow_no_answer,
      pointsData: data.settings.points_data,
      slideToMaxData: data.settings.slide_to_max_data,
      openMotivation: undefined,
      skippedMotivation: undefined,
      randomOrder: randomOrder,
      hideDashboardRestrictionLabel: data.settings.hide_dashboard_restriction_label,
      showWarningPopup: data.settings.show_flexible_warning_popup
    }

    if (stage.type === 'choice-task') {
    } else if (stage.type === 'motivate-selection') {
      fields.openMotivation = data.settings.motivations.show_generic_motivation_text_input
      fields.skippedMotivation = data.settings.motivations.show_motivate_skip_choice_task
    } else if (stage.type === 'motivate-non-selected') {
    } else if (stage.type === 'motivate-all') {
      fields.skippedMotivation = data.settings.motivations.show_motivate_skip_choice_task
    }

    segments.push({
      id: `${stage.type}-${data.id}`,
      blockId: data.id,
      blockType: data.block_type,
      stageType: stage.type,
      component: componentMap[stage.type],
      conditions: data.conditions,
      hidden: false,
      fields: fields
    });
  }

  properties.forEach((property) => {
    calculatePropertyValues(property, options, segments[0]);
  });

  return segments;
}

export interface ApiProperty {
  id: number;
  unit_prefix: string | null;
  unit_postfix: string | null;
  calculation: 'add' | 'avg' | 'multiply' | 'none';
  hidden_restrictive_value_label: boolean;
  hidden_effect_direction_label: boolean;
  neutral_speedometer_colors: boolean;
  restrictive: boolean;
  initial_value: number;
  restrictive_icon: string | null;
  attribute_value_icon: string | null;
  slug: string | null;
  restrictive_max_value: number | null;
  restrictive_min_value: number | null;
  flexible_min_value: number | null;
  flexible_max_value: number | null;
  restrictive_min_scale: number | null;
  restrictive_max_scale: number | null;
  visibility: {
    visible_in_sort: boolean;
    visible_in_compare: boolean;
    visible_in_detailed: boolean;
    visible_in_right_bar: boolean;
    visible_in_option_card: boolean;
  };
  title: LocaleString;
  short_title: LocaleString;
  description: LocaleString;
  dictionary: any;
  attribute_value_dictionary: any;
  flexible_min_message: LocaleString;
  flexible_min_popup: LocaleString;
  flexible_max_message: LocaleString;
  flexible_max_popup: LocaleString;
  minimum_value_message: LocaleString;
  minimum_value_message_popup: LocaleString;
  maximum_value_message: LocaleString;
  maximum_value_message_popup: LocaleString;
  valid_value_message: LocaleString;
}

export function adaptProperty(property: ApiProperty, versionData, mode, data): Property {
  const values = [];
  let totalMin = property.initial_value;
  let totalMax = property.initial_value;

  for (const [optionId, propertyValues] of Object.entries(versionData)) {
    if (propertyValues[property.id] !== undefined) {
      let value = 0;

      if (Array.isArray(propertyValues[property.id])) { // Non-linear, non-symmetrical
        value = propertyValues[property.id][propertyValues[property.id].length - 1];
      } else if (typeof propertyValues[property.id] === 'object') { // Non-linear, symmetrical
        const keys = Object.keys(propertyValues[property.id]).sort(); // Highest key is last
        value = propertyValues[property.id][keys[keys.length - 1]];
      } else {
        value = propertyValues[property.id];
      }

      if (mode === 'points') {
        const totalPoints = data.settings.points_data.points_target;

        if (Math.sign(value) === 1) {
          if (value * totalPoints > totalMax) {
            totalMax = value * totalPoints;
          }
        } else if (Math.sign(value) === -1) {
          if (value * totalPoints < totalMin) {
            totalMin = value * totalPoints;
          }
        }
      } else {
        if (Math.sign(value) === 1) {
          totalMax += value;
        } else if (Math.sign(value) === -1) {
          totalMin = totalMin + value;
        }
      }

      values.push({
        id: parseInt(optionId),
        value: value
      });
    }
  }

  const adapted: Property = {
    ...property,
    values: values,
    current_calculated_value: property.initial_value,
    total_possible_min_value: totalMin,
    total_possible_max_value: totalMax,
    current_calculated_value_exceeds_restriction: null
  }

  return adapted;
}

export function adaptOption(option) {
  return {
    ...option,
    quantity: option.initial_quantity,
    step: 0,
  }
}
