import { Dynata } from './Dynata';
import { Norstat } from './Norstat';
import { Respondenten } from './Respondenten';

export const PanelService = {
  _PANELS: [Dynata, Norstat, Respondenten],

  getPanelParams(queryString) {
    const panel = this._PANELS.find((panel) => panel.isPanelUser(queryString));

    if (!panel) {
      this.unsetPanelUser();
      return null;
    }

    const panelParams = panel.getPanelParams(queryString);

    this.storePanelUser(panelParams, panel.NAME);

    return panelParams;
  },

  unsetPanelUser() {
    localStorage.removeItem('wevaluate.panel_id');
    localStorage.removeItem('wevaluate.panel_source');
  },

  storePanelUser(panelParams, panelName) {
    localStorage.setItem('wevaluate.panel_id', JSON.stringify(panelParams));
    localStorage.setItem('wevaluate.panel_source', panelName);
  },

  computeRedirectUrl(redirectUrl, params) {
    const url = new URL(redirectUrl);

    for (const [key, value] of Object.entries(params)) {
      url.searchParams.set(key, value);
    }

    return url.toString();
  },
};
