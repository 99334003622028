<script setup lang="ts">
import { computed } from 'vue';
import { useConsultationStore } from '../stores/useConsultationStore';

const props = defineProps<{
  option: Record<string, any>;
}>();

const consultationStore = useConsultationStore();

const showOptionDetailModal = computed({
  get: () => consultationStore.showOptionDetailModal,
  set: (value) => (consultationStore.showOptionDetailModal = value),
});
const locale = computed(() => consultationStore.locale);

const label = computed(() => {
  return `optionInfoButton` in props ?
    useI18n().t('optionInfoButton', { optionTitle: props.option.title[locale.value] }) : '';
});

const showOptionDetails = () => {
  showOptionDetailModal.value = props.option;
};
</script>

<template>
  <button
    class="info-button-wrapper"
    tabindex="0"
    :aria-label="label"
    @click="showOptionDetails"
  >
    <font-awesome-layers class="info-button button-hover-animation">
      <font-awesome-icon icon="square" />
      <font-awesome-icon
        :style="{ color: 'white' }"
        icon="info"
        transform="shrink-8"
      />
    </font-awesome-layers>
  </button>
</template>
