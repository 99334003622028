<script setup lang="ts">
import { computed } from 'vue';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { useConsultationStore } from '../stores/useConsultationStore';
import MobileLogo from '@/assets/img/logo-mobile.svg';
import DesktopLogo from '@/assets/img/logo-desktop.svg';

const interfaceStore = useInterfaceStore();
const consultationStore = useConsultationStore();

const getMobileView = computed(() => interfaceStore.getMobileView);
const logo = computed(() => interfaceStore.getMobileView ? MobileLogo : DesktopLogo);
</script>

<template>
  <div class="logo-wrapper">
    <img
      :src="logo"
      :class="{ 'logo-mobile': getMobileView, 'logo-desktop': !getMobileView }"
      :alt="$t('appName')"
    >
  </div>
</template>

<style scoped>
.logo-wrapper {
  height: 32px;
}

.logo-desktop {
  width: 152.7px;
  height: 32px;
}

.logo-mobile {
  width: 32px;
  height: 32px;
}
</style>
