<template>
  <div class="avc-soft-property-value-wrapper">
    <span
      v-if="showSoftPropertyIcon"
      class="avc-soft-property-icons"
    >
      <font-awesome-icon
        v-if="showPolarityLabel && calculativeValue < 0"
        icon="arrow-down"
        transform="shrink-4"
        class="m2-1 soft-property-icon"
      />
      <font-awesome-icon
        v-else-if="showPolarityLabel && calculativeValue > 0"
        icon="arrow-up"
        transform="shrink-4"
        class="ms-1 soft-property-icon"
      />
      <font-awesome-icon
        v-for="index in softPropertyIconQuantity"
        :key="index"
        :icon="softPropertyIcon"
        class="soft-property-icon"
        transform="shrink-2"
        :style="marginPropertyIcon"
      />
    </span>
    <span
      v-else
      class="avc-value"
    >
      {{ value }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import log from 'loglevel';
import { mapState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  props: {
    rawValue: {
      type: Number,
      default: undefined,
    },
    optionId: {
      type: Number,
      default: undefined,
    },
    currentAttributeData: {
      type: Object,
      default: undefined,
    },
    returnPositiveNumber: {
      type: Boolean,
      default: false,
    },
    returnRawNumber: {
      type: Boolean,
      default: false,
    },
    hideIcons: {
      type: Boolean,
      default: false,
    },
    calculativeDictionary: {
      type: Boolean,
      default: false,
    },
    returnValueOnly: {
      type: Boolean,
      default: false,
    },
    returnPostFixOnly: {
      type: Boolean,
      default: false,
    },
    returnPreFixOnly: {
      type: Boolean,
      default: false,
    },
    language: {
      type: String,
      default: undefined,
    },
  },

  emits: ['sendCurrentLabel', 'sendQuantityOfIcons'],

  data() {
    return {
      softPropertyIconQuantity: null,
      currentLabel: null,
      calculativeValue: null,
    };
  },

  computed: {
     ...mapState(useConsultationStore, [
      'locale'
    ]),

    showPolarityLabel() {
      if (!this.currentAttributeData) {
        return false;
      }

      const { hidden_effect_direction_label } = this.currentAttributeData;

      return hidden_effect_direction_label === false;
    },

    currentLanguage() {
      if (this.language) {
        return this.language;
      }

      return this.locale;
    },

    value() {
      const value = this.choosePath();

      if (value === undefined || this.showSoftPropertyIcon) {
        return '-';
      }

      return value;
    },

    softPropertyIcon() {
      if (this.currentAttributeData) {
        return this.currentAttributeData.attribute_value_icon || null;
      }

      return null;
    },

    showSoftPropertyIcon() {
      return (
        !this.hideIcons &&
        this.softPropertyIcon &&
        this.softPropertyIconQuantity > 0
      );
    },

    justifyContent() {
      if (this.justifyEnd === true) {
        return {
          justifyContent: 'flex-end',
        };
      } else if (this.justifyEnd === false) {
        return {
          justifyContent: 'flex-start',
        };
      } else {
        return {
          justifyContent: 'center',
        };
      }
    },

    marginPropertyIcon() {
      if (this.softPropertyIconQuantity > 1) {
        return {
          marginLeft: '2px',
        };
      }

      return {};
    },
  },

  watch: {
    rawValue() {
      this.choosePath();
    },

    currentLabel(label) {
      this.$emit('sendCurrentLabel', label);
    },

    softPropertyIconQuantity(amountOfIcons) {
      this.$emit('sendQuantityOfIcons', amountOfIcons);
    },
  },

  created() {
    // initialize data properties
    this.currentLabel = '';
    this.softPropertyIconQuantity = 0;
  },

  methods: {
    choosePath() {
      const returnRaw = this.returnRawNumber;
      const incomingValue = this.rawValue;
      const id = this.optionId;
      const data = this.currentAttributeData;

      if (incomingValue || incomingValue === 0) {
        if (data && !returnRaw) {
          return this.attributePropCheck(incomingValue, data);
        } else if (returnRaw) {
          return incomingValue;
        } else {
          return '-';
        }
      } else if (id) {
        if (data) {
          const tempValue = this.searchAttributeValue(id, data);

          if (!returnRaw) {
            return this.attributePropCheck(tempValue, data);
          } else {
            return tempValue;
          }
        }
      }

      return '-';
    },

    searchAttributeValue(id, data) {
      const result = data.values.find((value) => value.id === id);

      return result ? result.value : '-';
    },

    attributePropCheck(value, data) {
      if (value === undefined) {
        return this.handleUndefinedValue(value);
      }

      let tempValue = value;
      if (isNaN(tempValue)) {
        return '-';
      }

      tempValue = Math.round(tempValue);

      if (this.returnPostFixOnly) {
        if (data.hasOwnProperty('unit_postfix') && data.unit_postfix !== null) {
          return data.unit_postfix;
        } else {
          return;
        }
      } else if (this.returnPreFixOnly) {
        if (data.hasOwnProperty('unit_prefix') && data.unit_prefix !== null) {
          return data.unit_prefix;
        } else {
          return;
        }
      }

      const dict = this.calculativeDictionary
        ? data.dictionary
        : data.attribute_value_dictionary;

      if (dict !== null && Object.keys(dict).length > 0) {
        let dictionaryData;
        // first check if data structure is correct (when dict is an array it's not correct)
        // else check if there is a language selected
        // otherwise just select the first language available
        if (Array.isArray(dict)) {
          dictionaryData = dict;
          log.error('dictionaryData is not saved correctly');
        } else if (this.currentLanguage && dict.hasOwnProperty(this.currentLanguage)) {
          //	check if selected language is available in the dictionary otherwise use the first available language
          dictionaryData = dict[this.currentLanguage]
            ? dict[this.currentLanguage]
            : dict[Object.keys(dict)[0]];
        } else {
          dictionaryData = dict[Object.keys(dict)[0]];
        }

        if (dictionaryData === null || dictionaryData.length > 0) {
          return this.dictionaryValue(tempValue, dictionaryData);
        }
      }

      if (this.returnPositiveNumber && Math.sign(tempValue) === -1) {
        tempValue = tempValue * -1;
      }

      // Checks if the value needs to have 2 decimals
      if (data.is_decimal === 1) {
        tempValue = tempValue.toFixed(2);
      }

      // Convert back to string so we can easily add the pre and postfix.
      tempValue = tempValue.toString();

      //  else check for prefix or postfix
      if (
        data.hasOwnProperty('unit_prefix') &&
        data.unit_prefix !== null &&
        !this.returnValueOnly
      ) {
        tempValue = data.unit_prefix + ' ' + tempValue;
      }

      if (
        data.hasOwnProperty('unit_postfix') &&
        data.unit_postfix !== null &&
        !this.returnValueOnly
      ) {
        tempValue = tempValue + ' ' + data.unit_postfix;
      }

      tempValue = tempValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      return tempValue;
    },

    handleUndefinedValue(value) {
      const label = this.$t('pagination.dce_empty_value_fallback');

      if (label) {
        return label;
      }

      if (this.currentLanguage === 'nl') {
        return 'n.v.t.';
      } else if (this.currentLanguage === 'en') {
        return 'N/A';
      }

      return '-';
    },

    dictionaryValue(tempValue, dictionaryData) {
      tempValue = tempValue.toString();

      // Try to find the value in the dictionary.
      const result = this.findMatchingDictionaryValue(tempValue, dictionaryData);

      // If we didn't find a matching value, return a fallback.
      if (!result) {
        log.warn(
          `Expected to find a dictionary value for attribute "${this.currentAttributeData.id}" with key "${tempValue}"`
        );
        return '-';
      }

      // Check if we should display icons or not.
      const iconQuantity = parseInt(result.icon_quantity);
      if (!isNaN(iconQuantity)) {
        this.softPropertyIconQuantity = iconQuantity;
        this.calculativeValue = parseInt(tempValue);
        return result.label;
      }

      // Otherwise we just return the label.
      this.currentLabel = result.label;
      return result.label;
    },

    findMatchingDictionaryValue(tempValue, dictionaryData) {
      // First check if the value is present in the dictionary as a single value
      const result = dictionaryData.find(
        (dictionaryItem) => dictionaryItem.value === tempValue
      );
      if (result) {
        return result;
      }

      const NUMERIC_REGEXP = /[-]{0,1}[\d]*/g;

      // Secondly, check if the value is present in the dictionary as a range
      const resultRange = dictionaryData.find((dictionaryItem) => {
        if (dictionaryItem.value === undefined) {
          return false;
        }

        let range = dictionaryItem.value.match(NUMERIC_REGEXP);
        range = range.filter((element) => element !== '');

        // Return if the value isn't a range.
        if (range.length < 2) {
          return false;
        }

        // 1-2 is parsed as ['1', '-2'] but should be ['1', '2']
        if (range.length === 2) {
          range[1] = range[1].replace('-', '');
        }

        // -5--2 is parsed as ['-5', '-', '-2'] but should be ['-5', '-2']
        if (range.length === 3) {
          range = range.filter((element) => element !== '-');
        }

        if (range.length !== 2) {
          log.warn(
            `Range "${dictionaryItem.value}" could not be parsed correctly: [${range}]`
          );
          return false;
        }

        const rangeStart = parseInt(range[0]);
        const rangeEnd = parseInt(range[1]);

        return tempValue >= rangeStart && tempValue <= rangeEnd;
      });

      // Return the result if we found one.
      if (resultRange) {
        return resultRange;
      }

      // Return false at last, to indicate that we didn't find a match
      return false;
    },
  },
});
</script>

<style scoped>
.soft-property-icon {
  color: var(--text-color-content);
}

.avc-soft-property-icons {
  display: flex;
}
</style>
