<template>
  <div class="dce-container ">
    <div
      :class="{ 'd-flex': !getMobileView }"
      class="justify-content-center"
    >
      <div>
        <DiscreteChoiceSituations
          :show-user-input-is-required="showUserInputIsRequired"
          @send-current-user-data="updateData"
        />
      </div>
    </div>
    <div
      class="page-navigation mb-navigation mx-auto"
      style="max-width: 1024px; width: 100%;"
    >
      <AppNavigation :submit-function="submit" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DiscreteChoiceSituations from './DiscreteChoiceSituations.vue';
import AppNavigation from './AppNavigation.vue';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { mapState, mapWritableState } from 'pinia';
import { useAnalyticsStore } from '../stores/useAnalyticsStore';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    DiscreteChoiceSituations,
    AppNavigation,
  },

  setup() {
    const analyticsStore = useAnalyticsStore();
    const consultationStore = useConsultationStore();
    return { analyticsStore, consultationStore };
  },

  data() {
    return {
      showUserInputIsRequired: false,
    };
  },

  computed: {
    ...mapState(useInterfaceStore, [
      'getMobileView',
    ]),

    ...mapWritableState(useConsultationStore, [
      'nextDisabled',
    ]),

    ...mapState(useConsultationStore, [
      'stage',
      'stageId',
      'getAnswerData',
      'getBlockId',
      'getSequenceNumber',
    ]),

    currentUserData() {
      if (this.getAnswerData[this.stageId] === undefined) {
        return {};
      }

      return this.getAnswerData[this.stageId].answers;
    },
  },

  created() {
    const data = this.currentUserData;

    if (data.user_has_answered_choice_situation === true) {
      this.nextDisabled = false;
    } else {
      this.nextDisabled = true;
    }
  },

  methods: {
    submit() {
      const data = this.currentUserData;
      const { doubleResponse } = this.stage.fields;

      if (data.user_has_answered_choice_situation === false) {
        this.showUserInputIsRequired = true;
        return;
      }

      if (doubleResponse === true && data.user_has_answered_double_response === false) {
        this.showUserInputIsRequired = true;
        return;
      }

      this.consultationStore.submitDiscreteChoiceAnswerData(this.stageId);
      this.analyticsStore.trackUserAction(['Submit dce data', this.getBlockId, this.getSequenceNumber]);
    },

    updateData(data) {
      const { doubleResponse } = this.stage.fields;

      if (doubleResponse === true && data.user_has_answered_choice_situation === true && data.user_has_answered_double_response === true) {
        this.nextDisabled = false;
        this.showUserInputIsRequired = false;
      } else if (doubleResponse === false && data.user_has_answered_choice_situation === true) {
        this.nextDisabled = false;
        this.showUserInputIsRequired = false;
      } else {
        this.nextDisabled = true;
      }

      const payload = {
        stageId: this.stageId,
        data: data
      };

      this.consultationStore.setAnswerData(payload);
    }
  },
});
</script>

<style scoped>
.dce-container {
  position: relative;
}

.choice-situations {
  width: 80%;
  max-width: 1120px;
}

@media (max-width: 1440px) {
  .choice-situations {
    width: 100%;
  }
}
</style>
