<template>
  <input
    value="0"
    max="100"
    type="range"
    min="0"
    class="row-fluid question-border ms-auto me-auto mt-1 w-100 slider-progress-disabled disabled-slider px-0"
  >
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  mounted() {
    for (const e of document.querySelectorAll(
      'input[type="range"].slider-progress-disabled'
    )) {
      e.style.setProperty('--min', e.min == '' ? '0' : e.min);
      e.style.setProperty('--max', e.max == '' ? '100' : e.max);
      e.style.setProperty('--value', e.value);
      e.addEventListener('input', () => e.style.setProperty('--value', e.value));
    }
  },
});
</script>
