export const en = {
  appName: "Wevaluate",
  help: "Help",
  close: "Close",
  optionInfoButton: "Information: {optionTitle}",
  privacyStatement: "Privacy Statement",
  accessibility_info: {
    accessibility: "Accessibility",
    title: "Accessibility features of this consultation"
  }
}
