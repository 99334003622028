<template>
  <button class="add-button d-flex justify-content-end align-items-end p-0 m-0">
    <font-awesome-layers
      class="add-button-circle "
      :class="{ 'button-hover-animation': !skipChoiceTask && !isMuted }"
      @click="userHasSelected()"
    >
      <font-awesome-icon
        :style="colorOfButton"
        icon="circle"
      />
      <font-awesome-icon
        :style="{ color: 'white' }"
        :icon="currentIcon"
        transform="shrink-6"
      />
    </font-awesome-layers>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useConsultationStore } from '../stores/useConsultationStore';
import { mapActions, mapState, mapWritableState } from 'pinia';

export default defineComponent({
  props: {
    option: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapWritableState(useConsultationStore, [
      'nextDisabled',
    ]),

    ...mapState(useConsultationStore, [
      'stage',
      'stageId',
      'getAnswerData',
      'skipChoiceTask',
    ]),

    getMutedOptionsData() {
      const channel = this.option.interaction_channel;

      if (!channel) {
        return {};
      }

      const answerData = this.getAnswerData[this.stageId] ? this.getAnswerData[this.stageId].answers : {};

      const other = Object.values(answerData).find((option) => option.interaction_channel === channel && option.id !== this.option.id);

      return {
        muted: other ? other.quantity !== 0 : false,
        mutedBy: other ? other.title : null,
      };
    },

    selected() {
      const answerData = this.getAnswerData[this.stageId] ? this.getAnswerData[this.stageId].answers : {};
      return answerData[this.option.id] && answerData[this.option.id].quantity === 1;
    },

    currentIcon() {
      if (this.skipChoiceTask) {
        return 'plus';
      }

      return this.selected ? 'minus' : 'plus';
    },

    colorOfButton() {
      if (this.skipChoiceTask) {
        return {
          color: '#B7B7B7',
          opacity: 0.5,
          transition: 'all 0.3s ease-in',
        };
      }

      if (!this.selected) {
        if (this.isMuted) {
          return {
            color: 'grey',
            opacity: 0.5,
            transition: 'all 0.3s linear',
          };
        }

        return {
          color: '#205d86',
          transition: 'all 0.5s linear',
        };
      }

      return {
        color: 'grey',
        transition: 'all 0.2s linear',
      };
    },

    mutedData() {
      return this.getMutedOptionsData || {};
    },

    isMuted() {
      return this.mutedData.muted;
    },

    mutedBy() {
      return this.mutedData.mutedBy;
    },
  },

  methods: {
    ...mapActions(useConsultationStore, [
      'calculateOptionEffects',
      'setAnswerData',
    ]),

    userHasSelected() {
      if (this.isMuted || this.skipChoiceTask) {
        return;
      }

      const answerData = this.getAnswerData[this.stageId] ? this.getAnswerData[this.stageId].answers : {};

      const data = {...this.option}
      data.quantity = this.selected ? 0 : 1;
      data.skipped = this.skipChoiceTask;

      answerData[data.id] = data;

      const payload = {
        stageId: this.stageId,
        data: answerData
      };

      this.setAnswerData(payload);
      this.calculateOptionEffects();

      if (this.stage.fields.allowNoSelection || Object.keys(answerData).length > 0) {
        this.nextDisabled = false;
      } else {
        this.nextDisabled = true;
      }
    },
  },
});
</script>

<style scoped>
button.add-button {
  background-color: transparent;
  border-style: none;
  height: fit-content;
}

.button-color-transition {
  transition: color 0.3s ease-out;
}

.disabled-button {
  color: #b7b7b7;
  cursor: not-allowed !important;
  transition-property: none !important;
}
</style>
