import { useConsultationStore } from "../stores/useConsultationStore";

export default {
  install: (app, options) => {
    // inject a globally available $translate() method
    app.config.globalProperties.$translate = (obj: false | object) => {
      if (typeof obj !== 'object') {
        return;
      }

      const store = useConsultationStore();

      return obj[store.locale];
    }
  }
}
