<template>
  <div class="form-group">
    <QuestionTitle
      :question-title="questionTitle"
      :is-required="isRequired"
      :current-number="currentQuestionDisplayNumber"
      :total-number="totalQuestionNumber"
    />

    <div class="d-flex">
      <div v-show="hasDragged">
        <div
          v-for="number in orderedOptions.length"
          :key="number"
          class="mb-2 me-3 d-flex align-items-center"
          :style="{ height: getHeightForPrefix(number) }"
        >
          <div class="item-number">
            {{ number }}
          </div>
        </div>
      </div>
      <draggable
        v-model="orderedOptions"
        :group="`options-group-${currentQuestionData.id}`"
        ghost-class="ghost-item"
        item-key="id"
        class="flex-grow-1"
        @end="userOrderedAnswers"
      >
        <template #item="{ element, index }">
          <div
            :id="`options-item-${currentQuestionData.id}-${index}`"
            class="question-border d-flex drag-item-container flex-grow-1"
            style="padding: 10px"
            :style="borderColor"
          >
            <div class="d-flex align-items-center">
              <div class="drag-handle">
                <span class="handle-dot" />
                <span class="handle-dot" />
                <span class="handle-dot" />
                <span class="handle-dot" />
                <span class="handle-dot" />
                <span class="handle-dot" />
                <span class="handle-dot" />
                <span class="handle-dot" />
              </div>
            </div>
            <div class="flex-grow-1 ms-3">
              {{ element.title }}
            </div>
          </div>
        </template>
      </draggable>
    </div>

    <div
      v-show="showUserInputIsRequired"
      class="my-2"
    >
      <div
        class="alert-required-message opacity-1 mb-4"
        style="opacity: 1"
      >
        <LanguageSelector :to-be-selected="labelNegativeValidationMessage" />
      </div>
      <div
        class="alert-required-message mb-2"
        style="opacity: 1"
      >
        <LanguageSelector
          :to-be-selected="labelNegativeValidationConfirmCurrentOrder"
        />
      </div>
      <button
        class="navigation-button navigation-button-next order-confirm-button"
        @click="userOrderedAnswers"
      >
        <LanguageSelector
          :to-be-selected="labelNegativeValidationConfirmCurrentOrderButton"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import draggable from 'vuedraggable';
import QuestionTitle from './QuestionTitle.vue';
import { mapState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    QuestionTitle,
    draggable,
  },
  props: {
    currentQuestionData: {
      type: Object,
      required: true,
    },
    showUserInputIsRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentQuestionNumber: {
      type: Number,
      required: true,
    },
    currentQuestionDisplayNumber: {
      type: Number,
      required: true,
    },
    totalQuestionNumber: {
      type: Number,
      required: true,
    },
    currentUserData: {
      type: Object,
      required: true,
    },
  },

  emits: ['sendCurrentUserData'],

  data() {
    return {
      orderedOptions: [],
      hasDragged: false,
      answerData: {},
    };
  },

  computed: {
    ...mapState(useConsultationStore, [
      'locale',
    ]),

    questionTitle() {
      return this.currentQuestionData.title;
    },

    answerOptions() {
      if (!this.currentQuestionData.answer_options) {
        return [];
      }

      let answers = this.currentQuestionData.answer_options.map((answer) => {
        return {
          id: answer.id,
          title: answer.title[this.locale],
        };
      });

      answers = answers.filter((answer) => {
        return answer.title !== undefined;
      });

      if (this.currentQuestionData.randomize_answer_order) {
        answers = answers.sort(() => Math.random() - 0.5);
      }

      return answers;
    },

    isRequired() {
      return this.currentQuestionData.required;
    },

    borderColor() {
      return this.showUserInputIsRequired
        ? { borderColor: '#D40005' }
        : { borderColor: '#E6E6E6' };
    },

    labelNegativeValidationMessage() {
      return this.$t('pagination.input_drag_in_order_validation_required');
    },

    labelNegativeValidationConfirmCurrentOrder() {
      return this.$t('pagination.input_drag_in_order_confirm_current_order');
    },

    labelNegativeValidationConfirmCurrentOrderButton() {
      return this.$t('pagination.input_drag_in_order_confirm_current_order_button');
    },
  },

  created() {
    const answerData = this.currentUserData ? { ...this.currentUserData } : {};

    if (answerData && answerData.user_has_answered === true) {
      this.hasDragged = true;
      this.orderedOptions = answerData.raw_ordered_answers;
    } else {
      this.orderedOptions = this.answerOptions;
      answerData.user_has_answered = false;
      answerData.user_answered_array = null;
      answerData.raw_ordered_answers = null;
    }

    answerData.id = this.currentQuestionData.id;
    answerData.title = this.questionTitle;
    answerData.required = this.isRequired;
    answerData.randomize_answer_order =
      this.currentQuestionData.randomize_answer_order;
    answerData.field_type = this.currentQuestionData.field_type;
    answerData.question_number = this.currentQuestionNumber;
    answerData.answer_options = this.orderedOptions;
    answerData.answer_id = [];

    this.answerData = answerData;

    this.$emit('sendCurrentUserData', answerData);
  },

  methods: {
    userOrderedAnswers() {
      this.answerData.raw_ordered_answers = this.orderedOptions;
      this.answerData.user_has_answered = true;
      this.answerData.user_answered_array = this.orderedOptions.map(
        (option) => option.title
      );

      this.answerData.answer_id = this.orderedOptions.map(
        (option) => option.id
      );

      this.$emit('sendCurrentUserData', this.answerData);
      this.hasDragged = true;
    },

    getHeightForPrefix(number) {
      const id = `options-item-${this.currentQuestionData.id}-${number - 1}`;
      const element = document.getElementById(id);

      if (!element) {
        return '46px';
      }

      return `${element.offsetHeight}px`;
    },
  },
});
</script>

<style scoped>
.drag-handle {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
}

.handle-dot {
  width: 4px;
  height: 4px;
  background: #d9d9d9;
  border-radius: 100%;
}

.ghost-item .handle-dot {
  background: #f2f2f2;
}

.drag-item-container:not(:last-child) {
  margin-bottom: 0.5rem;
}

.drag-item-container:hover {
  cursor: pointer;
}

.drag-item-container:active {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 25px #7474742b;
  cursor: pointer;
}

.ghost-item {
  opacity: 1;
  color: #f2f2f2;
  background: #f2f2f2;
}

.item-number {
  color: #004876;
  font-size: 20px;
  font-weight: bold;
}

.order-confirm-button {
  width: auto;
  padding: 8px 23px;
}
</style>
