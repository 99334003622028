<template>
  <div class="research-instruction-cues-wrapper">
    <div class="instruction-cue-header-wrapper">
      <h2 class="mt-0">
        <LanguageSelector :to-be-selected="instructionCueHeader" />
      </h2>
    </div>
    <div
      v-if="isPickMode"
      class="instruction-cues-pick-mode-wrapper"
    >
      <p class="instruction-cues d-inline-block m-0">
        <LanguageSelector :to-be-selected="instructionCueStartSentence" />&nbsp;
      </p>
      <font-awesome-layers
        class="add-button-circle d-inline-block"
        style="cursor: auto; font-size: 28px"
      >
        <font-awesome-icon icon="circle" />
        <font-awesome-icon
          :style="{ color: 'white' }"
          icon="plus"
          transform="shrink-6"
        />
      </font-awesome-layers>
      <p class="instruction-cues m-0 d-inline-block">
        &nbsp;
        {{ $t('pagination.choice_task_instructions_buttons') }}
        &nbsp;
      </p>
      <p class="instruction-cues m-0 d-inline-block">
        <LanguageSelector :to-be-selected="instructionCueSub" />
      </p>
    </div>
    <div
      v-else-if="isPointsMode"
      class="instruction-cues-points-mode-wrapper"
    >
      <p class="instruction-cues d-inline-block m-0">
        <LanguageSelector :to-be-selected="instructionCueStartSentence" />&nbsp;
      </p>
      <font-awesome-layers
        class="add-button-circle d-inline-block"
        style="cursor: auto; font-size: 28px"
      >
        <font-awesome-icon icon="circle" />
        <font-awesome-icon
          :style="{ color: 'white' }"
          icon="plus"
          transform="shrink-6"
        />
      </font-awesome-layers>
      <p class="instruction-cues m-0 d-inline-block">
        {{ $t('pagination.choice_task_instructions_and') }}&nbsp;
      </p>
      <p class="instruction-cues m-0 d-inline-block me-1">
        <font-awesome-layers
          v-if="isPointsMode"
          class="add-button-circle d-inline-block"
          style="cursor: auto; font-size: 28px"
        >
          <font-awesome-icon icon="circle" />
          <font-awesome-icon
            :style="{ color: 'white' }"
            icon="minus"
            transform="shrink-6"
          />
        </font-awesome-layers>
      </p>
      <p class="instruction-cues m-0 d-inline-block">
        <LanguageSelector :to-be-selected="$t('pagination.choice_task_instructions_buttons')" />&nbsp;
      </p>
      <p class="instruction-cues m-0 d-inline-block">
        <LanguageSelector :to-be-selected="instructionCueSub" />
      </p>
    </div>
    <div v-else-if="isSliderMode">
      <p class="instruction-cues m-0 d-inline-block">
        <LanguageSelector
          :to-be-selected="instructionCueStartSentence"
        />&nbsp;<LanguageSelector
          v-if="isSliderMode"
          :to-be-selected="instructionCueSub"
        />
      </p>
    </div>
    <p v-if="subtitle">
      <LanguageSelector :to-be-selected="subtitle" />
    </p>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  computed: {
    ...mapState(useConsultationStore, [
      'stage',
      'isSliderMode',
      'isPointsMode',
      'isPickMode',
      'inCompareMode',
    ]),

    subtitle() {
      return this.stage.fields.subtitle
    },

    instructionCueStartSentence() {
      if (this.isPickMode || this.isPointsMode) {
        return this.$t('pagination.choice_task_instructions_use_the');
      }

      // else assume a slider mode
      return this.$t('pagination.choice_task_instructions_use_the_sliders');
    },

    instructionCueHeader() {
      if (this.inCompareMode) {
        return this.$t('pagination.choice_task_instruction_header_compare');
      }

      const fallbackTitle = this.stage.fields.title;

      if (this.isPickMode) {
        return this.$t('pagination.choice_task_instruction_header_pick') || fallbackTitle
      } else if (this.isSliderMode) {
        return this.$t('pagination.choice_task_instruction_header_slide') || fallbackTitle
      } else if (this.isPointsMode) {
        return this.$t('pagination.choice_task_instruction_header_points') || fallbackTitle
      }

      return fallbackTitle;
    },

    instructionCueSub() {
      if (this.isPickMode) {
        return this.$t('pagination.choice_task_instruction_sub_pick');
      } else if (this.isPointsMode) {
        return this.$t('pagination.choice_task_instruction_sub_points');
      }

      // Else assume a slider mode
      return this.$t('pagination.choice_task_instruction_sub_slide');
    },
  },
});
</script>

<style scoped>
.instruction-cues {
  font-weight: bold;
}
</style>
