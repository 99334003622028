<script setup lang="ts">
import { ref, computed } from 'vue';

const props = defineProps<{
  wikiData: {
    wiki_word: string;
    wiki_content: string;
  };
}>();

const isWikiOpen = ref(false);

const wikiWord = computed(() => props.wikiData.wiki_word);
const wikiContent = computed(() => props.wikiData.wiki_content);

const rotateIconStyle = computed(() => {
  return isWikiOpen.value ? { transform: 'rotate(90deg)' } : {};
});

const openWiki = () => {
  isWikiOpen.value = !isWikiOpen.value;
};
</script>

<template>
  <span>
    <button
      role="button"
      class="wiki-link"
      tabindex="0"
      @click="openWiki"
    >
      <font-awesome-icon
        :style="rotateIconStyle"
        class="more-detail-expandable-arrow-icon"
        style="font-size: 0.8rem"
        icon="chevron-right"
      />
      <span class="wiki-link-underline ms-1"><LanguageSelector :to-be-selected="wikiWord" /></span>
    </button>
    <transition name="dropdown">
      <span
        v-if="isWikiOpen"
        class="wiki-box p-4 mt-4 mb-4"
      ><LanguageSelector :to-be-selected="wikiContent" /></span>
    </transition>
  </span>
</template>

<style>
.wiki-link {
  color: var(--text-color-heading);
  cursor: pointer;
  user-select: none;
  white-space: break-spaces;
  font-weight: 500;
  background-color: transparent;
  border: none;
}

.wiki-link:hover {
  color: var(--text-color-heading);
  filter: brightness(2);
}

.wiki-link-underline span {
  text-decoration: underline;
  white-space: break-spaces;
}

.wiki-box {
  display: block;
  background: #f2f2f2;
}
</style>
