<template>
  <div class="form-group">
    <QuestionTitle
      :question-title="questionTitle"
      :is-required="isRequired"
      :current-number="currentQuestionDisplayNumber"
      :total-number="totalQuestionNumber"
    />

    <fieldset
      :id="`question-${currentQuestionData.id}`"
      :style="borderColor"
      class="form-check ps-0 question-border mb-1"
    >
      <legend class="visually-hidden">
        {{ $translate(questionTitle) }}
      </legend>
      <div
        v-for="answer in answerOptions"
        :key="answer.id"
        class="checkbox-answer-option p-2 ps-4"
      >
        <label
          class="checkbox-wrapper position-relative d-flex align-items-center"
          :for="`answer-${answer.id}`"
          tabindex="0"
          @pointerup="toggleAnswer(answer)"
          @keydown.enter="toggleAnswer(answer)"
        >
          <input
            :id="`answer-${answer.id}`"
            class="checkbox-input"
            type="checkbox"
            :tabindex="-1"
            :value="answer.id"
          >
          <div
            class="checkmark-wrapper d-flex align-items-center position-relative me-4"
          >
            <span class="checkbox" />
            <span
              v-if="userChecked[answer.id]"
              class="checkmark"
            />
          </div>
          <span class="answer-option">{{ answer.title }}</span>
        </label>
      </div>
      <div>
        <div
          v-if="isOpenAnswer"
          class="checkbox-answer-option p-2 ps-4"
        >
          <label
            class="checkbox-wrapper d-flex align-items-center"
            tabindex="0"
          >
            <input
              id="index"
              v-model="userCheckedOpenAnswer"
              tabindex="-1"
              class="checkbox-input"
              type="checkbox"
              value="answer"
            >
            <div
              class="checkmark-wrapper d-flex align-items-center position-relative me-4"
            >
              <span class="checkbox" />
              <span
                v-if="userCheckedOpenAnswer"
                class="checkmark"
              />
            </div>
            <span class="answer-option">
              {{ $t('pagination.input_checkbox_open_answer') }}
            </span>
          </label>
        </div>
        <div
          v-if="userCheckedOpenAnswer"
          class="checkbox-answer-option checkbox-open-answer-option d-flex"
        >
          <div class="pseudo-checkbox" />
          <label class="checkbox-open-answer-option-label w-100 me-2 mb-2">
            <input
              v-model.lazy.trim="openAnswer"
              :name="questionTitle"
              :style="borderColor"
              autocomplete="off"
              type="text"
              class="form-control question-border w-100 p-2"
              @click="triggerCheckPreviousRequired()"
            >
          </label>
        </div>
      </div>
    </fieldset>
    <div
      :style="textOpacity"
      class="alert-required-message my-2"
    >
      {{ validationLabel }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import QuestionTitle from './QuestionTitle.vue';
import { mapState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    QuestionTitle,
  },
  props: {
    currentQuestionData: {
      type: Object,
      required: true,
    },
    showUserInputIsRequired: Boolean,
    currentQuestionNumber: {
      type: Number,
      required: true,
    },
    currentQuestionDisplayNumber: {
      type: Number,
      required: true,
    },
    totalQuestionNumber: {
      type: Number,
      required: true,
    },
    currentUserData: {
      type: Object,
      required: true,
    },
    validationLabel: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  emits: ['sendCurrentUserData'],

  data() {
    return {
      answerData: {},
      alertUser: false,
      userChecked: {},
      userCheckedOpenAnswer: false,
      openAnswer: '',
    };
  },

  computed: {
    ...mapState(useConsultationStore, [
      'locale',
    ]),

    questionTitle() {
      return this.currentQuestionData.title;
    },

    answerOptions() {
      if (!this.currentQuestionData.answer_options) {
        return [];
      }

      const answers = this.currentQuestionData.answer_options.map((answer) => {
        return {
          id: answer.id,
          title: answer.title[this.locale],
        };
      });

      return answers.filter((answer) => {
        return answer.title !== undefined;
      });
    },

    isOpenAnswer() {
      return this.currentQuestionData.open_answer;
    },

    openAnswerFilled() {
      if (this.answerData.user_answered_string) {
        return true;
      }

      return false;
    },

    isRequired() {
      return this.currentQuestionData.required;
    },

    borderColor() {
      return this.showUserInputIsRequired
        ? { borderColor: '#D40005' }
        : { borderColor: '#E6E6E6' };
    },

    textOpacity() {
      return this.showUserInputIsRequired ? { opacity: '1' } : { opacity: '0' };
    },
  },

  watch: {
    alertUserForRequired(boolean) {
      this.alertUser = this.alertUserForRequired;
    },

    userCheckedOpenAnswer(value) {
      this.answerData.raw_input_check_box_open_answer = value;

      if (value === false) {
        const answeredOptions = Object.values(this.userChecked).some(
          (a) => a === true
        );

        this.answerData.user_has_answered = answeredOptions === true;
        this.answerData.raw_input_text = null;
        this.answerData.user_answered_string = null;
        this.openAnswer = '';
      }

      this.$emit('sendCurrentUserData', this.answerData);
    },

    openAnswer(value) {
      if (value === '') {
        this.answerData.raw_input_text = null;
        this.answerData.user_answered_string = null;
        this.alertUser = true;
      } else {
        this.alertUser = false;
        this.answerData.user_has_answered = true;
        this.answerData.user_answered_string = value;
        this.answerData.raw_input_text = value;
      }

      this.$emit('sendCurrentUserData', this.answerData);
    },
  },

  created() {
    const answerData = this.currentUserData ? { ...this.currentUserData } : {};

    if (answerData.user_has_answered === true) {
      this.userChecked = answerData.raw_input_check_box_table;
      this.userCheckedOpenAnswer = answerData.raw_input_check_box_open_answer;
      this.openAnswer = answerData.raw_input_text;
    } else {
      answerData.user_has_answered = false;
      answerData.user_answered_string = null;
      answerData.user_answered_array = null;
      answerData.raw_input_check_box_open_answer = false;
      answerData.raw_input_text = null;
      answerData.answer_id = null;

      this.answerOptions.forEach((answer) => {
        this.userChecked[answer.id] = false;
      });

      answerData.raw_input_check_box_table = { ...this.userChecked };
    }

    answerData.id = this.currentQuestionData.id;
    answerData.title = this.questionTitle;
    answerData.required = this.isRequired;
    answerData.field_type = this.currentQuestionData.field_type;
    answerData.question_number = this.currentQuestionNumber;
    answerData.answer_options = this.answerOptions;

    this.answerData = answerData;
    this.$emit('sendCurrentUserData', answerData);
  },

  methods: {
    toggleAnswer(answer) {
      this.userChecked[answer.id] = !this.userChecked[answer.id];
      this.answerData.raw_input_check_box_table = { ...this.userChecked };

      this.answerData.answer_id = [];

      Object.keys(this.userChecked).forEach((id) => {
        if (this.userChecked[id]) {
          this.answerData.answer_id.push(parseInt(id));
        }
      });

      this.answerData.user_answered_array = [];
      this.answerOptions.forEach((option) => {
        if (this.userChecked[option.id]) {
          this.answerData.user_answered_array.push(option.title);
        }
      });

      const answeredOptions = Object.values(this.userChecked).some(
        (a) => a === true
      );

      this.answerData.user_has_answered = answeredOptions || this.openAnswerFilled;

      if (!this.answerData.user_has_answered) {
        this.alertUser = true;
        this.answerData.user_answered_array = null;
      }

      this.$emit('sendCurrentUserData', this.answerData);
    },

    triggerCheckPreviousRequired() {
      this.answerData.question_number = this.currentQuestionNumber;
      this.$emit('sendCurrentUserData', this.answerData);
    },
  },
});
</script>

<style scoped>
.checkbox-wrapper {
  height: 100%;
  min-height: 50px;
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-wrapper :focus {
  outline: 1px dotted;
}

/* Hide the browser's default checkbox */
.checkbox-wrapper input {
  position: relative;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark-wrapper {
  min-height: 19px;
  min-width: 19px;
}

/* Create a custom checkbox */
.checkbox {
  box-sizing: content-box;
  position: absolute;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: white;
  border: solid #e9eff4;
  border-radius: 6px;
}

/* On mouse-over, add a grey background color */
.checkbox-wrapper:hover input ~ .checkmark {
  background-color: #ccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark {
  box-sizing: content-box;
  position: absolute;
  left: 0;
  top: -2px;
}

/* Style the checkmark/indicator */
.checkbox-wrapper .checkmark {
  left: 5px;
  bottom: 2px;
  width: 5px;
  height: 14px;
  border: solid #fd1f9b;
  border-radius: 2px;
  border-width: 0 5px 5px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.checkbox-answer-option {
  cursor: pointer;
  position: relative;
  height: 100%;
  min-height: 45px;
  background-color: white;
  box-sizing: border-box;
  border-radius: 25px;
  white-space: nowrap;
}

.pseudo-checkbox {
  width: 65px;
}
</style>
