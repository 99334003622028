import { defineStore } from 'pinia';

interface State {
  _mobileView: boolean | null;
  _landscapeDimensions: boolean | null;
  _theme: string;
}

export const useInterfaceStore = defineStore('InterfaceStore', {
  state: (): State => ({
    _mobileView: null,
    _landscapeDimensions: null,
    _theme: 'light',
  }),

  getters: {
    theme: ({ _theme }): string => _theme,

    getMobileView: ({ _mobileView }): boolean | null => _mobileView,

    isLandscapeMode: ({ _mobileView, _landscapeDimensions }): boolean | null => {
      if (_mobileView === null) {
        return null;
      }

      return _mobileView && _landscapeDimensions;
    },
  },

  actions: {
    setLandscapeDimensions(bool: boolean | null) {
      this._landscapeDimensions = bool;
    },

    setMobileView(bool: boolean | null) {
      this._mobileView = bool;
    },
  },
});
