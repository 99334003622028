<template>
  <div>
    <div
      v-if="show"
      class="dialog-backdrop"
      @click="closeDialog"
    />
    <transition name="dialog-transition">
      <dialog
        v-if="show"
        open
        class="dialog-modal-container"
      >
        <div
          class="d-flex justify-content-end ms-0 dialog-closing-button-container p-1 p-sm-3 p-md-3 p-lg-3 mb-0 mb-sm-1 mb-md-2 mb-lg-3"
        >
          <font-awesome-layers
            class="dialog-closing-button-wrapper"
            @click="closeDialog"
          >
            <font-awesome-icon
              icon="square"
              class="closing-square-icon"
            />
            <font-awesome-icon
              icon="times"
              class="closing-cross-icon"
            />
          </font-awesome-layers>
        </div>
        <div
          class="dialog-content d-flex flex-column p-3 p-sm-4 p-md-5 pt-0 pt-sm-0 pt-md-0 pt-lg-0"
        >
          <div class="d-flex flex-row flex-nowrap mb-md-4 mb-2 align-items-center">
            <div
              v-if="optionImage"
              class="project-image-wrapper d-flex"
              :class="{ 'me-2': getMobileView }"
            >
              <img
                :src="optionImage"
                class="project-image-detail-small"
              >
            </div>
            <div
              v-if="optionTitle"
              class="col col-lg-9 project-detail-title-wrapper p-0"
            >
              <h2 class="dialog-title">
                {{ $translate(optionTitle) }}
              </h2>
            </div>
          </div>
          <div class="project-description-image-wrapper d-flex mt-0">
            <div
              v-if="shortDescription"
              class="col-12 col-md-8 col-lg-8 project-detail-short-description-wrapper p-0"
            >
              <LanguageSelector
                :to-be-selected="shortDescription"
                class="project-detail-short-description"
              />
            </div>
            <div
              v-if="optionImage"
              class="project-image-wrapper col-md-4 col-12 d-flex justify-content-md-center align-items-start mb-3 mb-md-0 justify-content-start"
            >
              <img
                :src="optionImage"
                class="project-image-detail"
              >
            </div>
          </div>
          <div
            v-if="fullDescription"
            class="more-detail-expandable-wrapper-double-border d-flex flex-column mt-5"
          >
            <div
              class="more-detail-expandable-trigger pointer d-flex align-items-center pt-2 pb-2"
              @click="openFullDescription"
            >
              <div
                class="more-detail-expandable-icon-wrapper more-detail-arrows pt-auto pb-auto ps-0"
              >
                <font-awesome-icon
                  :style="returnRotateIcon()"
                  class="more-detail-expandable-arrow-icon"
                  icon="chevron-right"
                />
              </div>
              <div class="more-detail-expandable-title-wrapper ms-4">
                <LanguageSelector
                  :to-be-selected="labelFullDescriptionButton"
                  class="more-detail-expandable-title"
                />
              </div>
            </div>
            <div class="more-detail-content-outer-wrapper">
              <transition name="dropdown">
                <div class="more-detail-content-container d-flex flex-row">
                  <div class="pseudo-col" />
                  <div
                    v-if="isFullDescriptionOpen"
                    class="project-detail-full-description-wrapper col ms-4 mt-2 mb-3"
                  >
                    <LanguageSelector
                      :to-be-selected="fullDescription"
                      class="project-detail-full-description"
                    />
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div
            v-if="attributeData"
            class="project-detail-title-wrapper mt-5 mb-3"
          >
            <LanguageSelector
              :to-be-selected="labelPropertiesOfOptionTitle"
              class="more-detail-attributes-title"
            />
          </div>

          <div
            v-if="attributeData"
            class="project-detail-attribute-wrapper"
          >
            <div
              v-for="(attribute, index) in attributeData"
              :key="attribute.id"
              :style="returnBorderTop(index)"
              class="more-detail-expandable-wrapper-single-border"
            >
              <div
                :class="{ pointer: hasAttributeDescription(index) }"
                class="more-detail-expandable-trigger d-flex align-items-center py-2 flex-nowrap"
                @click="openCurrentMoreDetail(index)"
              >
                <div
                  class="more-detail-expandable-trigger-content-container d-flex flex-column flex-sm-row p-0 m-0 w-100"
                >
                  <div
                    class="more-detail-chevron-polarity-wrapper d-flex flex-row p-0 m-0"
                  >
                    <div
                      v-if="hasAttributeDescription(index)"
                      class="more-detail-expandable-icon-wrapper more-detail-arrows ps-0"
                    >
                      <font-awesome-icon
                        :style="returnRotateIconArray(index)"
                        icon="chevron-right"
                        class="more-detail-expandable-arrow-icon"
                      />
                    </div>
                    <div v-else />
                    <div v-if="!hidePolarityLabel(attribute)">
                      <div class="more-detail-prefix-wrapper ms-md-4 me-2">
                        <LanguageSelector
                          :to-be-selected="returnMoreDetailPrefix(attribute.id)"
                          class="more-detail-attribute-title-bold"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="more-detail-attribute-title-value-wrapper d-flex flex-row align-items-center"
                  >
                    <div
                      class="more-detail-attribute-title-wrapper d-flex justify-content-start"
                    >
                      <LanguageSelector
                        :to-be-selected="attribute.short_title"
                        class="more-detail-attribute-title-normal"
                      />
                    </div>
                    <div class="ms-1 ms-md-3">
                      <span class="more-detail-attribute-title-bold">
                        <AttributeValueConverter
                          :current-attribute-data="attribute"
                          :option-id="optionId"
                          style="white-space: nowrap"
                        />
                      </span>
                    </div>
                    <div class="ms-1 ms-md-3">
                      <font-awesome-icon
                        v-if="attribute.restrictive_icon"
                        :icon="attribute.restrictive_icon"
                        class="more-detail-inline-attribute-icon"
                        :style="{ color: '#004876' }"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <transition
                v-if="hasAttributeDescription(index)"
                name="dropdown"
              >
                <div class="more-detail-pseudo-col-description-wrapper d-flex flex-row">
                  <div class="pseudo-col" />
                  <div
                    v-if="returnIsCurrentMoreDetailOpen(index)"
                    class="project-detail-full-description-wrapper mt-2 mb-3 ms-4"
                  >
                    <LanguageSelector
                      v-if="attribute.description"
                      :to-be-selected="attribute.description"
                    />
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </dialog>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { mapState, mapWritableState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isFullDescriptionOpen: false,
      isMoreDetailOpenArray: [],
      rotateArrowIcon: {
        transform: 'rotate(45deg)',
      },
    };
  },

  computed: {
    ...mapState(useInterfaceStore, [
      'getMobileView',
    ]),

    ...mapWritableState(useConsultationStore, [
      'showOptionDetailModal',
    ]),

    ...mapState(useConsultationStore, [
      'locale',
      'stageProperties',
    ]),

    optionId() {
      return this.showOptionDetailModal.id;
    },

    optionTitle() {
      return this.showOptionDetailModal.title;
    },

    optionImage() {
      if (this.showOptionDetailModal.img !== null) {
        return this.showOptionDetailModal.img;
      }

      return false;
    },

    shortDescription() {
      return this.showOptionDetailModal.short_description;
    },

    fullDescription() {
      if (!this.showOptionDetailModal.full_description) {
        return false;
      }

      if (typeof this.showOptionDetailModal.full_description === 'string') {
        return this.showOptionDetailModal.full_description;
      }

      if (typeof this.showOptionDetailModal.full_description === 'object' && this.showOptionDetailModal.full_description[this.locale]) {
        return this.showOptionDetailModal.full_description;
      }

      return false;
    },

    labelFullDescriptionButton() {
      return this.$t('pagination.choice_task_option_detail_full_description_title');
    },

    labelPropertiesOfOptionTitle() {
      return this.$t('pagination.choice_task_option_detail_properties_title');
    },

    attributeData() {
      const filteredProperties = this.stageProperties.filter(
        (property) => property.visibility.visible_in_detailed === true
      );

      if (filteredProperties.length === 0) {
        return false;
      }

      return filteredProperties;
    },
  },

  created() {
    if (!this.attributeData) {
      return;
    }

    this.attributeData.forEach((attribute) => {
      this.isMoreDetailOpenArray.push(false);
    });
  },

  mounted() {
    document.addEventListener('keydown', this.handleKeyDown);
  },

  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  },

  methods: {
    handleKeyDown(event) {
      if (event.key === 'Escape') {
        this.closeDialog();
      }
    },

    closeDialog() {
      this.showOptionDetailModal = false;
    },

    openFullDescription() {
      if (!this.isFullDescriptionOpen) {
        this.isFullDescriptionOpen = true;
      } else {
        this.isFullDescriptionOpen = false;
      }
    },

    hidePolarityLabel(attributeData) {
      const { hidden_effect_direction_label } = attributeData;
      return (
        hidden_effect_direction_label && hidden_effect_direction_label === true
      );
    },

    openCurrentMoreDetail(index) {
      if (!this.isMoreDetailOpenArray[index]) {
        this.isMoreDetailOpenArray[index] = true;

        for (let i = 0; i < this.isMoreDetailOpenArray.length; i++) {
          if (i !== index) {
            this.isMoreDetailOpenArray[i] = false;
          }
        }
      } else {
        this.isMoreDetailOpenArray[index] = false;
      }
    },

    returnIsCurrentMoreDetailOpen(index) {
      return this.isMoreDetailOpenArray[index];
    },

    returnBorderTop(index) {
      if (index === 0) {
        return {
          borderTopStyle: 'solid',
        };
      }
    },

    returnMoreDetailPrefix(id) {
      let data = this.attributeData;

      let value = null;

      for (let i = 0; i < data.length; i++) {
        if (data[i].id === id) {
          data = data[i];
          break;
        }
      }

      for (let j = 0; j < data.values.length; j++) {
        if (data.values[j].id === this.optionId) {
          value = data.values[j].value;
          break;
        }
      }

      if (Math.sign(value) === 1) {
        return this.$t('pagination.choice_task_option_detail_property_increase');
      } else if (Math.sign(value) === -1) {
        return this.$t('pagination.choice_task_option_detail_property_decrease');
      } else {
        return this.$t('pagination.choice_task_option_detail_property_neutral');
      }
    },

    hasAttributeDescription(index) {
      const attributeData = this.attributeData[index];

      if (typeof attributeData.description === 'string') {
        return true;
      }

      if (
        attributeData.description &&
        attributeData.description[this.locale]
      ) {
        return true;
      }

      return false;
    },

    returnRotateIcon() {
      if (this.isFullDescriptionOpen) {
        return {
          transform: 'rotate(90deg)',
        };
      }
    },

    returnRotateIconArray(index) {
      if (this.isMoreDetailOpenArray[index]) {
        return {
          transform: 'rotate(90deg)',
        };
      }
    },
  },
});
</script>

<style scoped>
dialog {
  position: fixed;
  width: 100vw;
  z-index: 100;
  transform: translate3d(0, 0, 100px);
  -webkit-transform: translate3d(0, 0, 100px);
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: auto;
  background-color: white;
  top: calc(50% - 35vh);
  left: 0;
  max-height: 80vh;
  overflow-x: hidden;
}

@media (min-width: 1000px) {
  dialog {
    margin: auto;
    width: 95%;
    max-width: 1160px;
  }
}

.project-detail-title {
  font-weight: bold;
  line-height: 2rem;
  font-size: 1.5rem;
  color: var(--text-color-heading);
}

.project-detail-short-description {
  color: black;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.7rem;
}

.project-detail-full-description,
p {
  margin-top: 0;
  margin-bottom: 0;
}

.more-detail-arrows {
  color: var(--text-color-heading);
}

.more-detail-expandable-title {
  font-size: 1rem;
  font-weight: 700;
  color: var(--text-color-heading);
}

.more-detail-attribute-title-bold {
  font-size: 1rem;
  font-weight: 700;
  color: var(--text-color-heading);
}

.more-detail-attribute-title-normal {
  font-size: 1rem;
  font-weight: 400;
  color: var(--text-color-heading);
  text-align: start;
}

.more-detail-expandable-wrapper-double-border {
  border-top-style: solid;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}

.more-detail-expandable-wrapper-single-border {
  /*  border-top-style: solid;*/
  border-bottom-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}

.more-detail-expandable-trigger {
  user-select: none;
}

.pointer {
  cursor: pointer;
}

.more-detail-expandable-arrow-icon {
  transition: all 0.05s ease-in;
}

.more-detail-chevron-polarity-wrapper {
  min-width: 15%;
}

.more-detail-attributes-title {
  font-size: 1, 25rem;
  font-weight: 700;
  letter-spacing: 0px;
  color: var(--text-color-heading);
}

.project-image-detail-small {
  border-radius: 50%;
  object-fit: cover;
  user-select: none;
}

.project-image-detail {
  border-radius: 50%;
  object-fit: cover;
  user-select: none;
  display: normal;
}

img.project-image-detail-small {
  display: flex;
  width: 70px;
  height: 70px;
}

@media (min-width: 998px) {
  img.project-image-detail-small {
    display: none;
    width: 170px;
    height: 170px;
  }
}

img.project-image-detail {
  display: none;
  width: 80px;
  height: 80px;
}

@media (min-width: 998px) {
  img.project-image-detail {
    display: flex;
    width: 170px;
    height: 170px;
  }
}

@media (min-width: 1441px) {
  img.project-image-detail {
    display: flex;
    width: 250px;
    height: 250px;
  }
}

.pseudo-col {
  width: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}
</style>

<style>
#string-contents *:first-child {
  margin-top: 0;
}

#string-contents *:last-child {
  margin-bottom: 0;
}
</style>
