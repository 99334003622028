<template>
  <div class="header-container border-top border-left border-right">
    <div>
      <div class="header-actions">
        <div
          @mouseover="hover = true"
          @mouseleave="hover = false"
        >
          <ChoiceTaskComparePickMode
            v-if="isPickMode"
            :option="option"
          />
          <ChoiceTaskComparePointsMode
            v-else-if="isPointsMode"
            :option="option"
            class="mx-1"
          />
          <ChoiceTaskCompareSliderMode
            v-else-if="isSliderMode"
            :option="option"
          />
        </div>
        <OptionInfoButton
          :option="option"
          class="col-2 p-0 ms-3"
        />
      </div>
      <LanguageSelector :to-be-selected="option.title" />
    </div>
    <div
      :style="returnOpacity"
      class="header-card-item-overlay"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <div
        class="overlay-text p-3"
        style="position: relative"
      >
        <span :style="returnLabelColor">
          {{ $t('pagination.choice_task_option_muted_by') }}
        </span>
        <LanguageSelector
          :style="returnLabelColor"
          :to-be-selected="mutedBy"
          style="font-weight: 800"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ChoiceTaskComparePickMode from './ChoiceTaskComparePickMode.vue';
import ChoiceTaskComparePointsMode from './ChoiceTaskComparePointsMode.vue';
import ChoiceTaskCompareSliderMode from './ChoiceTaskCompareSliderMode.vue';
import OptionInfoButton from './OptionInfoButton.vue';
import { mapState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    ChoiceTaskComparePickMode,
    ChoiceTaskComparePointsMode,
    ChoiceTaskCompareSliderMode,
    OptionInfoButton,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    ...mapState(useConsultationStore, [
      'stageId',
      'isSliderMode',
      'isPickMode',
      'isPointsMode',
      'getAnswerData',
    ]),

    getMutedOptionsData() {
      const channel = this.option.interaction_channel;

      if (!channel) {
        return {};
      }

      const answerData = this.getAnswerData[this.stageId] ? this.getAnswerData[this.stageId].answers : {};

      const other = Object.values(answerData).find((option) => option.interaction_channel === channel && option.id !== this.option.id);

      return {
        muted: other ? other.quantity !== 0 : false,
        mutedBy: other ? other.title : null,
      };
    },

    isHover() {
      return this.hover;
    },

    isSelected() {
      return false;
    },

    showOverlay() {
      return this.isPickMode && this.isHover && this.isMuted;
    },

    returnOpacity() {
      if (this.showOverlay) {
        return {
          opacity: 0.8,
          background: 'white',
        };
      } else {
        return {
          opacity: 0,
          display: 'none',
        };
      }
    },

    mutedData() {
      return this.getMutedOptionsData || {};
    },

    isMuted() {
      return this.mutedData.muted;
    },

    mutedBy() {
      return this.mutedData.mutedBy;
    },

    returnLabelColor() {
      if (this.showOverlay) {
        return {
          opacity: 1,
          color: 'red',
        };
      } else {
        return {
          color: 'grey',
          opacity: 0,
        };
      }
    },
  },
});
</script>

<style scoped>
.header-actions {
  margin-bottom: 20px;
  padding: 0px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-container {
  position: relative;
  border-radius: 25px 25px 0px 0px;
  background-color: white;
  color: var(--text-color-heading);
  text-align: center;
  padding: 20px;
  width: 100%;
  height: 100%;
}
.header-card-item-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 25px 25px 0px 0px;
  cursor: not-allowed;
}
.header-container p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
}
</style>
