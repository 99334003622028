<template>
  <div class="page-container">
    <div class="card page">
      <div class="page-title">
        <h1 v-show="title">
          <font-awesome-layers style="position: relative; top: 5px; font-size: 29px">
            <font-awesome-icon icon="comment-alt" />
            <font-awesome-icon
              style="color: white"
              transform="shrink-5 up-2"
              icon="square"
            />
            <font-awesome-icon
              style="color: white"
              transform="right-4 up-7 shrink-1"
              icon="circle"
            />
            <font-awesome-icon
              style=""
              transform="right-4 up-7 shrink-1"
              icon="globe"
            />
          </font-awesome-layers>
          <LanguageSelector
            :to-be-selected="title"
            class="ms-3"
          />
        </h1>
      </div>
      <div class="page-questions">
        <InputTypeDropdown
          :key="question.id"
          :current-question-data="question"
          :show-user-input-is-required="alert"
          :current-user-data="userData"
          :validation-label="$t('pagination.input_validation_no_selection_message')"
          style="max-width: 335px"
          @send-current-user-data="updateData"
        />
      </div>
      <div class="page-navigation">
        <AppNavigation :submit-function="submit" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useAnalyticsStore } from '../stores/useAnalyticsStore';
import InputTypeDropdown from './InputTypeDropdown.vue';
import AppNavigation from './AppNavigation.vue';
import { mapActions, mapState, mapWritableState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    InputTypeDropdown,
    AppNavigation,
  },

  setup() {
    const analyticsStore = useAnalyticsStore();
    return { analyticsStore };
  },

  data() {
    return {
      alert: false,
      userData: {
        id: 0,
        user_has_answered: false,
      },
      listOfLanguages: null,
      languageAnswerOptions: null,
    };
  },

  computed: {
    ...mapWritableState(useConsultationStore, [
      'nextDisabled',
    ]),

    ...mapState(useConsultationStore, [
      'locale',
      'getAvailableLocales',
    ]),

    title() {
      return (
        this.choiceSelectionLabelDefaultLanguage + this.choiceSelectionLabelEnglish
      );
    },

    choiceSelectionLabelDefaultLanguage() {
      return this.$t('pagination.language_language_selection');
    },

    choiceSelectionLabelEnglish() {
      return this.locale === 'en' ? '' : ' / Language selection';
    },

    answerOptions() {
      const answerOptions = [];

      for (const [locale, title] of Object.entries(this.getAvailableLocales)) {
        const optionTitle = {};
        optionTitle[this.locale] = locale === 'nl' ? 'Nederlands' : title;

        answerOptions.push({
          id: locale,
          title: optionTitle,
        });
      }

      return answerOptions;
    },

    question() {
      return {
        id: 0,
        answer_options: this.answerOptions,
        required: true,
      };
    },
  },

  created() {
    this.nextDisabled = true;
  },

  methods: {
    ...mapActions(useConsultationStore, ['setLocale']),

    updateData(data) {
      this.userData = data;
      if (data.answer_id !== null) {
        this.setLocale(data.answer_id);
        this.$i18n.locale = data.answer_id;
        this.nextDisabled = false;
        this.alert = false;
      }
    },

    submit() {
      if (this.userData.user_has_answered === false) {
        this.alert = true;
        return;
      }

      this.analyticsStore.trackUserAction([`Language chosen: ${this.locale}`]);
    },
  },
});
</script>
