<template>
  <BaseModal
    :show="show"
    :close-function="closeModal"
  >
    <template #header>
      <h1 class="mt-0 mb-3">
        {{ $t('pagination.choice_task_past_restrictive_value') }}
      </h1>
      <p class="dialog-subtitle">
        {{ $t('pagination.restrictive_maximum_value_message_popup') }}
      </p>
    </template>
    <template #content>
      <h3>{{ $t('pagination.conflicts') }}</h3>

      <div
        v-for="attribute in attributesExceededRestrictions"
        :key="attribute.id"
        class="mt-4"
      >
        <div class="d-flex align-items-center justify-content-between">
          <div class="mb-2 d-flex align-items-center">
            <h4 class="restrictive-attribute-title">
              <LanguageSelector :to-be-selected="attribute.title" />
            </h4>
            <font-awesome-icon
              v-if="attribute.restrictive_icon"
              :icon="attribute.restrictive_icon"
              class="ms-2 more-detail-inline-attribute-icon"
            />
          </div>
          <div>
            <span
              class="restrictive-attribute-value"
              :style="{ color: getRestrictionIconColor(attribute) }"
            >
              <AttributeValueConverter
                :current-attribute-data="attribute"
                :raw-value="attribute.current_calculated_value"
                :hide-icons="true"
                :calculative-dictionary="true"
              />
            </span>
          </div>
        </div>
        <div
          class="p-0 flexible-range-warning-wrapper d-flex align-items-center flex-nowrap"
        >
          <font-awesome-icon
            :style="{ color: getRestrictionIconColor(attribute) }"
            class="flexible-range-warning-logo"
            icon="exclamation-circle"
          />
          <span class="ms-2 me-2 flexible-range-warning-message">
            <LanguageSelector
              :to-be-selected="getWarningMessageForAttribute(attribute)"
              :style="{ color: getRestrictionTextColor(attribute) }"
            />
          </span>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-start align-items-center">
        <button
          class="me-4 navigation-button navigation-button-back"
          @click="closeModal"
        >
          <font-awesome-icon
            class="navigation-arrows navigation-arrow-back me-1"
            icon="arrow-left"
          />
          <span class="navigation-labels navigation-label-back">
            {{ $t('pagination.back') }}
          </span>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseModal from './BaseModal.vue';
import { mapState, mapWritableState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';
import { restrictiveColorScheme } from '../consultation/Properties/restrictiveColorScheme';

export default defineComponent({
  components: {
    BaseModal,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapWritableState(useConsultationStore, [
      'showExceededRestrictionsModal'
    ]),

    ...mapState(useConsultationStore, [
      'locale',
      'stageProperties',
    ]),

    attributesExceededRestrictions() {
      return this.stageProperties.filter(
        ({ current_calculated_value_exceeds_restriction: exceeds }) => {
          return exceeds === 'max' || exceeds === 'min';
        }
      );
    },
  },
  methods: {
    closeModal() {
      this.showExceededRestrictionsModal = false;
    },

    getRestrictionIconColor(attribute) {
      const isNeutral = attribute.neutral_speedometer_colors;
      const scheme = restrictiveColorScheme(isNeutral);
      return scheme.restrictive;
    },

    getRestrictionTextColor(attribute) {
      const isNeutral = attribute.neutral_speedometer_colors;
      const scheme = restrictiveColorScheme(isNeutral);
      const color = isNeutral ? '#111111' : scheme.restrictive;
      return color;
    },

    getWarningMessageForAttribute(attribute) {
      const exceeds = attribute.current_calculated_value_exceeds_restriction;

      if (exceeds === 'min') {
        // TODO: clean up this nested if mess..
        if (typeof attribute.minimum_value_message_popup === 'string') {
          return attribute.minimum_value_message_popup;
        }

        const minMessage =
          attribute.minimum_value_message_popup &&
          attribute.minimum_value_message_popup[this.locale]
            ? attribute.minimum_value_message_popup[this.locale]
            : false;
        const minLabel = this.$t('pagination.choice_task_under_restrictive_warning_message');
        return minMessage || minLabel;
      } else if (exceeds === 'max') {
        // TODO: clean up this nested if mess..
        if (typeof attribute.maximum_value_message_popup === 'string') {
          return attribute.maximum_value_message_popup;
        }

        const maxMessage =
          attribute.maximum_value_message_popup &&
          attribute.maximum_value_message_popup[this.locale]
            ? attribute.maximum_value_message_popup[this.locale]
            : false;
        const maxLabel = this.$t('pagination.choice_task_over_restrictive_warning_message');
        return maxMessage || maxLabel;
      }

      return '';
    },
  },
});
</script>

<style scoped>
.restrictive-attribute-title {
  margin: 0px;
}

.restrictive-attribute-value {
  font-size: 16px;
  font-weight: 700;
}

.dialog-subtitle {
  font-weight: normal;
}
</style>
