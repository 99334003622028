<template>
  <div
    v-if="!vertical"
    class="container m-0 px-0 py-2"
  >
    <div class="row row m-0 p-0 w-100">
      <div
        class="dashboard-drawer-label-text col m-0 p-0 d-flex justify-content-center align-items-center"
      >
        <span>{{ dashboardDrawerLabel }}</span>
      </div>
      <div class="col m-0 p-0">
        <div class="row p-0 m-0">
          <div
            class="dashboard-drawer-score col p-0 m-0 d-flex flex-nowrap justify-content-center align-items-center"
          >
            <span> {{ totalSpendPoints }} / {{ targetValue }} </span>
          </div>
          <div class="col p-0 m-0 w-100 h-100">
            <div class="row p-0 m-0 h-100 d-flex justify-content-center">
              <div class="points-progress-visualisation-wrapper position-relative">
                <PointsProgressVisualisation
                  :circle-radius="65"
                  :circle-color="circleColor"
                  :circle-progress="pointsProgress"
                  :stroke-density="20"
                  :circle-width="15"
                  :path-width="35"
                  class="points-progress-visualisation"
                />
                <font-awesome-layers
                  class="pointsTargetReachedValidation"
                  :style="{ opacity: showTargetReachedValidation }"
                >
                  <font-awesome-icon
                    style="color: white"
                    icon="circle"
                  />
                  <font-awesome-icon icon="check-circle" />
                </font-awesome-layers>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="container py-0 px-2 m-0 w-auto"
  >
    <div
      class="col h-100 d-flex flex-column align-items-center justify-content-center"
    >
      <div class="row mt-auto mb-4 justify-content-center align-items-center">
        <div
          class="points-progress-visualisation-wrapper rotate-content position-relative"
        >
          <PointsProgressVisualisation
            :circle-radius="65"
            :circle-color="circleColor"
            :circle-progress="pointsProgress"
            :stroke-density="20"
            :circle-width="15"
            :path-width="35"
            class="points-progress-visualisation"
          />
          <font-awesome-layers
            class="pointsTargetReachedValidation"
            :style="{ opacity: showTargetReachedValidation }"
          >
            <font-awesome-icon
              style="color: white"
              icon="circle"
            />
            <font-awesome-icon icon="check-circle" />
          </font-awesome-layers>
        </div>
      </div>
      <div class="row mb-4 justify-content-center">
        <div
          class="dashboard-drawer-score drawer-title-vertical col p-0 m-0 d-flex flex-nowrap justify-content-start align-items-bottom"
        >
          <span> {{ totalSpendPoints }} / {{ targetValue }} </span>
        </div>
      </div>
      <div class="row mb-auto justify-content-center">
        <div
          class="dashboard-drawer-label-text drawer-title-vertical col m-0 p-0 d-flex justify-content-start align-items-bottom"
        >
          <span>{{ dashboardDrawerLabel }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PointsProgressVisualisation from './PointsProgressVisualisation.vue';
import { mapState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    PointsProgressVisualisation,
  },
  props: {
    vertical: {
      type: Boolean,
      required: false,
      default: false,
    },
    dashboardDrawerLabel: {
      type: String,
      required: true,
      default: '',
    },
  },
  computed: {
    ...mapState(useConsultationStore, [
      'stage',
      'stageId',
      'stagePointsData',
      'getAnswerData',
      'totalSpendPoints',
    ]),

    targetReached() {
      return this.targetValue === this.totalSpendPoints;
    },

    pointsProgress() {
      const percentage = this.totalSpendPoints / this.targetValue;

      return percentage <= 1 ? percentage : 1;
    },

    targetValue() {
      return this.stagePointsData.points_target;
    },

    circleColor() {
      return 'grey';
    },

    showTargetReachedValidation() {
      return this.targetReached ? '1' : '0';
    },
  },
});
</script>

<style scoped>
.rotate-content {
  transform: rotate(-90deg);
}

.points-progress-visualisation-wrapper {
  width: 60px;
}

.pointsTargetReachedValidation {
  position: absolute;
  top: 0px;
  right: 14px;
  transition: opacity 0.5s;
  font-size: 0.95rem;
  color: var(--bs-success);
}

.dashboard-drawer-label-text {
  font-weight: 700;
  color: var(--text-color-heading);
}

.dashboard-drawer-score {
  font-weight: 400;
  color: var(--text-color-heading);
}

.drawer-title-vertical {
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
}
</style>
