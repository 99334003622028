<template>
  <div class="min-vh-100 d-flex flex-column">
    <header>
      <nav class="navbar">
        <div class="container-fluid">
          <div class="navbar-brand">
            <img
              src="@/assets/img/logo-desktop.svg"
              class=""
              alt="Wevaluate Logo"
              aria-label="Wevaluate"
            >
          </div>
        </div>
      </nav>
    </header>

    <div class="flex-grow-1">
      <div class="page-container">
        <div class="card">
          <h2 class="mt-5">
            {{ title }}
          </h2>
          <div class="page-body">
            <div>
              <h4 class="mt-5">
                Error
              </h4>
              <div>
                Error. The page could not be loaded. Please try to load the page
                again. If the problem still persists, your browser version might
                be unsuitable for this consultation. Please use the latest
                versions of the following browsers:
                <span style="font-weight: 700">{{ supportedBrowsers }}</span>
              </div>

              <h4 class="mt-5">
                Fout
              </h4>
              <div>
                Fout. De pagina kon niet geladen worden. Probeer de pagina
                opnieuw te laden. Als het probleem nog steeds voorkomt dan kan het
                zijn dat u een browser gebruikt die niet geschikt is voor het
                gebruik voor deze raadpleging. Gebruik aub de laatste versies van
                één van de volgende browsers:
                <span style="font-weight: 700">{{ supportedBrowsers }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="text-white container-fluid text-center">
      {{ footerText }}
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    title () {
      return 'Something went wrong (de raadpleging kan niet worden getoond)';
    },

    supportedBrowsers() {
      return 'Chrome, MS Edge, Firefox, Safari and Opera.';
    },

    footerText() {
      const currentYear = new Date().getFullYear();
      return 'Wevaluate - ' + currentYear;
    },
  },
});
</script>

<style scoped>
header {
	height: 56px;
	box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
	background-color: var(--primary-custom-header);
}
.navbar {
  padding: 0.75rem 0;
}
.navbar-brand img{
  height: 32px;
  width: auto;
}
footer {
  font-size: 0.8rem;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: -6px -8px 11px rgba(0,0,0,.16);
	background-color: var(--primary-custom-footer);
}
</style>
