<template>
  <div class="d-flex flex-column min-vh-100">
    <AppHeader />
    <main
      :class="{
        'main-landscape': landscapeMode && inChoiceTask,
      }"
      class="flex-grow-1 main-container"
    >
      <transition
        :name="navigate"
        :duration="{ enter: 500, leave: 250 }"
        mode="out-in"
      >
        <component
          :is="resolveComponent"
          :key="stageId"
        />
      </transition>
      <div
        v-if="offline"
        class="backdrop"
      >
        <BaseSpinner />
      </div>
      <BaseInfo />
      <BaseWarning />
      <BaseConfirm :reset-open-state="resetBrowserBackOpen" />
    </main>
    <AppFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { WARN_BEFORE_UNLOAD } from '../settings';
import SegmentDiscreteChoice from './SegmentDiscreteChoice.vue';
import SegmentChoiceTask from './SegmentChoiceTask.vue';
import SegmentChoiceTaskMotivateAll from './SegmentChoiceTaskMotivateAll.vue';
import SegmentChoiceTaskSelection from './SegmentChoiceTaskSelection.vue';
import SegmentChoiceTaskUnchosen from './SegmentChoiceTaskUnchosen.vue';
import ConsentSegment from './ConsentSegment.vue';
import SegmentLanguageSelection from './SegmentLanguageSelection.vue';
import SegmentOutro from './SegmentOutro.vue';
import SegmentOutroConsentDeclined from './SegmentOutroConsentDeclined.vue';
import PageSegment from './PageSegment.vue';
import SegmentSurvey from './SegmentSurvey.vue';
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';
import BaseSpinner from './BaseSpinner.vue';
import BaseConfirm from './BaseConfirm.vue';
import BaseInfo from './BaseInfo.vue';
import BaseWarning from './BaseWarning.vue';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { mapState } from 'pinia';
import { useAnalyticsStore } from '../stores/useAnalyticsStore';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    SegmentDiscreteChoice,
    SegmentChoiceTask,
    SegmentChoiceTaskMotivateAll,
    SegmentChoiceTaskSelection,
    SegmentChoiceTaskUnchosen,
    ConsentSegment,
    SegmentLanguageSelection,
    SegmentOutro,
    SegmentOutroConsentDeclined,
    PageSegment,
    SegmentSurvey,
    AppHeader,
    AppFooter,
    BaseSpinner,
    BaseInfo,
    BaseConfirm,
    BaseWarning,
  },

  setup() {
    const interfaceStore = useInterfaceStore();
    const analyticsStore = useAnalyticsStore();
    const consultationStore = useConsultationStore();
    return { interfaceStore, analyticsStore, consultationStore };
  },

  data() {
    return {
      offline: false,
      isMobile: false,
      isMediumScreen: false,
      adviceDesktopWarningShown: false,
      allowExit: false,
      confirmOpen: 0,
      component: undefined,
    };
  },

  computed: {
    ...mapState(useInterfaceStore, [
      'getMobileView',
      'isLandscapeMode'
    ]),

    ...mapState(useConsultationStore, [
      'stage',
      'stageId',
      'navigate',
      'completed',
      'consentGiven',
      'isInstanceActive',
      'getInstanceId',
      'locale',
      'getPanelMode',
      'browserBackConfirm',
      'getBlockId',
      'getSequenceNumber',
      'getInstanceSlug',
      'showConfirm',
      'inChoiceTask',
    ]),

    resolveComponent() {
      if (this.consentGiven === false && this.completed === true) {
        return SegmentOutroConsentDeclined;
      }

      return this.stage.component;
    },

    landscapeMode() {
      return this.isLandscapeMode && this.inChoiceTask;
    },
  },

  watch: {
    isLandscapeMode(bool) {
      if (bool) {
        this.analyticsStore.trackUserAction([
          'Participant changed screen: landscapeMode',
          this.getBlockId,
          this.getSequenceNumber,
        ]);
      } else {
        this.analyticsStore.trackUserAction([
          'Participant changed screen: portraitMode',
          this.getBlockId,
          this.getSequenceNumber,
        ]);
      }
    },

    getSequenceNumber(number) {
      this.scrollToTopFunction(number);

      this.analyticsStore.trackPageView({
        slug: this.getInstanceSlug,
        segmentId: this.stage.blockId,
        segmentType: this.stage.blockType,
        stageId: this.stageId,
        sequenceNumber: this.getSequenceNumber,
      });
    },
  },

  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.matomoPageViewDimensions();
  },

  mounted() {
    //	add event listener when user presses browser back button
    if (window.history && window.history.pushState) {
      window.addEventListener('popstate', () => {
        this.allowExit = true;
        this.confirmOpen += 1;

        if (this.confirmOpen === 1) {
          this.canUserLeave();
        }
      });
    }

    window.onbeforeunload = function (e) {
      if (WARN_BEFORE_UNLOAD() === false || this.allowExit) {
        return;
      }

      if (!this.completed) {
        this.canUserRefresh(e);
      }
    }.bind(this);

    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },

  beforeUnmount() {
    //	TODO declare the function name for popstate so it can properly be removed here
    // window.removeEventListener('popstate');
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },

  methods: {
    updateOnlineStatus(e) {
      const { type } = e;
      this.offline = type === 'offline';
    },

    canUserLeave() {
      this.analyticsStore.trackUserAction([
        'Browser back pressed',
        this.getBlockId,
        this.getSequenceNumber,
      ]);

      // checks if the user needs to be warned for leaving the application
      if (this.browserBackConfirm) {
        this.consultationStore.setUserLeavesConfirmData();
        this.showConfirm = true;
      } else {
        window.history.back();
      }
    },

    canUserRefresh(e) {
      this.analyticsStore.trackUserAction([
        'Browser unload',
        this.getBlockId,
        this.getSequenceNumber,
      ]);

      e.preventDefault();
      e.returnValue = '';
    },

    resetBrowserBackOpen() {
      this.confirmOpen = 0;
    },

    async handleResize() {
      this.isMobile = window.matchMedia(
        'only screen and (max-width: 999px)'
      ).matches;

      this.interfaceStore.setMobileView(this.isMobile);

      const isLandscapeDimensions = window.innerWidth > window.innerHeight;

      this.interfaceStore.setLandscapeDimensions(isLandscapeDimensions);
    },

    matomoPageViewDimensions() {
      //  matomo custom dimensions
      this.analyticsStore.trackDimension([1, this.getInstanceId]);
      this.analyticsStore.trackDimension([2, this.getInstanceSlug]);

      const typeOfUser = this.getPanelMode ? 'panel' : 'open';

      this.analyticsStore.trackDimension([3, typeOfUser]);
      this.analyticsStore.trackDimension([4, this.isInstanceActive]);

      this.analyticsStore.trackSystemAction(['Load initial page'])
    },

    async scrollToTopFunction(number) {
      const headerHeight = 60;
      let scrollToHeader = true;

      if (this.interfaceStore.getMobileView && window.scrollY >= headerHeight) {
        scrollToHeader = false;
      }

      if (!scrollToHeader && number !== 0) {
        window.scrollTo({
          top: headerHeight,
          left: 0,
          behavior: 'smooth',
        });
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    },
  },
});
</script>

<style scoped>
.main-container {
  width: 100%;
}

.main-landscape.main-container {
  width: calc(100% - 44px);
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: all 0.4s ease;
}

.component-fade-enter {
  transform: translateX(1000px);
  opacity: 0;
}

.component-fade-leave-to {
  transform: translateX(-1000px);
  opacity: 0;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 100;
  transform: translate3d(0, 0, 50px);
  -webkit-transform: translate3d(0, 0, 50px);
}
</style>
