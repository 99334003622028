<template>
  <div
    class="research-project-list-wrapper d-flex flex-column justify-content-center"
  >
    <component
      :is="component"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ChoiceTaskPickMode from './ChoiceTaskPickMode.vue';
import ChoiceTaskPointsMode from './ChoiceTaskPointsMode.vue';
import ChoiceTaskSliderMode from './ChoiceTaskSliderMode.vue';
import { mapState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    ChoiceTaskPickMode,
    ChoiceTaskPointsMode,
    ChoiceTaskSliderMode,
  },

  computed: {
    ...mapState(useConsultationStore, [
      'isPointsMode',
      'isPickMode',
      'isSliderMode',
    ]),

    component() {
      if (this.isPickMode) {
        return ChoiceTaskPickMode;
      } else if (this.isPointsMode) {
        return ChoiceTaskPointsMode;
      } else if (this.isSliderMode) {
        return ChoiceTaskSliderMode;
      }

      console.error('Unknown selection mode');
      return null
    },
  },
});
</script>
