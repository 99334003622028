import { AnswerData, Answers } from "./AnswerData";

export interface CompletionAnswers extends Answers {
  [key: string]: {
    blockId: number;
    uuid: string | null;
    answers: {};
  };
}

export class CompletionAnswerData extends AnswerData {
  /**
   * The slug for the complete form.
   */
  protected endpoint = 'complete';

  public formatData(answers: CompletionAnswers) {
    const complete = Object.values(answers)[0];

    return {
      blockId: complete.blockId,
      answerData: {},
    }
  }
}
