<template>
  <div
    v-if="show"
    class="backdrop"
    @click="$emit('my-event')"
  />
  <transition name="dialog">
    <dialog
      v-if="show"
      ref="modal"
      open
      class="p-3 mx-auto"
      role="dialog"
      aria-modal="true"
      @keydown="trapFocus"
    >
      <div class="dialog-closing-button-container d-flex justify-content-end">
        <font-awesome-layers
          class="dialog-closing-button-wrapper"
          @click="$emit('my-event')"
        >
          <font-awesome-icon
            icon="square"
            class="closing-square-icon"
          />
          <font-awesome-icon
            icon="times"
            class="closing-cross-icon"
          />
        </font-awesome-layers>
      </div>
      <div
        v-if="icons"
        class="icons"
      >
        <slot name="icons" />
      </div>
      <header v-if="title">
        <slot name="header">
          <h2 class="dialog-title pb-2">
            <LanguageSelector :to-be-selected="title" />
          </h2>
        </slot>
      </header>
      <section class="pt-2">
        <div class="dialog-content">
          <slot />
        </div>
      </section>
      <menu
        v-if="singleButton"
        class="pt-4"
      >
        <BaseButton @my-event="tryClose">
          {{ $t('pagination.close') }}
        </BaseButton>
      </menu>
      <menu
        v-if="!singleButton"
        class="pt-4 container-fluid row flex-nowrap justify-content-end pe-0 me-0 ms-0"
      >
        <button
          class="navigation-button navigation-button-back"
          @click="tryClose"
        >
          <font-awesome-icon
            class="navigation-arrows navigation-arrow-back"
            icon="arrow-left"
          />
          <span class="navigation-labels navigation-label-back ms-2">
            {{ $t('pagination.return') }}
          </span>
        </button>
        <button
          class="navigation-button navigation-button-next d-flex justify-content-center ms-3"
          style="width: auto; min-width: 120px"
          @click="confirmSubmit"
        >
          <span
            class="navigation-labels navigation-label-next d-flex"
            style="white-space: nowrap"
          >
            {{ confirmSubmitButtonLabel }}
          </span>
        </button>
      </menu>
    </dialog>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseButton from './BaseButton.vue';

export default defineComponent({
  components: {
    BaseButton,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    icons: {
      type: Boolean,
    },
    title: {
      type: [String, Boolean, Object],
      required: false,
      default: false,
    },
    typeOfConfirm: {
      type: String,
      required: false,
      default: undefined,
    },
    singleButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    'close',
    'confirm',
    'my-event',
    'confirm-event'
  ],

  computed: {
    confirmSubmitButtonLabel() {
      if (this.typeOfConfirm) {
        if (
          this.typeOfConfirm === 'confirmDeclineConsent' ||
          this.typeOfConfirm === 'confirmUserLeaves'
        ) {
          return this.$t('pagination.confirm_stop_consultation');
        }
      }

      return this.$t('pagination.next');
    },
  },

  watch: {
    show(show) {
      if (show) {
        this.$nextTick(() => {
          this.$refs.modal.focus();
        });
      }
    },
  },

  mounted() {
    document.addEventListener('keydown', this.trapFocus);
  },

  beforeUnmount() {
    document.removeEventListener('keydown', this.trapFocus);
  },

  methods: {
    tryClose() {
      this.$emit('my-event');
    },

    confirmSubmit() {
      this.$emit('confirm-event');
    },

    trapFocus(event) {
      if (event.key === 'Escape' && this.show) {
        this.$emit('my-event');
      }

      if (!this.$refs.modal || event.key !== 'Tab') {
        return;
      }

      const focusableElements = this.$refs.modal.querySelectorAll(
        'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
      );

      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      if (event.shiftKey && document.activeElement === firstElement) {
        event.preventDefault();
        lastElement.focus();
      } else if (!event.shiftKey && document.activeElement === lastElement) {
        event.preventDefault();
        firstElement.focus();
      }
    },
  },
});
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 50;
  transform: translate3d(0, 0, 50px);
  -webkit-transform: translate3d(0, 0, 50px);
}

dialog {
  position: fixed;
  z-index: 100;
  transform: translate3d(0, 0, 100px);
  -webkit-transform: translate3d(0, 0, 100px);
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: auto;
  background-color: white;
  top: calc(50% - 35vh);
  left: 0;
  width: 100vw;
  max-height: 80vh;
  max-width: 999px;
  overflow-x: hidden;
}

@media (min-width: 1000px) {
  dialog {
    width: 608px;
  }
}

.dialog-title {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: var(--text-color-heading);
}

.dialog-content {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: var(--text-color-content);
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3s ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}
</style>
