<script setup lang="ts">
import { computed } from 'vue';
import LanguageSelector from './LanguageSelector.vue';

const props = defineProps<{
  questionTitle: Record<string, any>;
  isRequired: boolean;
  currentNumber?: number;
  totalNumber?: number;
}>();

const showCurrentNumberOfQuestion = computed(() => {
  return props.currentNumber !== undefined && props.totalNumber !== undefined;
});
</script>

<template>
  <legend class="question-title d-flex flex-row align-items-center flex-nowrap">
    <LanguageSelector :to-be-selected="questionTitle" />
    <span v-if="isRequired">*</span>
    <span
      v-if="showCurrentNumberOfQuestion"
      class="question-number p-0 ms-1 d-flex stay-ltr"
    >
      <span>(</span>
      <span>{{ currentNumber }}</span>
      <span>/</span>
      <span>{{ totalNumber }}</span>
      <span>)</span>
    </span>
  </legend>
</template>
