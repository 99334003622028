<template>
  <div class="research-mobile-dashboard-container">
    <div class="research-mobile-dashboard position-relative">
      <div
        v-if="showToggleButton"
        class="research-dashboard-button-wrapper position-absolute w-100"
      >
        <button
          class="research-dashboard-button"
          @click="toggleExpanded"
        >
          <font-awesome-icon
            :icon="expanded || tease ? 'chevron-down' : 'chevron-up'"
          />
        </button>
      </div>
      <DrawerContentPointsMode
        v-if="isPointsMode"
        :dashboard-drawer-label="dashboardDrawerLabel"
      />
      <div
        v-else
        :style="{
          color: drawerTitleColor,
        }"
        class="research-dashboard-drawer py-1 d-flex align-items-center"
        :class="justifyContentClass"
      >
        <LanguageSelector
          :to-be-selected="dashboardDrawerLabel"
          style="height: 32px;"
        />
        <span
          v-show="spinner"
          style="width: 32px; height: 32px;"
          class="ms-2"
        >
          <font-awesome-icon
            icon="sync"
            class="fa-spin"
            style="color: #004876; margin-top:6px;"
          />
        </span>
        <span
          v-show="showRestrictionPreview && !spinner"
          class="ms-2"
        >
          <font-awesome-layers
            style="width: 32px; height: 32px;"
          >
            <img
              src="@/assets/img/icon-speedometer.svg"
              style="width: 32px; height: 32px;"
            >
            <font-awesome-icon
              v-if="restrictionPreviewIcon"
              :icon="restrictionPreviewIcon"
              style="margin-left:18px; margin-bottom: 14px; background-color: white;"
              :style="{
                color: restrictionPreviewIconColor,
              }"
            />
          </font-awesome-layers>
        </span>
      </div>
      <div
        :class="{ expanded: expanded, tease: tease }"
        class="dashboard-attributes-container d-flex justify-content-center px-2"
      >
        <ResearchDashboard />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ResearchDashboard from './ResearchDashboard.vue';
import DrawerContentPointsMode from './DrawerContentPointsMode.vue';
import { useConsultationStore } from '../stores/useConsultationStore';
import { mapState } from 'pinia';

export default defineComponent({
  components: {
    ResearchDashboard,
    DrawerContentPointsMode,
  },
  props: {
    expanded: {
      type: Boolean,
      required: true,
    },
    tease: {
      type: Boolean,
      required: true,
    },
    drawerTitleColor: {
      type: String,
      required: true,
      default: '',
    },
    dashboardDrawerLabel: {
      type: String,
      required: true,
      default: '',
    },
    showRestrictionPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
    restrictionPreviewIcon: {
      type: String,
      required: false,
      default: undefined,
    },
    restrictionPreviewIconColor: {
      type: String,
      required: true,
    },
    spinner: {
      type: Boolean,
      required: true,
    },
    justifyContentClass: {
      type: String,
      required: false,
      default: '',
    },
    showToggleButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['toggleExpanded'],
  computed: {
    ...mapState(useConsultationStore, [
      'isPointsMode',
    ]),
  },
  methods: {
    toggleExpanded() {
      this.$emit('toggleExpanded', null);
    },
  },
});
</script>

<style scoped>
.research-mobile-dashboard-container {
  position: fixed;
  bottom: 56px;
  width: 100%;
  background-color: #ffffff;
  z-index: 10;
  box-shadow: 0px -3px 6px rgb(0 0 0 / 16%);
}

.research-mobile-dashboard {
  text-align: center;
}

.research-dashboard-button-wrapper {
  top: -14px;
}

.research-dashboard-button {
  background-color: #ffffff;
  box-shadow: 0px -3px 6px rgb(0 0 0 / 16%);
  outline: none;
  border: none;
  padding: 0px 18px;
  border-radius: 12px;
  color: var(--bs-info);
}

.research-dashboard-drawer {
  text-align: center;
  font-weight: bold;
  bottom: -100%;
  transition: color 1s ease-out;
}

.dashboard-attributes-container {
  height: 0vh;
  transition: height 1.1s ease-in-out;
}

.dashboard-attributes-container.tease {
  bottom: 56px;
  height: 20vh;
}

.dashboard-attributes-container.expanded {
  bottom: 56px;
  height: 70vh;
}
</style>
