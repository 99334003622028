import { AnswerData, Answers } from './AnswerData';

interface ConsentAnswers extends Answers {
  [key: string]: {
    blockId: number;
    uuid: string | null;
    answers: {
      answer_id: 'accept' | 'decline';
      field_type: 'radio';
      id: 'consent';
      question_number: 0;
      raw_input_radio_table: 'accept' | 'decline';
      required: true;
      user_answered_string: string;
      user_has_answered: true;
    };
  }
}

export class ConsentAnswerData extends AnswerData {
  /**
   * The slug for the consent form.
   */
  protected endpoint = 'consent';

  public formatData(answers: ConsentAnswers) {
    const consent = Object.values(answers)[0];

    return {
      blockId: consent.blockId,
      answerData: {
        consentGiven: consent.answers.answer_id === 'accept'
      }
    };
  }
}
