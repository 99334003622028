import { AnswerData, Answers } from "./AnswerData";

export interface SurveyAnswers extends Answers {
  [key: string]: {
    blockId: number;
    uuid: string | null;
    answers: {
      [key: string]: any
    };
  };
}

export class SurveyAnswerData extends AnswerData {
  /**
   * The slug for the survey form.
   */
  protected endpoint = 'survey';

  public formatData(answers: SurveyAnswers) {
    const surveyAnswers = []

    for (const [key, answerData] of Object.entries(answers)) {
      surveyAnswers.push(...Object.values(answerData.answers));
    }

    const formatted = [];

    for (const element of surveyAnswers) {
      const cloned = { ...element };

      delete cloned.answer_options;
      delete cloned.description;
      delete cloned.question_number;
      delete cloned.matrix_theses;
      delete cloned.raw_input_radio_table;
      delete cloned.randomize_answer_order;
      delete cloned.conditions;
      delete cloned.raw_input_dropdown;
      delete cloned.raw_input_text;
      delete cloned.raw_input_double_response;
      delete cloned.max_characters_allowed;
      delete cloned.fancyRadio;
      delete cloned.open_answer;
      delete cloned.raw_input_check_box_open_answer;
      delete cloned.raw_input_matrix_table;
      delete cloned.raw_input_check_box_table;
      delete cloned.raw_ordered_answers;
      delete cloned.raw_input_radio_ab;
      delete cloned.raw_input_slider;
      delete cloned.user_answered_array_temp;
      delete cloned.user_has_answered_array;

      formatted.push(cloned)
    }

    return {
      blockId: this.blockId,
      answerData: Object.assign({}, formatted)
    };
  }
}
