<template>
  <div class="form-group">
    <div
      v-if="isSliderMode && currentQuestionData && optionId !== -1"
      class="project-header-wrapper container row"
    >
      <ChoiceTaskSliderModeCard
        class="ps-3 pe-3"
        :option="currentQuestionData"
        :static-mode="true"
      />
    </div>
    <div
      v-else
      class="project-header-wrapper container row flex-nowrap"
    >
      <div
        v-if="!lastQuestion"
        :style="textColorReturn"
        class="project-wrapper col-12 py-2 px-2"
      >
        <div class="d-flex flex-nowrap align-items-center h-100">
          <OptionInfoButton
            :option="currentQuestionData"
            class="me-3 ms-2"
          />
          <div class="project-title-amount-of-points-wrapper">
            <LanguageSelector
              :to-be-selected="questionTitle"
              class="project-title"
            />
            <h3
              v-show="isPointsMode"
              style="font-size: 15px"
              class="my-0"
            >
              {{ amountOfPointsSpend }}&nbsp;<LanguageSelector
                style="text-transform: lowercase"
                :to-be-selected="valuePostfix"
              />
            </h3>
          </div>
        </div>
      </div>
      <div
        v-else-if="lastQuestion"
        class="last-question col"
      >
        <h3 class="m-0">
          <LanguageSelector
            :to-be-selected="questionTitle"
            class="question"
          />
        </h3>
      </div>
    </div>
    <div class="answer">
      <label>
        <textarea
          v-model="answerData.user_motivation"
          :name="questionTitle"
          :maxLength="limitOfCharacters"
          autocomplete="off"
          rows="6"
          class="form-control input-text-area"
          @blur="userHasChosenAnswer(answerData.user_motivation)"
          @keyup="checkCharacterLength(answerData.user_motivation)"
        />
      </label>
    </div>
    <div
      :style="textOpacityA"
      class="alert-required-message"
    >
      <LanguageSelector
        :to-be-selected="$t('pagination.input_validation_max_amount_characters')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ChoiceTaskSliderModeCard from './ChoiceTaskSliderModeCard.vue';
import OptionInfoButton from './OptionInfoButton.vue';
import { mapState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    ChoiceTaskSliderModeCard,
    OptionInfoButton,
  },

  props: {
    lastQuestion: Boolean,
    currentQuestionData: {
      type: Object,
      required: true,
    },
    currentUserData: {
      type: Object,
      default: undefined,
    },
  },

  emits: ['sendCurrentUserData'],

  data() {
    return {
      answerData: {},
      textColor: 'black',
      maxLimitReached: false,
    };
  },

  computed: {
    ...mapState(useConsultationStore, [
      'locale',
      'stage',
      'isSliderMode',
      'isPointsMode',
      'isSymmetricalSlider',
      'stageProperties',
    ]),

    typeOfSlider() {
      if (this.isSliderMode) {
        return this.isSymmetricalSlider ? 'list-slider-symmetrical' : 'list-slider';
      }

      return false;
    },

    questionTitle() {
      return this.currentQuestionData.title;
    },

    optionId() {
      return this.currentQuestionData.id;
    },

    amountOfPointsSpend() {
      if (this.isSliderMode) {
        return Math.round(this.currentQuestionData.quantity * 100);
      }

      return this.currentQuestionData.quantity || 0;
    },

    valuePostfix() {
      const pointsData = this.stage.fields.pointsData || {};

      if (pointsData.short_title && pointsData.short_title[this.locale] !== '') {
        return pointsData.short_title[this.locale];
      }

      return 'x';
    },

    textColorReturn() {
      if (this.answerData.userHasAnswered === true) {
        return { color: 'black' };
      }

      return { color: 'red' };
    },

    textOpacityA() {
      if (this.maxLimitReached) {
        return { opacity: 1 };
      } else {
        return { opacity: 0 };
      }
    },

    limitOfCharacters() {
      return 3500;
    },
  },

  created() {
    const answerData = this.currentUserData ? { ...this.currentUserData } : {};

    if (answerData.user_has_motivated_option) {
      answerData.user_motivation = answerData.raw_input_text;
    } else {
      answerData.user_has_motivated_option = false;
      answerData.user_motivation = null;
      answerData.raw_input_text = null;
    }

    answerData.last_question = this.lastQuestion;
    answerData.id = this.currentQuestionData.id;
    answerData.quantity = this.currentQuestionData.quantity;
    answerData.title = this.currentQuestionData.title;

    this.answerData = answerData;

    this.$emit('sendCurrentUserData', this.answerData);
  },

  methods: {
    userHasChosenAnswer(value) {
      let chosenAnswer = value || '';
      chosenAnswer = chosenAnswer.trim();

      if (chosenAnswer === '') {
        this.answerData.user_has_motivated_option = false;
        this.answerData.user_motivation = null;
        this.answerData.raw_input_text = null;
      } else {
        this.answerData.user_has_motivated_option = true;
        this.answerData.user_motivation = chosenAnswer;
        this.answerData.raw_input_text = value;
      }

      this.$emit('sendCurrentUserData', this.answerData);
    },

    checkCharacterLength(value) {
      if (value && value.length >= this.limitOfCharacters) {
        this.maxLimitReached = true;
      } else {
        this.maxLimitReached = false;
      }
    },
  },
});
</script>

<style scoped>
.container {
  margin: 0;
  padding: 0;
}

.row {
  margin: 0;
  padding: 0;
}

.col {
  margin: 0;
  padding: 0;
}

header {
  text-align: center;
}

.form-group {
  width: 100%;
}

.project-header-wrapper {
  height: 100%;
  min-height: 60px;
  width: 100%;
  margin: 1em 0;
}

.project-wrapper {
  background-color: white;
  box-sizing: border-box;
  border-radius: 20px;
  border-style: solid;
  border-color: #e9eff4;
  border-width: 1px;
  box-shadow: 0 1px 25px rgba(212, 212, 212, 0.17);
}

.main-attribute-wrapper {
  flex-basis: 40%;
  margin: auto;
  display: flex;
  flex-direction: row;
}

.main-attribute-symbol {
  margin-left: auto;
  margin-right: 0;
}

.main-attribute-value {
  margin: auto auto auto 0;
}

.info-button-motivation {
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
}

.amount-of-points-container {
  display: inline-block;
  margin: auto;
  height: 50px;
}

.vl {
  display: inline-block;
  float: left;
  background-color: #017cf7;
  height: 100%;
  width: 5px;
  margin: 0 0.5em;
}

.amount-of-points {
  color: #017cf7;
}

.last-question {
  margin: auto 0;
}

.last-question-title {
  margin: auto auto auto 15px;
}
</style>
