import i18next from 'i18next';

export default {
  getWriteDirection(languageCode) {
    return i18next.dir(languageCode);
  },

  isRtlScript(languageCode) {
    const writeDirection = this.getWriteDirection(languageCode);
    return writeDirection === 'rtl' ? true : false;
  },

  setLanguageAttribute(languageCode) {
    document.documentElement.setAttribute('lang', languageCode);
  },

  setWriteDirection(languageCode) {
    const writeDirection = this.getWriteDirection(languageCode);
    document.documentElement.setAttribute('dir', writeDirection);
  },
};
