<template>
  <div id="app">
    <GlobalLoading v-if="loading" />
    <GlobalFetchError v-else-if="asyncError && !loading" />
    <GlobalFaultyConsultation v-else-if="parseError" />
    <GlobalEmptyConsultation v-else-if="empty" />
    <RouterView v-else-if="initialized" />
  </div>
</template>

<script lang="ts">
import log from 'loglevel';
import { defineComponent } from 'vue';
import { useFetchInstanceStore } from './stores/useFetchInstanceStore';
import { getConsultationSlugFromRoute } from './consultation/Source/getConsultationSlugFromRoute';
import { InstanceAdapter } from './consultation/Source/Adapters/InstanceAdapter';
import GlobalEmptyConsultation from './views/GlobalEmptyConsultation.vue';
import GlobalFaultyConsultation from './views/GlobalFaultyConsultation.vue';
import GlobalFetchError from './views/GlobalFetchError.vue';
import GlobalLoading from './views/GlobalLoading.vue';
import { useAnalyticsStore } from './stores/useAnalyticsStore';
import { useConsultationStore } from './stores/useConsultationStore';

export default defineComponent({
  name: 'AppContainer',
  components: {
    GlobalEmptyConsultation,
    GlobalFaultyConsultation,
    GlobalFetchError,
    GlobalLoading,
  },

  setup() {
    const fetchInstanceStore = useFetchInstanceStore();
    const analyticsStore = useAnalyticsStore();
    const consultationStore = useConsultationStore();
    return { fetchInstanceStore, analyticsStore, consultationStore };
  },

  data() {
    return {
      parseError: false,
      initialized: false,
    }
  },

  computed: {
    loading() {
      return this.fetchInstanceStore.loading;
    },

    asyncError() {
      return this.fetchInstanceStore.hasError;
    },

    empty() {
      return !this.loading && this.fetchInstanceStore.isEmpty;
    },
  },

  async created() {
    const slug = getConsultationSlugFromRoute();
    const result = await this.fetchInstanceStore.fetchInstance(slug);

    if (this.fetchInstanceStore.hasError || result === undefined) {
      return;
    }

    try {
      const consultation = InstanceAdapter(result);
      await this.consultationStore.init(consultation);
      this.$i18n.locale = this.consultationStore.locale;
      this.initialized = true;
    } catch (error) {
      this.parseError = true;
      log.error(error);
    }

    if (this.parseError) {
      this.analyticsStore.trackSystemAction([`Failed retrieval of the JSON: ${slug}`]);
    } else {
      this.analyticsStore.trackSystemAction([`Retrieving JSON: ${slug}`]);
    }
  },
});
</script>

<style>
  * {
    --primary: #44a7d6;
    --primary-dark: #0d4875;

    --primary-custom-header: #44a7d6;
    --primary-custom-footer: #0d4875;

    --text-color-custom-header: #F4F6FC;
    --text-color-custom-footer: #F4F6FC;

    --text-color-heading: #004876;
    --text-color-content: #484848;

    --text-color-link: #004876;
    --text-color-link-visited: #421e95;
    --text-color-link-hover: #00a6d6;
    --text-color-link-focus: #00a6d6;
    --text-color-link-active: #0066a2;

    --bg-color-app: #F4F6FC;

    --border-color-light: #E6E6E6;
  }
</style>
