<template>
  <transition-group
    class="list-cards-wrapper d-flex flex-wrap justify-content-start"
    name="flip-list"
    tag="div"
  >
    <ChoiceTaskPickModeCard
      v-for="option in stageOptions"
      :key="option.id"
      :option="option"
    />
  </transition-group>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ChoiceTaskPickModeCard from './ChoiceTaskPickModeCard.vue';
import { mapState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    ChoiceTaskPickModeCard,
  },

  computed: {
    ...mapState(useConsultationStore, [
      'stageOptions',
    ]),
  },
});
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.7s;
}
</style>
