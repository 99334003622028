<template>
  <ChoiceTaskOption :style="mutedCardStyle">
    <template #top-section>
      <ChoiceTaskOptionTopSection :option="option" />
    </template>
    <template #bottom-section>
      <div class="col-6 m-0 p-0 d-flex">
        <ChoiceTaskOptionAttributes :option-id="option.id" />
      </div>
      <div
        class="add-button-wrapper col-6 p-0 m-0 d-flex justify-content-end align-items-end"
      >
        <ChoiceTaskPickModeButton
          :option="option"
          @mouseover="hover = true"
          @mouseleave="hover = false"
        />
      </div>
    </template>
    <template #overlay-section>
      <div
        :style="returnOpacity"
        class="row card-item-overlay h-100 w-100"
      >
        <div
          v-if="mutedData && mutedType === 'mutual-exclusive'"
          class="overlay-text p-3 position-relative"
        >
          <LanguageSelector
            :style="returnLabelColor"
            :to-be-selected="$t('pagination.choice_task_option_muted_by')"
          />&nbsp;<LanguageSelector
            :style="returnLabelColor"
            :to-be-selected="mutedBy"
            style="font-weight: 800"
          />
        </div>
        <div
          v-else-if="mutedData && mutedType === 'prerequisite-group'"
          class="overlay-text p-3 position-relative"
        >
          <LanguageSelector
            :style="returnLabelColor"
            :to-be-selected="$t('pagination.choice_task_option_prerequisites')"
          />&nbsp;
          <LanguageSelector
            :style="returnLabelColor"
            :to-be-selected="mutedByPrerequisiteMessage"
            style="font-weight: 800"
          />
        </div>
      </div>
    </template>
  </ChoiceTaskOption>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ChoiceTaskPickModeButton from './ChoiceTaskPickModeButton.vue';
import ChoiceTaskOption from './ChoiceTaskOption.vue';
import ChoiceTaskOptionTopSection from './ChoiceTaskOptionTopSection.vue';
import ChoiceTaskOptionAttributes from './ChoiceTaskOptionAttributes.vue';
import { mapState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    ChoiceTaskPickModeButton,
    ChoiceTaskOption,
    ChoiceTaskOptionTopSection,
    ChoiceTaskOptionAttributes,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      hover: false,
    };
  },

  computed: {
    ...mapState(useConsultationStore, [
      'stageId',
      'getAnswerData',
      'locale',
    ]),

    getMutedOptionsData() {
      const channel = this.option.interaction_channel;

      if (!channel) {
        return {};
      }

      const answerData = this.getAnswerData[this.stageId] ? this.getAnswerData[this.stageId].answers : {};

      const other = Object.values(answerData).find((option) => option.interaction_channel === channel && option.id !== this.option.id);

      return {
        muted: other ? other.quantity !== 0 : false,
        mutedBy: other ? other.title : null,
        mutedType: 'mutual-exclusive'
      };
    },

    selected() {
      const answerData = this.getAnswerData[this.stageId].answers;
      const option = answerData[this.option.id];

      if (!option) {
        return false;
      }

      return option.quantity === 1;
    },

    mutedData() {
      return this.getMutedOptionsData || {};
    },

    isMuted() {
      return this.mutedData.muted;
    },

    mutedBy() {
      return this.mutedData.mutedBy;
    },

    mutedType() {
      return this.mutedData.mutedType;
    },

    mutedCardStyle() {
      if (!this.isMuted) {
        return;
      }

      return { backgroundColor: '#efefef', opacity: 0.8 };
    },

    mutedByPrerequisiteMessage() {
      if (this.isMuted === false) {
        return;
      }

      const mutedBy = this.mutedBy;

      const groupSeparator = this.$t('pagination.or');

      const msg = mutedBy
        .map((group) => {
          return group.map((option) => option[this.locale]).join(', ');
        })
        .join(` ${groupSeparator} `);

      return msg;
    },

    showOverlay() {
      return Boolean(this.hover && this.isMuted);
    },

    returnOpacity() {
      if (this.showOverlay) {
        return { opacity: 0.9, borderColor: '#D40005' };
      }

      return { borderColor: 'grey', opacity: 0 };
    },

    returnLabelColor() {
      if (this.showOverlay) {
        return { opacity: 1, color: '#D40005' };
      }

      return { color: 'grey', opacity: 0 };
    },
  },
});
</script>

<style scoped>
.card-item-overlay {
  border-style: solid;
  border-color: grey;
  position: absolute;
  bottom: 0;
  left: 16px;
  pointer-events: none;
  opacity: 0.8;
  background: white;
  border-radius: 25px;
  border-style: solid;
  border-color: #e9eff4;
  border-width: 1px;
  transition: border-color 0.8s ease-in;
  transition: opacity 0.4s ease-out;
}

.overlay-text {
  position: absolute;
  bottom: 0;
  user-select: none;
  transition: opacity 1s ease-out;
}

img.project-image {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  object-fit: cover;
  user-select: none;
}
</style>
