import { Property } from "../../types/Property";
import { restrictiveColorScheme } from "./restrictiveColorScheme";

export function restrictiveSegmentColors(property: Property) {
  const colors = [];
  const scheme = restrictiveColorScheme(property.neutral_speedometer_colors);

  if (property.restrictive_min_value !== null) {
    colors.push(scheme.restrictive);
  }

  if (property.flexible_min_value !== null) {
    colors.push(scheme.flexible);
  }

  colors.push(scheme.allowed);

  if (property.flexible_max_value !== null) {
    colors.push(scheme.flexible);
  }

  if (property.restrictive_max_value !== null) {
    colors.push(scheme.restrictive);
  }

  return colors;
}
