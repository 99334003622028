<template>
  <div class="dashboard-muted-wrapper ps-0 pe-0 pt-5 text-start">
    <div class="mb-3">
      <span
        v-if="!getMobileView"
        class="dashboard-headers"
      >
        {{ $t('pagination.choice_task_restrictions') }}
      </span>
    </div>
    <div class="d-flex align-items-center">
      <p class="dashboard-muted-text">
        {{ $t('pagination.choice_task_skip_choice_task_dashboard_text') }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import { useInterfaceStore } from '../stores/useInterfaceStore';

export default defineComponent({
  computed: {
    ...mapState(useInterfaceStore, [
      'getMobileView',
    ]),
  },
});
</script>

<style scoped>
.dashboard-muted-text {
  font-weight: normal;
}
</style>
