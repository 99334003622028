<template>
  <div class="page-container">
    <div class="card page">
      <div class="page-title">
        <h1 v-show="title">
          <LanguageSelector :to-be-selected="title" />
        </h1>
      </div>
      <div class="page-content">
        <span v-show="content"><LanguageSelector :to-be-selected="content" /></span>
      </div>

      <div class="page-actions">
        <OutroPageActions />
      </div>

      <div
        v-if="isPanelParticipant || nonPanelInfo"
        class="page-content mt-4 pt-2"
        style="border-top-style: solid; border-width: 1px"
      >
        <div v-if="isPanelParticipant">
          <LanguageSelector
            v-if="panelMessage"
            :to-be-selected="panelMessage"
          />
          <span v-else>{{ $t('pagination.panel_thank_you_page_panel_info') }}</span>
        </div>
        <div v-else>
          <LanguageSelector
            v-if="nonPanelInfo"
            :to-be-selected="nonPanelInfo"
          />
        </div>
      </div>
      <div
        v-if="isPanelParticipant || nonPanelRedirectUrl"
        class="page-navigation"
      >
        <AppNavigation />
      </div>
    </div>
    <OutroShareConsultationModal />
  </div>
</template>

<script lang="ts">
import Cookies from 'js-cookie';
import { defineComponent } from 'vue';
import AppNavigation from './AppNavigation.vue';
import OutroShareConsultationModal from './OutroShareConsultationModal.vue';
import OutroPageActions from './OutroPageActions.vue';
import { useAnalyticsStore } from '../stores/useAnalyticsStore';
import { mapState, mapWritableState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    AppNavigation,
    OutroShareConsultationModal,
    OutroPageActions,
  },

  setup() {
    const analyticsStore = useAnalyticsStore();
    const consultationStore = useConsultationStore();

    return {
      analyticsStore,
      isInstanceActive: consultationStore.isInstanceActive,
      submitCompletionAnswerData: consultationStore.submitCompletionAnswerData
    };
  },

  computed: {
    ...mapWritableState(useConsultationStore, [
      'nextDisabled',
      'browserBackConfirm',
      'completed',
    ]),

    ...mapState(useConsultationStore, [
      'locale',
      'stage',
      'stageId',
      'stagesCount',
      'getPanelMode',
      'isPanelParticipant',
    ]),

    title() {
      return this.stage.fields.title;
    },

    content() {
      return this.stage.fields.content;
    },

    nonPanelInfo() {
      const message = this.stage.fields.nonPanelMessage;

      if (message && message[this.locale]) {
        return message;
      }

      return false;
    },

    panelMessage() {
      const message = this.stage.fields.panelMessage;

      if (message && message[this.locale]) {
        return message;
      }

      return false;
    },

    nonPanelRedirectUrl() {
      return this.stage.fields.nonPanelRedirectUrl;
    },

    isNextDisabled() {
      const fields = this.stage.fields
      return !Boolean(fields.panelRedirectUrl || fields.nonPanelRedirectUrl);
    },
  },

  async created() {
    this.completed = true;
    this.browserBackConfirm = false;
    this.nextDisabled = this.isNextDisabled;
  },

  mounted() {
    if (this.stagesCount > 1) {
      this.submitCompletionAnswerData(this.stageId);
      this.analyticsStore.trackUserAction(['Complete consultation']);
      this.setCookie();
    }
  },

  methods: {
    setCookie() {
      if (!this.isInstanceActive) {
        return false;
      }

      const slug = this.getInstanceSlug;
      Cookies.set(`wevaluate.${slug}`, true, { expires: 365 });
    }
  }
});
</script>

<style scoped>
.page-content {
  overflow: auto;
}
</style>
