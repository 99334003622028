import { isLocal } from '../..//utils/isLocal';
import { LOCAL_SLUG } from '../../settings';

function parseSubdomain() {
  const host = window.location.host;
  const parts = host.split('.');
  const domainLength = parts.length;

  if (domainLength === 3) {
    // To handle '{slug}.domain.com'
    return parts[0];
  } else if (domainLength > 3) {
    // To handle 'www.{slug}.domain.com'
    return parts.reverse()[2];
  }

  return ''
}

function processSlugMapping(slug) {
  const JONGE_SLUGS = [
    'onderzoeksfinanciering1',
    'onderzoeksfinanciering2',
    'onderzoeksfinanciering3',
  ];

  if (slug === 'onderzoeksfinanciering') {
    slug = JONGE_SLUGS[Math.floor(Math.random() * JONGE_SLUGS.length)];
  }

  const FWG2_S_SLUGS = [
    'fwg2s1',
    'fwg2s2',
    'fwg2s3',
    'fwg2s4',
    'fwg2s5',
    'fwg2s6',
    'fwg2s7',
    'fwg2s8',
    'fwg2s9',
    'fwg2s10',
  ];

  if (slug === 'fwg2s') {
    slug = FWG2_S_SLUGS[Math.floor(Math.random() * FWG2_S_SLUGS.length)];
  }

  const FWG2_P_SLUGS = ['fwg2p1', 'fwg2p2'];

  if (slug === 'fwg2p') {
    slug = FWG2_P_SLUGS[Math.floor(Math.random() * FWG2_P_SLUGS.length)];
  }

  const ENERGIERSCHAARSTE_SLUGS = ['energieschaarste1', 'energieschaarste2'];

  if (slug === 'energieschaarste') {
    slug = ENERGIERSCHAARSTE_SLUGS[Math.floor(Math.random() * ENERGIERSCHAARSTE_SLUGS.length)];
  }

  return slug;
}

export function getConsultationSlugFromRoute(): string {
  let slug;

  if (isLocal()) {
    slug = LOCAL_SLUG()
  } else {
    slug = parseSubdomain();
  }

  return processSlugMapping(slug);
}
