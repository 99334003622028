<script setup lang="ts">
import { computed } from 'vue';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { useConsultationStore } from '../stores/useConsultationStore';

const interfaceStore = useInterfaceStore();
const consultationStore = useConsultationStore();

const mobileFooter = computed(() => interfaceStore.getMobileView && !consultationStore.completed);
const isResearchView = computed(() => consultationStore.inChoiceTask && mobileFooter.value);

const isResearchLandscapeView = computed(
  () => consultationStore.inChoiceTask && mobileFooter.value && interfaceStore.isLandscapeMode
);

const canShowAccessibilityInfo = computed(() => {
  const accessibilityInfo = consultationStore.getAccessibilityInfo;
  return accessibilityInfo && accessibilityInfo[consultationStore.locale];
});

const footerCustomContent = computed(() => {
  const customFooterText = consultationStore.getCustomFooterText;
  if (customFooterText && customFooterText[consultationStore.locale]) {
    return customFooterText;
  }

  const currentYear = new Date().getFullYear();
  return `Wevaluate - ${currentYear}`;
});

const openPrivacyStatement = () => {
  consultationStore.$patch({
    showPrivacyStatement: true,
    infoMode: 'statement',
  });
};

const openAccessibilityInfo = () => {
  consultationStore.$patch({
    showAccessibilityInfo: true,
    infoMode: 'accessibility',
  });
};
</script>

<template>
  <footer
    :class="{
      'mobile-footer': mobileFooter,
      'research-footer': isResearchView,
      'research-landscape-footer': isResearchLandscapeView,
    }"
    class="the-footer container row justify-content-center ms-0 me-0"
  >
    <div class="container row p-0 m-0 max-header-footer-content-width justify-content-between">
      <div class="col-6 d-flex align-items-center">
        <button
          id="footer-privacy-link"
          class="footer-content ps-2 ps-sm-3 ps-md-4"
          tabindex="0"
          @click="openPrivacyStatement"
        >
          {{ $t("privacyStatement") }}
        </button>
        <button
          v-if="canShowAccessibilityInfo"
          id="footer-accessibility-link"
          class="footer-content ps-2 ps-sm-3 ps-md-4"
          tabindex="0"
          @click="openAccessibilityInfo"
        >
          {{ $t("accessibility_info.accessibility") }}
        </button>
      </div>
      <span
        id="footer-customizable-content"
        class="footer-content pe-2 pe-sm-3 pe-md-4 col-6 d-flex align-items-center justify-content-end"
      >
        <LanguageSelector :to-be-selected="footerCustomContent" />
      </span>
    </div>
  </footer>
</template>

<style>
.the-footer {
  position: relative;
  width: 100%;
  padding: 20px 0px 20px 0px;
  background-color: var(--primary-custom-footer);
  box-shadow: -6px -8px 11px #00000029;
}

.mobile-footer.the-footer.research-footer {
  margin-bottom: 94px;
}

.mobile-footer.the-footer,
.mobile-footer.the-footer.research-landscape-footer {
  margin-bottom: 56px;
}

.footer-content {
  cursor: pointer;
  user-select: none;
  text-decoration: underline;
  color: var(--text-color-custom-footer);
  font-size: 0.8rem;
}

#footer-privacy-link {
  color: var(--text-color-custom-footer);
  background-color: transparent;
  border: none;
}

#footer-accessibility-link {
  color: var(--text-color-custom-footer);
  background-color: transparent;
  border: none;
}

#footer-customizable-content {
  text-decoration: none;
  cursor: default;
}

#footer-customizable-content span {
  color: var(--text-color-custom-footer);
  cursor: default;
  margin: 0;
  font-size: 0.8rem;
  line-height: 1.5;
  text-align: right;
}

#footer-customizable-content p {
  color: var(--text-color-custom-footer);
  cursor: default;
  margin: 0;
  font-size: 0.8rem;
  line-height: 1.5;
  text-align: right;
}

#footer-customizable-content a {
  color: var(--text-color-custom-footer);
}

.footer-content:hover#footer-customizable-content {
  color: var(--text-color-custom-footer);
}
</style>
