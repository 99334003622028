<template>
  <div>
    <div id="la-posta-embed-script-placeholder" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import postscribe from 'postscribe';
import { mapState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  computed: {
    ...mapState(useConsultationStore, [
      'stage'
    ]),
  },
  mounted() {
    const embedLink = this.stage.fields.lapostaEmbedLink;

    postscribe(
      '#la-posta-embed-script-placeholder',
      `<script async src="${embedLink}"><\/script>`
    );
  },
});
</script>
