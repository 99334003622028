import { Option } from "../../types/Option";
import { Property } from "../../types/Property";
import { StageChoiceTask } from "../../types/StageChoiceTask";
import { calculateAdditiveProjectValue } from "./calculateAdditiveProjectValue";
import { calculateAverageProjectValue } from "./calculateAverageProjectValue";
import { calculateMultipliedProjectValue } from "./calculateMultipliedProjectValue";

export function calculatePropertyValues(
  property: Property,
  selectedOptions: Option[],
  stage: StageChoiceTask
) {
  if (property.calculation === 'none') {
    return;
  } else if (property.calculation === 'add') {
    property.current_calculated_value = Math.round(
      calculateAdditiveProjectValue(
        property,
        selectedOptions,
        stage,
      )
    );
  } else if (property.calculation === 'avg') {
    property.current_calculated_value = Math.round(
      calculateAverageProjectValue(
        property,
        selectedOptions,
        stage,
      )
    );
  } else if (property.calculation === 'multiply') {
    property.current_calculated_value = Math.round(
      calculateMultipliedProjectValue(
        property,
        selectedOptions,
        stage,
      )
    );
  }

  if (!property.restrictive) {
    return;
  }

  if (
    property.restrictive_min_value !== null &&
    property.current_calculated_value < property.restrictive_min_value
  ) {
    property.current_calculated_value_exceeds_restriction = 'min';
    return;
  }

  if (
    property.restrictive_max_value !== null &&
    property.current_calculated_value > property.restrictive_max_value
  ) {
    property.current_calculated_value_exceeds_restriction = 'max';
    return;
  }

  if (
    property.flexible_min_value !== null &&
    property.current_calculated_value < property.flexible_min_value
  ) {
    property.current_calculated_value_exceeds_restriction = 'flex-min';
    return;
  }

  if (
    property.flexible_max_value !== null &&
    property.current_calculated_value > property.flexible_max_value
  ) {
    property.current_calculated_value_exceeds_restriction = 'flex-max';
    return;
  }

  property.current_calculated_value_exceeds_restriction = null;
}
