<template>
  <nav
    :class="{
      'mobile-navigation': getMobileView && !inChoiceTask,
    }"
    class="navigation container justify-content-between flex-nowrap row"
  >
    <div
      v-if="navigationPanelTop"
      class="navigation-back p-0"
    >
      <button
        v-if="showBackButtonTopPanel"
        class="navigation-button navigation-button-back navigation-button-back-top"
        @click="functionBack()"
      >
        <font-awesome-icon
          class="navigation-arrows navigation-arrow-back ms-auto me-auto"
          aria-hidden="true"
          icon="arrow-left"
        />
        <span class="visually-hidden">{{ $t('pagination.back') }}</span>
      </button>
    </div>
    <div
      v-else
      class="navigation-back col p-0"
    >
      <button
        v-if="showBackButtonInMobileView"
        class="navigation-button navigation-button-back mobile-navigation-button-back"
        @click="functionBack()"
      >
        <font-awesome-icon
          class="navigation-arrows navigation-arrow-back"
          icon="arrow-left"
        />
        <span class="navigation-labels navigation-label-back ms-2">
          {{ $t('pagination.back') }}
        </span>
      </button>
    </div>
    <div class="navigation-next col d-flex justify-content-end p-0">
      <button
        v-if="showNextButton"
        class="navigation-button"
        :class="
          nextDisabled
            ? 'navigation-button-next-disabled'
            : 'navigation-button-next'
        "
        @click="functionNext()"
      >
        <span class="navigation-labels navigation-label-next me-2">
          {{ $t('pagination.next') }}
        </span>
        <font-awesome-icon
          class="navigation-arrows navigation-arrow-next"
          icon="arrow-right"
        />
      </button>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  props: {
    navigationPanelTop: {
      type: Boolean,
      default: false,
    },
    inChoiceTask: {
      type: Boolean,
      default: false,
    },
    submitFunction: {
      type: Function,
      required: false,
      default: undefined,
    },
  },

  setup() {
    const consultationStore = useConsultationStore();
    return { consultationStore };
  },

  computed: {
    ...mapState(useInterfaceStore, [
      'getMobileView',
    ]),

    ...mapState(useConsultationStore, [
      'canNavigateBack',
      'canNavigateForward',
      'nextDisabled',
    ]),

    showBackButtonTopPanel() {
      return !this.getMobileView && this.canNavigateBack;
    },

    showBackButtonInMobileView() {
      return this.getMobileView && this.canNavigateBack;
    },

    showNextButton() {
      if (this.navigationPanelTop) {
        return false;
      }

      return this.canNavigateForward;
    },
  },

  methods: {
    functionBack() {
      this.consultationStore.back();
    },

    functionNext() {
      if (this.submitFunction) {
        const result = this.submitFunction();

        if (result === false) {
          return;
        }
      }

      this.consultationStore.next();
    },
  },
});
</script>

<style scoped>
.container {
  margin: 0;
  padding: 0;
  max-width: 100%;
}

.navigation-arrow-back {
  color: var(--text-color-heading);
}

.mobile-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.mobile-navigation .navigation-back {
  background-color: lightgrey;
}

.mobile-navigation .navigation-next {
  background-color: lightgrey;
}

.mobile-navigation .navigation-next .navigation-button.navigation-button-next,
.mobile-navigation
  .navigation-next
  .navigation-button.navigation-button-next-disabled,
.mobile-navigation .navigation-back .navigation-button.navigation-button-back,
.mobile-navigation
  .navigation-back
  .navigation-button.navigation-button-back-disabled {
  width: 100%;
  border-radius: 0px;
  height: 56px;
  padding-inline: 1rem;
}

.mobile-navigation .navigation-next .navigation-button.navigation-button-next,
.mobile-navigation
  .navigation-next
  .navigation-button.navigation-button-next-disabled {
  justify-content: end;
}

.mobile-navigation .navigation-back .navigation-button.navigation-button-back,
.mobile-navigation
  .navigation-back
  .navigation-button.navigation-button-back-disabled {
  justify-content: start;
}

.navigation-button-next {
  transition: all 0.2s ease-out;
}

.navigation-button-next-disabled {
  transition: background-color 0.2s ease-out;
}

.navigation-button-next:active {
  transform: scale(0.98);
}

.mobile-navigation
  .navigation-next
  .navigation-button.navigation-button-next:hover,
.mobile-navigation
  .navigation-next
  .navigation-button.navigation-button-next:active,
.mobile-navigation .navigation-back .navigation-button.navigation-button-back {
  color: white;
  background-color: #00a6d6;
  border: none;
}

.mobile-navigation
  .navigation-back
  .navigation-button.navigation-button-back
  .navigation-arrow-back,
.mobile-navigation
  .navigation-back
  .navigation-button.navigation-button-back-disabled
  .navigation-arrow-back {
  color: white;
}
</style>
