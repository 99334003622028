export const Environments = {
  DEVELOPMENT: 'dev',
  STAGING: 'staging',
  PRODUCTION: 'master',
};

export const environmentModifier = () => {
  if (import.meta.env.VITE_STAGE === Environments.DEVELOPMENT) {
    return '-dev';
  } else if (import.meta.env.VITE_STAGE === Environments.STAGING) {
    return '-staging';
  }

  return '';
};

export const API_BASE_URL = () =>
  `https://api.wevaluate${environmentModifier()}.io`;

export const LOCAL_SLUG = () => {
  return import.meta.env.VITE_LOCAL_SLUG;
};

export const CMS_BASE_URL = () => {
  return (
    import.meta.env.VITE_CMS_BASE_URL ||
    `https://cms.wevaluate${environmentModifier()}.io/`
  );
};

export const WARN_BEFORE_UNLOAD = () => {
  return import.meta.env.VITE_WARN_BEFORE_UNLOAD !== 'false';
};

export function getCurrentEnvironment() {
  const stage = import.meta.env.VITE_STAGE;
  if (stage === Environments.PRODUCTION || stage === Environments.STAGING) {
    return stage;
  }

  return Environments.DEVELOPMENT;
}
