<template>
  <div class="form-group animation-dropdown mt-3">
    <div v-show="questionTitle">
      <legend
        class="question-title d-flex flex-row align-items-center flex-nowrap"
      >
        <LanguageSelector :to-be-selected="questionTitle" />
        <span v-if="isRequired">*</span>
        <span
          v-show="currentQuestionDisplayNumber && totalQuestionNumber"
          class="question-number p-0 ms-1 d-flex stay-ltr"
        >
          <span>(</span>
          <span>{{ currentQuestionDisplayNumber }}</span>
          <span>/</span>
          <span>{{ totalQuestionNumber }}</span>
          <span>)</span>
        </span>
      </legend>
    </div>
    <div
      v-if="isDropdownOpen"
      class="input-dropdown-backdrop"
      @click="functionDropdownTrigger"
    />
    <div class="input-dropdown-wrapper mb-1">
      <button
        :style="[borderColor, borderClass]"
        :aria-expanded="isDropdownOpen ? 'true' : 'false'"
        :aria-haspopup="true"
        class="position-relative input-dropdown-trigger question-border align-items-center justify-content-between d-flex px-3"
        @click="functionDropdownTrigger"
      >
        <span class="answer-option input-dropdown-selected-answer">
          {{ labelDropdownDisplay }}
        </span>
        <font-awesome-icon icon="chevron-down" />
      </button>
      <ul
        v-if="isDropdownOpen"
        class="input-dropdown-options p-0"
        role="listbox"
        tabindex="-1"
      >
        <li
          v-for="answer in answerOptions"
          :key="answer.id"
          role="option"
          class="input-dropdown-option m-0 d-flex align-items-center px-3"
          :aria-selected="labelDropdownDisplay === answer.title ? 'true' : 'false'"
          tabindex="0"
          @click="functionDropdownSelected(answer)"
          @keydown.enter="functionDropdownSelected(answer)"
        >
          <span class="answer-option">
            {{ answer.title }}
          </span>
        </li>
      </ul>
    </div>
    <div
      :style="textOpacity"
      class="alert-required-message my-2"
    >
      {{ validationLabel }}
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  props: {
    currentQuestionData: {
      type: Object,
      required: true,
    },
    showUserInputIsRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentQuestionNumber: {
      type: Number,
      required: false,
      default: undefined,
    },
    currentQuestionDisplayNumber: {
      type: Number,
      required: false,
      default: undefined,
    },
    totalQuestionNumber: {
      type: Number,
      required: false,
      default: undefined,
    },
    currentUserData: {
      type: Object,
      required: true,
    },
    validationLabel: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  emits: ['sendCurrentUserData'],

  data() {
    return {
      answerData: {},
      isDropdownOpen: false,
      onDropdownDisplay: null,
    };
  },

  computed: {
    ...mapState(useConsultationStore, [
      'locale',
    ]),

    questionTitle() {
      return this.currentQuestionData.title;
    },

    answerOptions() {
      if (!this.currentQuestionData.answer_options) {
        return [];
      }

      const answers = this.currentQuestionData.answer_options.map((answer) => {
        return {
          id: answer.id,
          title: answer.title[this.locale],
        };
      });

      return answers.filter((answer) => {
        return answer.title !== undefined;
      });
    },

    labelDropdownDisplay() {
      return this.onDropdownDisplay;
    },

    isRequired() {
      return this.currentQuestionData.required;
    },

    borderColor() {
      return this.showUserInputIsRequired
        ? { borderColor: '#D40005' }
        : { borderColor: '#E6E6E6' };
    },

    textOpacity() {
      return this.showUserInputIsRequired ? { opacity: '1' } : { opacity: '0' };
    },

    borderClass() {
      if (this.isDropdownOpen) {
        return { 'border-radius': '10px 10px 0 0' };
      }

      return { 'border-radius': '10px' };
    },
  },

  created() {
    const answerData = this.currentUserData ? { ...this.currentUserData } : {};

    if (answerData.user_has_answered === true) {
      this.onDropdownDisplay = answerData.user_answered_string;
    } else {
      answerData.user_has_answered = false;
      answerData.user_answered_string = null;
      answerData.raw_input_dropdown = null;
      answerData.answer_id = null;
    }

    answerData.id = this.currentQuestionData.id;
    answerData.title = this.questionTitle;
    answerData.required = this.isRequired;
    answerData.field_type = this.currentQuestionData.field_type;
    answerData.question_number = this.currentQuestionNumber;
    answerData.answer_options = this.answerOptions;

    this.answerData = answerData;

    this.$emit('sendCurrentUserData', this.answerData);
  },

  mounted() {
    //  retrieve label for display until user selects an answer
    if (this.answerData.user_has_answered === false) {
      this.onDropdownDisplay = this.$t('pagination.select');
    }
  },

  methods: {
    functionDropdownTrigger() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },

    functionDropdownSelected(answer) {
      this.onDropdownDisplay = answer.title;

      this.answerData.user_answered_string = answer.title;
      this.answerData.raw_input_dropdown = answer.id;
      this.answerData.answer_id = answer.id;
      this.answerData.user_has_answered = true;

      this.$emit('sendCurrentUserData', this.answerData);
      this.alertUser = false;

      this.functionDropdownTrigger();
    },
  },
});
</script>

<style scoped>
.input-dropdown-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1;
}

.input-dropdown-wrapper {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.input-dropdown-trigger {
  width: 100%;
  height: 100%;
  min-height: 47px;
  z-index: 3;
  background-color: white;
}

.input-dropdown-trigger:hover {
  background-color: #e7eaec;
}

.input-dropdown-trigger:hover ~ .input-dropdown-selected-trigger {
  background-color: #e7eaec;
}

.input-dropdown-selected-answer:hover {
  background-color: #e7eaec;
}

.input-dropdown-selected-answer:hover + .input-dropdown-trigger {
  background-color: #e7eaec;
}

.input-dropdown-selected-answer {
  height: 100%;
  background-color: transparent;
  user-select: none;
}

.input-dropdown-options {
  transition: height 4s;
  cursor: pointer;
  position: relative;
  width: 100%;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-style: solid;
  border-top-style: none;
  border-color: #e7eaec;
  border-width: 1px;
  opacity: 1;
  z-index: 2;
}

ul.input-dropdown-options > * {
  border-radius: 0;
  border-bottom: solid 1px;
  border-bottom-color: #e7eaec;
}

ul.input-dropdown-options li:last-child {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-color: transparent;
}

li.input-dropdown-option {
  list-style: none;
  width: 100%;
  height: 100%;
  min-height: 47px;
  color: black;
  background-color: white;
}

li.input-dropdown-option:hover {
  color: white;
  background-color: #017cf7;
  border-color: #017cf7;
}

.question-border {
  box-shadow: none;
}
</style>
