<template>
  <div class="project-card-top-section-wrapper row p-0 m-0 flex-nowrap">
    <div class="project-title-wrapper col">
      <div class="row flex-nowrap d-flex">
        <div
          v-if="optionImage"
          class="project-image-wrapper col-3 ps-0"
        >
          <img
            :src="optionImage"
            class="project-image"
          >
        </div>
        <p
          v-if="optionImage"
          class="project-title col p-0 my-0 mx-1"
        >
          <LanguageSelector :to-be-selected="optionTitle" />
        </p>
        <p
          v-else
          class="project-title col p-0 m-0 pe-0"
        >
          <LanguageSelector :to-be-selected="optionTitle" />
        </p>
      </div>
    </div>
    <OptionInfoButton
      :option="option"
      class="col-2 m-0 p-0 d-flex justify-content-end"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import OptionInfoButton from './OptionInfoButton.vue';

export default defineComponent({
  components: {
    OptionInfoButton,
  },

  props: {
    option: {
      type: Object,
      required: true,
    },
  },

  computed: {
    optionTitle() {
      return this.option.title;
    },

    optionImage() {
      return this.option.img;
    },
  },
});
</script>

<style scoped>
img.project-image {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  object-fit: cover;
  user-select: none;
}
</style>
