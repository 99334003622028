import qs from 'query-string';

export const Dynata = {
  QUERY_STRING_KEYS: ['pid', 'psid'],

  NAME: 'Dynata',

  isPanelUser(queryString) {
    const parsed = qs.parse(queryString);

    let isPanelUser = false;

    Object.keys(parsed).forEach((param) => {
      if (this.QUERY_STRING_KEYS.includes(param.toLowerCase())) {
        isPanelUser = true;
      }
    });

    return isPanelUser;
  },

  getPanelParams(queryString) {
    const parsed = qs.parse(queryString);

    let panelUserId = null;
    let key = null;

    Object.keys(parsed).forEach((param) => {
      if (this.QUERY_STRING_KEYS.includes(param.toLowerCase())) {
        key = param;
        panelUserId = parsed[param];
      }
    });

    const result = {};
    result[key] = panelUserId;

    return result;
  },
};
