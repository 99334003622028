<template>
  <ChoiceTaskOption>
    <template #top-section>
      <ChoiceTaskOptionTopSection :option="option" />
    </template>
    <template #bottom-section>
      <div class="col m-0 me-1 p-0 d-flex">
        <ChoiceTaskOptionAttributes :option-id="option.id" />
      </div>
      <div class="col-6 m-0 ms-1 p-0 d-flex align-items-end">
        <ChoiceTaskPointsModeInput
          :option="option"
        />
      </div>
    </template>
  </ChoiceTaskOption>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ChoiceTaskPointsModeInput from './ChoiceTaskPointsModeInput.vue';
import ChoiceTaskOption from './ChoiceTaskOption.vue';
import ChoiceTaskOptionTopSection from './ChoiceTaskOptionTopSection.vue';
import ChoiceTaskOptionAttributes from './ChoiceTaskOptionAttributes.vue';

export default defineComponent({
  components: {
    ChoiceTaskPointsModeInput,
    ChoiceTaskOption,
    ChoiceTaskOptionTopSection,
    ChoiceTaskOptionAttributes,
  },

  props: {
    option: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style scoped>
.card-item-overlay {
  border-style: solid;
  border-color: grey;
  position: absolute;
  bottom: 0;
  left: 16px;
  pointer-events: none;
  opacity: 0.8;
  background: white;
  border-radius: 25px;
  border-style: solid;
  border-color: #e9eff4;
  border-width: 1px;
  transition: border-color 0.8s ease-in;
  transition: opacity 0.4s ease-out;
}

.overlay-text {
  position: absolute;
  bottom: 0;
  user-select: none;
  transition: opacity 1s ease-out;
}

.button-color-transition {
  transition: color 0.3s ease-out;
}
</style>
