<template>
  <div class="form-group">
    <div
      :style="returnBorderTop"
      class="pb-4"
      style="border-width: 1px; border-color: #e6e6e6"
    >
      <legend class="text-section-title">
        <LanguageSelector :to-be-selected="questionTitle" />
      </legend>
      <div>
        <LanguageSelector :to-be-selected="content" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    currentQuestionData: {
      type: Object,
      required: true,
    },
    alertUserForRequired: Boolean,
    currentQuestionNumber: {
      type: Number,
      required: false,
      default: undefined,
    },
  },

  data() {
    return {
      answerData: {},
      textColor: 'black',
      alertUser: false,
    };
  },

  computed: {
    questionTitle() {
      return this.currentQuestionData.title;
    },

    content() {
      return this.currentQuestionData.description;
    },

    returnBorderTop() {
      if (this.currentQuestionNumber > 0) {
        return { paddingTop: '1rem' };
      }

      return {};
    },
  },

  watch: {
    alertUserForRequired(bool) {
      this.alertUser = this.alertUserForRequired;
    },
  },

  created() {
    this.answerData = this.currentQuestionData;
    this.answerData.user_has_answered = true;
  },
});
</script>

<style scoped>
label {
  width: 100%;
}

input.form-control {
  resize: none;
  max-width: 800px;
}
</style>
