import { Option } from "../../types/Option";
import { Property } from "../../types/Property";
import { StageChoiceTask } from "../../types/StageChoiceTask";

export function calculateAdditiveProjectValue(
  property: Property,
  selectedOptions: Option[],
  stage: StageChoiceTask
) {
  let value = property.initial_value || 0;
  const linear = stage.fields.linearPropertyValues;
  const versionData = stage.fields.versionData;

  if (versionData === undefined) {
    return value;
  }

  if (linear) {
    value = selectedOptions.reduce((previousValue, option: Option) => {
      const versionValue = property.values.find(({ id }) => id === option.id);

      if (versionValue === undefined) {
        return previousValue;
      }

      return previousValue + versionValue.value * option.quantity;
    }, value);
  } else {
    selectedOptions.forEach((option: Option) => {
      if (versionData[option.id] === undefined || versionData[option.id][property.id] === undefined) {
        return;
      }

      const attributeValues = versionData[option.id][property.id];

      if (stage.fields.mode === 'slide_to_max') {
        const addValue = attributeValues[option.step] ? attributeValues[option.step] : 0;
        value += addValue;
      } else {
        const addValue = attributeValues[option.quantity] ? attributeValues[option.quantity] : 0;
        value += addValue;
      }
    });
  }

  return value;
}
