<template>
  <div
    v-if="inDashboard"
    style="position: relative"
    class="normal-attributes-container"
  >
    <div
      class="polarity-attribute-title-wrapper d-flex flex-nowrap align-items-center dashboard-m-12"
    >
      <LanguageSelector
        v-if="!hidePolarityLabel"
        :to-be-selected="polarityOfCurrentAttribute"
        class="more-detail-attribute-title-bold attribute-polarity"
      />
      <LanguageSelector
        :to-be-selected="attributeTitle"
        :class="{ 'ms-1': !hidePolarityLabel }"
        class="more-detail-attribute-title-normal"
      />
    </div>
    <AttributeAnimated
      :current-attribute-data="currentAttributeData"
      :incoming-value="currentAttributeValue"
      :array-index="arrayIndex"
      class="attribute-value-height"
    />
  </div>
  <div
    v-else-if="!inDashboard"
    class="attribute-merger-container d-flex flex-direction-row"
  >
    <div class="attribute-vertical-line-wrapper">
      <div
        :style="{ backgroundColor: attributeColor }"
        class="attribute-vertical-line"
      />
    </div>
    <div
      id="attribute-title-wrapper-dashboard"
      class="attribute-title-wrapper"
    >
      <LanguageSelector
        :to-be-selected="attributeTitle"
        class="attribute-normal-merged-title"
      />
    </div>
    <div
      class="attribute-value-checkout-wrapper d-flex align-items-center justify-content-end"
    >
      <font-awesome-icon
        v-if="restrictiveIcon"
        :icon="restrictiveIcon"
        class="fa-1x me-2"
      />
      <AttributeValueConverter
        :current-attribute-data="attributeData"
        :raw-value="attributeValue"
        :calculative-dictionary="true"
        class="attribute-value-summary"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { gsap } from 'gsap';
import AttributeAnimated from './AttributeAnimated.vue';

export default defineComponent({
  components: {
    AttributeAnimated,
  },
  props: {
    currentAttributeData: {
      type: Object,
      required: true,
    },
    currentAttributeValue: {
      type: Number,
      required: true,
    },
    componentInDashboard: Boolean,
    currentColor: {
      type: String,
      required: false,
      default: undefined,
    },
    currentIcon: {
      type: String,
      required: false,
      default: undefined,
    },
    arrayIndex: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      tweenedNumber: 0,
      tweenTime: 1,
    };
  },

  computed: {
    inDashboard() {
      if (this.componentInDashboard) {
        return true;
      } else {
        return false;
      }
    },

    theColorOfTheHeading() {
      return this.pinkColor;
    },

    attributeData() {
      return this.currentAttributeData;
    },

    attributeTitle() {
      if (this.currentAttributeData.hasOwnProperty('title')) {
        return this.currentAttributeData.short_title;
      }

      return '-';
    },

    attributeValue() {
      if (this.currentAttributeValue === undefined) {
        return 0;
      }

      return this.currentAttributeValue;
    },

    attributeColor() {
      if (this.currentAttributeData.restrictive === true) {
        return this.currentColor;
      }

      return '#017CF7';
    },

    attributeColorDynamic() {
      if (this.currentAttributeData.restrictive) {
        if (
          this.currentAttributeData.current_calculated_value_exceeds_restriction
        ) {
          return '#D40005';
        } else {
          return '#92C301';
        }
      }

      return '#017CF7';
    },

    restrictiveValue() {
      if (this.currentAttributeData.hasOwnProperty('restrictive_max_value')) {
        return this.currentAttributeData.restrictive_max_value;
      }

      return false;
    },

    restrictiveIcon() {
      if (this.currentIcon && this.currentIcon !== '') {
        return this.currentIcon;
      }

      return false;
    },

    overRestrictive() {
      return this.restrictiveValue && this.attributeValue > this.restrictiveValue;
    },

    hidePolarityLabel() {
      const { hidden_effect_direction_label } = this.attributeData;
      return (
        hidden_effect_direction_label && hidden_effect_direction_label === true
      );
    },

    polarityOfCurrentAttribute() {
      const currentValue = Math.round(this.tweenedNumber);

      if (Math.sign(currentValue) === 1) {
        return this.$t('pagination.choice_task_option_detail_property_increase');
      } else if (Math.sign(currentValue) === -1) {
        return this.$t('pagination.choice_task_option_detail_property_decrease');
      }

      return this.$t('pagination.choice_task_option_detail_property_neutral');
    },
  },

  watch: {
    currentAttributeValue: function (newValue) {
      gsap.to(this.$data, {
        duration: 1,
        tweenedNumber: newValue,
        ease: 'Circ.easeOUT',
      });
    },
  },

  created() {
    this.tweenedNumber = this.attributeValue;
  },
});
</script>

<style scoped>
.attribute-value-normal-container {
  background-color: white;
  margin: 1em 0;
  display: flex;
  flex-direction: row;
}

.attribute-vertical-line-wrapper {
  display: inline-block;
}

.attribute-vertical-line {
  display: inline-block;
  width: 7px;
  height: 100%;
  background-color: #017cf7;
}

.attribute-title-value-wrapper {
  display: flex;
  flex-direction: column;
}

.attribute-title-wrapper {
  flex-basis: auto;
  height: 100%;
  display: flex;
  padding-left: 0;
  margin-left: 15px;
}

#attribute-title-wrapper-dashboard {
  flex-basis: 50%;
}

.attribute-title {
  font-size: 1rem;
}

.attribute-value-dashboard {
  font-size: 1rem;
  display: block;
  color: #017cf7;
  margin-right: auto;
  margin-left: 15px;
}

.attribute-merger-container {
  background-color: white;
  margin: 1rem 0;
}

.attribute-vertical-line {
  display: inline-block;
  width: 7px;
  height: 100%;
  background-color: #017cf7;
}

.attribute-value-checkout-wrapper {
  flex-basis: 50%;
}

.attribute-value-summary {
  font-size: 1rem;
  color: var(--text-color-content);
}

.direction-col {
  flex-direction: column;
}

.align-value-right {
  text-align: left;
}

dd {
  height: 10px;
  padding: 5px;
}

.percentage {
  background-color: var(--bs-success);
  padding: 0;
  border-radius: 20px;
}

.merged-value {
  width: 100%;
  display: block;
}

.more-detail-attribute-title-bold {
  font-size: 1rem;
  font-weight: 700;
  color: var(--text-color-heading);
}

.more-detail-attribute-title-normal {
  font-size: 1rem;
  font-weight: 400;
  color: var(--text-color-heading);
}
</style>

<style>
.soft-property-icon {
  font-size: 24px;
}
</style>
