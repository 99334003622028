<template>
  <div v-if="mailchimpEmbedCode">
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="mailchimpEmbedCode" />
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import { useConsultationStore } from '../stores/useConsultationStore';
export default defineComponent({
  computed: {
    ...mapState(useConsultationStore, [
      'stage',
    ]),
    mailchimpEmbedCode() {
      const code = this.stage.fields.mailchimpEmbedCode;
      return code;
    },
  },
});
</script>

<style>
#mc_embed_signup {
  width: 100% !important;
}
#mc_embed_signup form {
  padding: 0px !important;
}
</style>
