export const es = {
  appName: "Wevaluate",
  help: "Ayuda",
  close: "Cerrar",
  optionInfoButton: "Información: {optionTitle}",
  privacyStatement: "Declaración de privacida",
  accessibility_info: {
    accessibility: "Accesibilidad",
    title: "Características de accesibilidad de esta consulta"
  }
}
