import { Property } from "../../types/Property";

interface ColorScheme {
  allowed: string;
  flexible: string;
  restrictive: string;
}

const GREEN = '#92C301';
const ORANGE = '#EAB400';
const RED = '#D40005';

const ALLOWED = '#0066A1';
const FLEXIBLE = '#E9C369';
const WARNING = '#8D99AE';

const defaultScheme: ColorScheme = {
  allowed: GREEN,
  flexible: ORANGE,
  restrictive: RED
};

const neutralScheme: ColorScheme = {
  allowed: ALLOWED,
  flexible: FLEXIBLE,
  restrictive: WARNING
};

export function restrictiveColorScheme(isNeutral: boolean): ColorScheme {
  const scheme = isNeutral ? neutralScheme : defaultScheme;
  return scheme;
}
