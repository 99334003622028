<template>
  <div
    class="p-0 m-0 d-flex align-items-center"
    style="position: relative; overflow-y: hidden"
  >
    <AttributeValueConverter
      v-if="showPrefix"
      :current-attribute-data="currentAttributeData"
      :raw-value="tweenedNumber"
      :return-positive-number="true"
      :return-pre-fix-only="true"
      style="white-space: nowrap"
      class="me-2 ms-0 more-detail-attribute-title-bold d-flex align-items-center dashboard-value h-100"
    />
    <ul
      v-if="!softPropertyMode"
      class="p-0 m-0 d-flex align-items-center h-100"
    >
      <transition-group
        :name="animationName"
        tag="span"
      >
        <AttributeValueConverter
          v-for="item in arrayOfNumbers"
          :key="item.id"
          :current-attribute-data="currentAttributeData"
          :raw-value="tweenedNumber"
          :return-value-only="true"
          :calculative-dictionary="true"
          :style="[
            { transitionDuration: transitionTime },
            { transitionDelay: transitionDelay },
          ]"
          class="m-0 attribute-animated-item animated-component dashboard-value d-flex align-items-start"
          style="height: 100%"
        />
      </transition-group>
    </ul>
    <ul
      v-else-if="softPropertyMode"
      class="p-0 m-0"
      style="max-height: 24px; min-height: 24px"
    >
      <transition-group
        :name="animationName"
        tag="span"
        class="h-100"
      >
        <AttributeValueConverter
          v-for="item in arrayOfNumbersRange"
          :key="item.id"
          :current-attribute-data="currentAttributeData"
          :raw-value="item.value"
          :return-positive-number="true"
          :calculative-dictionary="true"
          :style="[
            { transitionDuration: transitionTime },
            { transitionDelay: transitionDelay },
          ]"
          class="m-0 animated-component dashboard-value d-flex h-100"
          @send-quantity-of-icons="receiveQuantityOfIcons"
          @send-current-label="receiveCurrentLabel"
        />
      </transition-group>
    </ul>
    <div class="h-100 d-flex align-items-center">
      <AttributeValueConverter
        v-if="showPostFix"
        :current-attribute-data="currentAttributeData"
        :raw-value="tweenedNumber"
        :return-positive-number="true"
        :return-post-fix-only="true"
        style="white-space: nowrap"
        class="ms-2 h-100 d-flex align-items-center"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  props: {
    incomingValue: {
      type: Number,
      required: true,
    },
    currentAttributeData: {
      type: Object,
      required: true,
    },
    arrayIndex: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      arrayOfNumbers: [],
      arrayOfNumbersRange: [],
      counter: 0,
      counterRange: 0,
      animationDirection: 'UP',
      previousValue: 0,
      tweenedNumber: 0,
      transitionTime: '0.5s',
      transitionDelay: '0s',
      softPropertyMode: null,
      softPropertyIconQuantity: null,
      nextLabel: null,
    };
  },

  computed: {
    ...mapState(useConsultationStore, [
      'locale'
    ]),

    prefixAvailable() {
      const data = this.currentAttributeData;
      return Boolean(data.unit_prefix);
    },

    postfixAvailable() {
      const data = this.currentAttributeData;
      return Boolean(data.unit_postfix);
    },

    dictionaryAvailable() {
      const dict = this.currentAttributeData.dictionary;

      if (dict !== null && Object.keys(dict).length > 0) {
        let dictionaryData;

        if (Array.isArray(dict)) {
          dictionaryData = dict;
        } else if (this.locale && dict.hasOwnProperty(this.locale)) {
          //	check if selected language is available in the dictionary otherwise use the first available language
          dictionaryData = dict[this.locale]
            ? dict[this.locale]
            : dict[Object.keys(dict)[0]];
        } else {
          dictionaryData = dict[Object.keys(dict)[0]];
        }

        if (dictionaryData !== null && dictionaryData.length > 0) {
          return true;
        }
      }

      return false;
    },

    animationName() {
      if (this.animationDirection === 'DOWN') {
        return 'list-down';
      }

      return 'list-up';
    },

    showPrefix() {
      return this.prefixAvailable && !this.dictionaryAvailable ? true : false;
    },

    showPostFix() {
      return this.postfixAvailable && !this.dictionaryAvailable ? true : false;
    },
  },

  watch: {
    incomingValue(newValue) {
      //	update incoming value for soft properties
      this.arrayOfNumbersRange[0].value = newValue;

      //	check the difference between current value and the next value
      let valueDifference = newValue - this.previousValue;

      //	transform valueDifference to positive / real number
      if (Math.sign(valueDifference) === -1) {
        this.animationDirection = 'DOWN';
        valueDifference = valueDifference * -1;
      } else if (Math.sign(valueDifference) === 1) {
        this.animationDirection = 'UP';
      } else {
        this.animationDirection = 'UP';
        valueDifference = 0;
      }

      if (!this.softPropertyMode) {
        this.tweenedNumber = newValue;
      }

      this.previousValue = newValue;
    },

    nextLabel(label) {
      if (!this.softPropertyMode) {
        return;
      }

      this.counterRange++;
      this.arrayOfNumbersRange.splice(0, 1);
      this.arrayOfNumbersRange.push({
        id: this.counterRange,
        value: this.incomingValue,
      });
    },

    tweenedNumber(value) {
      this.counter++;
      this.arrayOfNumbers.splice(0, 1);
      this.arrayOfNumbers.push({ id: this.counter, value: this.tweenedNumber });
    },

    softPropertyIconQuantity(quantity) {
      if (!this.softPropertyMode) {
        return;
      }

      this.counterRange++;
      this.arrayOfNumbersRange.splice(0, 1);
      this.arrayOfNumbersRange.push({
        id: this.counterRange,
        value: this.incomingValue,
      });
    },
  },

  created() {
    this.softPropertyMode = false;

    const dict = this.currentAttributeData.dictionary;

    if (dict !== null && Object.keys(dict).length > 0) {
      let dictionaryData;

      if (Array.isArray(dict)) {
        dictionaryData = dict;
      } else if (this.locale && dict.hasOwnProperty(this.locale)) {
        //	check if selected language is available in the dictionary otherwise use the first available language
        dictionaryData = dict[this.locale]
          ? dict[this.locale]
          : dict[Object.keys(dict)[0]];
      } else {
        dictionaryData = dict[Object.keys(dict)[0]];
      }

      if (dictionaryData !== null && dictionaryData.length > 0) {
        const firstEntry = dictionaryData[0];
        if (
          firstEntry.hasOwnProperty('icon_quantity') ||
          firstEntry.hasOwnProperty('label')
        ) {
          this.softPropertyMode = true;
        }
      }
    }

    //	initialize data properties
    this.softPropertyIconQuantity = 0;
    this.nextLabel = '';
  },

  mounted() {
    this.previousValue = this.incomingValue;
    this.tweenedNumber = this.incomingValue;

    // give every property a different timing
    const delay = `${this.arrayIndex * 0.25}s`;
    this.transitionDelay = delay;

    this.counter++;
    this.arrayOfNumbers.splice(0, 1);
    this.arrayOfNumbers.push({ id: this.counter, value: this.incomingValue });

    this.counterRange++;
    this.arrayOfNumbersRange.splice(0, 1);
    this.arrayOfNumbersRange.push({
      id: this.counterRange,
      value: this.incomingValue,
    });
  },

  methods: {
    receiveQuantityOfIcons(quantity) {
      this.softPropertyIconQuantity = quantity;
    },

    receiveCurrentLabel(label) {
      this.nextLabel = label;
    },
  },
});
</script>

<style scoped>
.animated-component {
  transition: all 0.1s ease-out;
}

.list-up-enter-from {
  transform: translateY(35px);
  opacity: 0;
}

.list-up-before-leave {
  transform: translateY(-35px);
}

.list-up-leave-active {
  opacity: 0;
  color: white;
  position: absolute;
  display: none;
}

.list-down-enter-from {
  transform: translateY(-35px);
  opacity: 0;
}

.list-down-before-leave {
  transform: translateY(35px);
}

.list-down-leave-active {
  opacity: 0;
  color: white;
  position: absolute;
  display: none;
}
</style>

<style>
.attribute-animated-item .avc-value {
  display: flex;
  align-items: center;
}
</style>
