import { AnswerData } from "./AnswerData";

export class DiscreteChoiceAnswerData extends AnswerData {
  /**
   * The slug for the choice experiment form.
   */
  protected endpoint = 'dce';

  public formatData(answers: any) {
    const selectedProjects = [];
    let versionId = -1;

    for (const [key, answerData] of Object.entries(answers)) {
      if (Object.keys(answerData.answers).length === 0) {
        continue;
      }

      versionId = answerData.versionId;

      selectedProjects.push({ ...answerData.answers });
    }

    const formatted = [];

    for (const project of selectedProjects) {
      const formattedProject = {
        choice_situation_id: project.choice_situation_id,
        user_answered_double_response_string: project.user_answered_double_response_string,
        user_has_answered_choice_situation: project.user_has_answered_choice_situation,
        user_has_answered_double_response: project.user_has_answered_double_response,
        user_has_chosen_project: project.user_has_chosen_project,
      };

      formatted.push(formattedProject);
    }

    return {
      blockId: this.blockId,
      answerData: {
        versionId: versionId,
        selectedProjects: formatted,
      }
    };
  }
}
