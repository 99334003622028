import { createRouter, createWebHistory } from 'vue-router';
import ConsultationView from './views/ConsultationView.vue';

const routes = [];

routes.push(
  {
    path: '/',
    component: ConsultationView,
  },
  {
    path: '/:pathMatch(.*)*',
    component: ConsultationView,
  }
);

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
