import { Option } from "../../types/Option";
import { Property } from "../../types/Property";
import { StageChoiceTask } from "../../types/StageChoiceTask";
import { calculateAdditiveProjectValue } from './calculateAdditiveProjectValue';

export function calculateAverageProjectValue(
  property: Property,
  selectedOptions: Option[],
  stage: StageChoiceTask
) {
  const additive = calculateAdditiveProjectValue(
    property,
    selectedOptions,
    stage
  );

  const totalQuantity = selectedOptions.reduce((previousValue, option: Option) => {
    return previousValue + option.quantity;
  }, 0);

  // Prevent division by zero
  if (totalQuantity === 0) {
    return property.initial_value || 0;
  }

  return additive / totalQuantity;
}
