<template>
  <span>
    <span v-if="isString">{{ value() }}</span>
    <span v-else-if="!isString">
      <RenderString :string="value()" />
    </span>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import RenderString from './RenderString.vue';
import { mapState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    RenderString,
  },
  props: {
    toBeSelected: {
      type: [String, Object, Boolean],
      required: false,
      default: undefined,
    },
  },

  data() {
    return {
      isString: true,
    };
  },

  computed: {
    ...mapState(useConsultationStore, [
      'locale',
    ]),
  },

  methods: {
    value() {
      if (this.toBeSelected) {
        return this.choosePath();
      }

      return null;
    },

    choosePath() {
      const incomingValue = this.toBeSelected;

      if (typeof incomingValue === 'string') {
        return incomingValue;
      }

      if (typeof incomingValue === 'object') {
        if (Object.keys(incomingValue).length === 0) {
          return '';
        }

        if (incomingValue && incomingValue.hasOwnProperty(this.locale)) {
          if (incomingValue[this.locale] !== null) {
            if (incomingValue[this.locale].charAt(0) === '<') {
              this.isString = false;
            } else {
              this.isString = true;
            }

            return incomingValue[this.locale];
          } else {
            return '';
          }
        } else if (incomingValue) {
          const languages = Object.keys(incomingValue);

          if (incomingValue[languages[0]] !== null) {
            if (incomingValue[languages[0]].charAt(0) === '<') {
              this.isString = false;
            } else {
              this.isString = true;
            }

            return incomingValue[languages[0]];
          } else {
            return '';
          }
        }

        return '';
      }
    },
  },
});
</script>

<style>
.no-wrap-class {
  white-space: nowrap;
}
</style>
