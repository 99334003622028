<template>
  <div class="project-card-slider position-relative d-flex flex-column my-2 mx-0 p-3 h-100 slider-card-wrapper position-relative">
    <div class="slider-top-section-wrapper col p-0">
      <ChoiceTaskSliderHeader
        class="row p-0 m-0"
        :show-attributes="true"
        :show-info="showInfo"
        :option="option"
        :attribute-data="optionCardAttributes"
      />
      <div
        :ref="`slider-tooltip-container-${option.id}`"
        class="slider-track-tooltip-wrapper row m-0 mt-2 position-relative"
      >
        <ChoiceTaskSliderToolTip
          v-if="showToolTip"
          :ref="`slider-tooltip-${option.id}`"
          class="position-absolute"
          :style="toolTipDown ? 'opacity: 0' : 'opacity: 1'"
          :connected-attribute="getConnectedAttribute"
          :current-value-display="getCurrentValueDisplay"
          :tool-tip-id="`slider-tooltip-${option.id}`"
          :slider-ratio="getSliderRatio"
          :slider-track-width="sliderElementWidth"
          :slider-loaded="sliderLoaded"
        />
        <component
          :is="currentComponent"
          :slider-id="option.id"
          :current-value="getCurrentValue"
          :min-value="minSliderValue"
          :max-value="maxSliderValue"
          :symmetrical="isSymmetricalSlider"
          :static-mode="staticMode"
          @pointer-down-event="pointerDownFunction"
          @pointer-up-event="pointerUpFunction"
          @touch-end-event="touchEndFunction"
          @send-slider-value="receiveSliderValue"
          @slider-loaded="setSliderElement"
        />
      </div>
      <div
        v-if="staticMode"
        class="static-mode-layer"
      />
    </div>
    <div
      class="slider-display-wrapper row w-100 p-0 m-0 mt-2 justify-content-center position-relative"
    >
      <div
        v-if="showMarker"
        class="slider-start-marker-wrapper d-flex w-100 position-relative px-0"
        :class="{
          'justify-content-start': !isSymmetricalSlider,
          'justify-content-center': isSymmetricalSlider,
        }"
      >
        <span
          class="slider-start-marker position-absolute"
          :class="{
            'slider-start-marker-normal': !isSymmetricalSlider,
            'slider-start-marker-symmetrical': isSymmetricalSlider,
          }"
        />
      </div>
      <ChoiceTaskSliderToolTip
        v-if="showToolTip"
        :ref="`slider-tooltip-down-${option.id}`"
        class="position-absolute"
        :style="hideToolTipDownStyle"
        :connected-attribute="getConnectedAttribute"
        :current-value-display="getCurrentValueDisplay"
        :tool-tip-id="`slider-tooltip-down-${option.id}`"
        :slider-ratio="getSliderRatio"
        :slider-track-width="sliderElementWidth"
        :keep-in-track-width="true"
        :slider-tooltip-down="true"
        :slider-loaded="sliderLoaded"
      />
      <div
        v-if="showToolTip"
        :style="hideToolTipDownStyle"
        class="slider-display-mobile-wrapper"
      >
        <AttributeValueConverter
          :current-attribute-data="getConnectedAttribute"
          :raw-value="getCurrentValueDisplay"
          class="slider-tooltip-mobile h-100 align-items-bottom mx-auto"
        />
      </div>

      <div
        v-if="showLabels"
        class="w-100 d-flex justify-content-between mt-2 mt-sm-0 px-0"
      >
        <LanguageSelector
          class="project-title project-title-blue"
          :to-be-selected="sliderMinLabel"
        />
        <LanguageSelector
          class="project-title project-title-blue text-right"
          :to-be-selected="sliderMaxLabel"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ChoiceTaskSliderHeader from './ChoiceTaskSliderHeader.vue';
import ChoiceTaskSliderInputEnabled from './ChoiceTaskSliderInputEnabled.vue';
import ChoiceTaskSliderInputDisabled from './ChoiceTaskSliderInputDisabled.vue';
import ChoiceTaskSliderToolTip from './ChoiceTaskSliderToolTip.vue';
import { nextTick } from 'vue';
import { restrictiveProperties } from '../consultation/Properties/restrictiveProperties';
import { optionCardProperties } from '../consultation/Properties/optionCardProperties';
import { mapActions, mapState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    ChoiceTaskSliderHeader,
    ChoiceTaskSliderInputEnabled,
    ChoiceTaskSliderInputDisabled,
    ChoiceTaskSliderToolTip,
  },

  props: {
    option: {
      type: Object,
      required: true,
    },
    showInfo: {
      type: Boolean,
      default: true,
    },
    staticMode: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const consultationStore = useConsultationStore();

    return {
      setAnswerData: consultationStore.setAnswerData,
      calculateOptionEffects: consultationStore.calculateOptionEffects,
      processConditions: consultationStore.processConditions,
    };
  },

  data() {
    return {
      currentValue: 0,
      sliderElement: undefined,
      sliderElementWidth: 0,
      sliderRatio: 0,
      sliderStep: 0,
      minSliderValue: null,
      maxSliderValue: null,
      toolTipDown: false,
      sliderLoaded: false,
    };
  },

  computed: {
    ...mapState(useConsultationStore, [
      'stage',
      'stageId',
      'getAnswerData',
      'stageProperties',
      'isSymmetricalSlider',
      'skipChoiceTask',
    ]),

    answerData() {
      const id = `choice-task-${this.stage.blockId}`;
      return this.getAnswerData[id].answers;
    },

    versionData() {
      return this.stage.fields.versionData;
    },

    getCurrentValue() {
      return parseInt(this.currentValue);
    },

    linear() {
      return this.stage.fields.linearPropertyValues;
    },

    attributeData() {
      return this.stageProperties;
    },

    currentComponent() {
      return this.skipChoiceTask ? 'ChoiceTaskSliderInputDisabled' : 'ChoiceTaskSliderInputEnabled';
    },

    optionCardAttributes() {
      return optionCardProperties(this.restrictiveAttributeData)
    },

    restrictiveAttributeData() {
      return restrictiveProperties(this.stageProperties);
    },

    showLabels() {
      return this.sliderMinLabel || this.sliderMaxLabel;
    },

    getSliderRatio() {
      return this.sliderRatio;
    },

    showMarker() {
      return !this.skipChoiceTask;
    },

    showToolTip() {
      return !this.skipChoiceTask && this.getConnectedAttribute;
    },

    hideToolTipDownStyle() {
      return this.toolTipDown ? { opacity: 1 } : { opacity: 0 };
    },

    sliderMinLabel() {
      if (this.getConnectedAttribute) {
        return false;
      }

      const label = this.option.min_label;
      if (label && Object.values(label).includes(null)) {
        return false;
      }

      return label;
    },

    sliderMaxLabel() {
      if (this.getConnectedAttribute) {
        return false;
      }

      const label = this.option.max_label;
      if (label && Object.values(label).includes(null)) {
        return false;
      }

      return label;
    },

    getCurrentValueDisplay() {
      if (this.linear) {
        if (this.isSymmetricalSlider) {
          const valueConverted = this.convertToSymmetricalValue();
          return valueConverted * this.getConnectedAttributeValue;
        }

        return this.sliderRatio * this.getConnectedAttributeValue;
      } else {
        return this.getConnectedAttributeValue;
      }
    },

    getConnectedAttribute() {
      if (!this.stageProperties) {
        return false;
      }

      const propertyId = this.option.property_id;
      const selectedAttribute = this.stageProperties.find(
        (data) => data.id === propertyId
      );

      return selectedAttribute === undefined ? false : selectedAttribute;
    },

    getConnectedAttributeValue() {
      if (!this.getConnectedAttribute || !this.getConnectedAttribute.values) {
        return null;
      }

      if (this.linear) {
        const currentAttribute = this.getConnectedAttribute.values.find(
          ({ id }) => id === this.option.id
        );

        if (currentAttribute && currentAttribute.hasOwnProperty('value')) {
          return currentAttribute.value;
        }
      } else {
        if (this.versionData !== undefined && this.versionData[this.option.id] !== undefined && this.versionData[this.option.id][this.getConnectedAttribute.id] !== undefined) {
          const values = this.versionData[this.option.id][this.getConnectedAttribute.id];
          return values[this.sliderStep] ? values[this.sliderStep] : 0;
        }
      }

      return null;
    },
  },

  watch: {
    currentValue(value) {
      this.sliderRatio = value / this.maxSliderValue;
    },

    skipChoiceTask(bool) {
      if (bool) {
        this.sliderLoaded = false;
        return;
      }

      this.resetVariables();
    },

    sliderElementWidth() {
      this.triggerUpdateSliderToolTipWidth();
    },
  },

  created() {
    this.resetVariables();
    this.restoreUserDataValues();
    window.addEventListener('resize', this.handleResize);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    maxValue() {
      if (this.isSymmetricalSlider) {
        return this.option.step_size * 2;
      }

      return this.option.step_size;
    },

    resetVariables() {
      this.currentValue = 0;

      // minSliderValue is always 0
      // maxSliderValue is the amount of steps
      this.minSliderValue = 0;
      this.maxSliderValue = this.maxValue();
      this.sliderStep = this.option.step ? this.option.step : 0;

      this.toolTipDown = true;
      this.sliderLoaded = false;
      this.sliderElementWidth = 0;
    },

    triggerUpdateSliderToolTipWidth() {
      // Exit early if we have no connected property for which to show a tooltip.
      if (!this.getConnectedAttribute) {
        return;
      }

      if (this.sliderLoaded) {
        nextTick(() => {
          this.$refs[
            `slider-tooltip-${this.option.id}`
          ].updateSliderToolTipWidth();
          this.$refs[
            `slider-tooltip-down-${this.option.id}`
          ].updateSliderToolTipWidth();
        });
      }
    },

    pointerDownFunction(value) {
      this.setToolTipDown(false);
      this.triggerUpdateSliderToolTipWidth();
    },

    pointerUpFunction(value) {
      this.userHasSelectedWithSlideToMax(parseInt(value));
      this.setToolTipDown(true);
      this.triggerUpdateSliderToolTipWidth();
    },

    touchEndFunction(value) {
      this.userHasSelectedWithSlideToMax(parseInt(value));
      this.triggerUpdateSliderToolTipWidth();
    },

    receiveSliderValue(value) {
      this.currentValue = parseInt(value);

      if (this.isSymmetricalSlider) {
        this.sliderStep = parseInt(value) - this.option.step_size;
      } else {
        this.sliderStep = parseInt(value);
      }

      this.triggerUpdateSliderToolTipWidth();
    },

    setSliderElement() {
      this.sliderElement =
        this.$refs[`slider-tooltip-container-${this.option.id}`];

      if (this.sliderElement) {
        this.sliderElementWidth = this.sliderElement.offsetWidth;

        this.restoreUserDataValues();
      }
      this.sliderLoaded = true;

      this.triggerUpdateSliderToolTipWidth();
    },

    setToolTipDown(bool) {
      this.toolTipDown = bool;
    },

    handleResize() {
      if (this.sliderElement) {
        this.sliderElementWidth = this.sliderElement.offsetWidth;
      }
    },

    restoreUserDataValues() {
      const optionData = this.answerData[this.option.id];

      //  check if the user made a selection before
      if (optionData) {
        this.currentValue = this.isSymmetricalSlider
          ? this.convertFromSymmetricalValue()
          : optionData.quantity * this.maxSliderValue;

        this.sliderRatio = this.isSymmetricalSlider ? this.convertRatioFromSymmetrical() : optionData.quantity;

        this.userHasSelectedWithSlideToMax(this.currentValue);
      } else {
        this.sliderRatio = this.isSymmetricalSlider ? 0.5 : 0;
        this.currentValue = this.isSymmetricalSlider ? parseInt(this.maxSliderValue / 2) : 0;
      }
    },

    userHasSelectedWithSlideToMax(value) {
      if (this.skipChoiceTask) {
        return;
      }
      // if (this.isMuted || this.skipChoiceTask) {
      //   return;
      // }

      const answerData = this.getAnswerData[this.stageId].answers;

      let currentStep = value;

      if (this.isSymmetricalSlider) {
        currentStep = currentStep - this.option.step_size;
      }

      this.sliderStep = currentStep;
      const valueConverted = this.isSymmetricalSlider
        ? this.convertToSymmetricalValue()
        : value / this.maxSliderValue;

      const data = {...this.option}
      data.quantity = valueConverted;
      data.step = currentStep;
      data.skipped = this.skipChoiceTask;

      answerData[data.id] = data;

      const payload = {
        stageId: this.stageId,
        data: answerData
      };

      this.setAnswerData(payload);
      this.calculateOptionEffects();
      this.processConditions();
    },

    convertRatioFromSymmetrical() {
      const optionData = this.answerData[this.option.id];

      if (optionData.quantity === 0) {
        return 0.5;
      }

      return this.currentValue / this.maxSliderValue;
    },

    convertFromSymmetricalValue() {
      const optionData = this.answerData[this.option.id];

      let value;
      //  conversion from quantity -> ratio the slider uses
      const halfValue = this.maxSliderValue / 2;
      let ratio = optionData.quantity;
      let newValue = null;

      //  slider position should be on the left side
      if (Math.sign(ratio) === -1) {
        ratio = ratio * -1;
        newValue = ratio * halfValue;
        value = halfValue - newValue;
      } else if (Math.sign(ratio) === 1) {
        //  slider position should be on the right side
        newValue = ratio * halfValue;
        value = newValue + halfValue;
      } else {
        //  setup slider position in the neutral position
        value = this.maxSliderValue / 2;
      }

      return parseInt(value);
    },

    convertToSymmetricalValue() {
      let valueConverted;
      let newValue;
      let ratio;

      const value = this.currentValue;
      const halfValue = this.maxSliderValue / 2;

      if (value < halfValue) {
        newValue = halfValue - value;
        ratio = newValue / halfValue;
        valueConverted = ratio * -1;
      } else if (value > halfValue) {
        newValue = value - halfValue;
        ratio = newValue / halfValue;
        valueConverted = ratio;
      } else {
        valueConverted = 0;
      }

      return valueConverted;
    },
  },
});
</script>

<style scoped>
.slider-start-marker {
  width: 0px;
  height: 8px;
  border-left: solid 2px rgba(143, 150, 156, 1);
}

.slider-start-marker-normal {
  top: -6px;
}

.slider-start-marker-symmetrical {
  top: 2px;
}

.slider-display-wrapper {
  min-height: 20px;
}

.slider-display-mobile-wrapper {
  margin-top: 20px;
  display: inline-block;
}

.slider-tooltip-mobile {
  width: fit-content;
  white-space: nowrap;
  color: #0e4876;
  font-weight: normal;
  font-size: 16px;
  pointer-events: none;
}

.slider-display-mobile-wrapper {
  transition: opacity 0.2s ease-out;
}

@media (min-width: 576px) {
  .slider-display-mobile-wrapper {
    display: none;
  }
}

.project-card-slider {
  user-select: none;
  background-color: white;
  box-sizing: border-box;
  border-radius: 25px;
  border: 1px solid #e9eff4;
  box-shadow: 0 1px 25px rgba(212, 212, 212, 0.17);
  transition: all 0.3s ease-out;
  min-height: 92px;
  width: 100%;
}
</style>

<style>
.slider-tooltip-mobile > span.avc-soft-property-icons > svg.soft-property-icon {
  font-size: 16px;
}
</style>
