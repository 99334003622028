<template>
  <div class="social-media-button-wrapper d-flex flex-column">
    <button
      id="social-media-button-whatsapp"
      class="social-media-button"
      @click="shareConsultationFunction()"
    >
      <font-awesome-layers class="h-100 w-100">
        <font-awesome-icon
          class="h-100 w-100"
          icon="square"
          :style="{ color: buttonColor }"
        />
        <font-awesome-icon
          v-if="showNonSocialMediaButton"
          class="social-media-button-icons"
          :icon="icon"
        />
        <font-awesome-icon
          v-else
          class="social-media-button-icons"
          :icon="['fab', icon]"
        />
      </font-awesome-layers>
    </button>
    <div class="social-media-button-title-wrapper d-flex justify-content-center">
      <p class="social-media-button-title">
        {{ name }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import { useOutroStore } from '../stores/useOutroStore';

export default defineComponent({
  props: {
    type: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    buttonColor: {
      required: true,
      type: String,
    },
    icon: {
      required: true,
      type: String,
    },
    shareUrl: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapState(useOutroStore, [
      'getUrlToShare'
    ]),

    showNonSocialMediaButton() {
      return this.type === 'EMAIL'
    },
  },

  methods: {
    shareConsultationFunction() {
      if (this.type === 'EMAIL') {
        this.shareToMailFunction();
        return;
      }

      this.shareToSocialFunction();
    },

    shareToMailFunction() {
      const message = this.getUrlToShare;
      window.location.href = `mailto:?body=${encodeURIComponent(message)}`;
    },

    shareToSocialFunction() {
      const fullUrl = this.shareUrl + encodeURIComponent(this.getUrlToShare);
      window.open(fullUrl, '_blank');
    },
  },
});
</script>

<style scoped>
.social-media-button {
  width: 65px;
  height: 65px;
  border-style: none;
  outline-style: none;
  background-color: white;
  transition: transform var(--btn-hover-zoom-transition-time);
}

.social-media-button:hover {
  transform: scale(var(--btn-hover-zoom-scale));
}

.social-media-button-icons {
  color: white;
  font-size: 2.2rem;
}

.social-media-button-title {
  font-weight: 400;
  font-size: 14px;
}
</style>
