export const nl = {
  appName: "Wevaluate",
  help: "Help",
  close: "Sluiten",
  optionInfoButton: "Toelichting: {optionTitle}",
  privacyStatement: "Privacyverklaring",
  accessibility_info: {
    accessibility: "Toegankelijkheid",
    title: "Toegankelijkheid van deze raadpleging"
  }
}
