import { Condition } from "../../types/Condition";
import { projectConditionShouldApply } from "./projectConditionShouldApply";

export function checkProject(condition: Condition, answers) {
  let projectAnswerData = false;

  for (const [stageId, answerData] of Object.entries(answers)) {
    if (!stageId.includes('choice-task')) {
      continue;
    }

    if (answerData.answers[condition.conditional_id]) {
      projectAnswerData = answerData.answers[condition.conditional_id];
      break;
    }
  }

  if (!projectAnswerData) {
    return false;
  }

  return projectConditionShouldApply(condition, projectAnswerData);
}
