import { defineStore } from 'pinia';

declare global {
  interface Window { _paq: { push: (data: any[]) => {} }; }
}

interface State {
  referrerUrl: string | null;
}

export const useAnalyticsStore = defineStore('AnalyticsStore', {
  state: (): State => ({
    referrerUrl: null,
  }),

  getters: {
    getReferrerUrl: (state) => state.referrerUrl,
  },

  actions: {
    pushEvent(payload: any[]) {
      if (typeof window._paq !== 'undefined') {
        window._paq.push(payload);
      }
    },

    trackUserAction(payload: any[]) {
      const data = [
        'trackEvent',
        'User action',
        ...payload,
      ];

      this.pushEvent(data);
    },

    trackSystemAction(payload: any[]) {
      const data = [
        'trackEvent',
        'System',
        ...payload,
      ];

      this.pushEvent(data);
    },

    trackDimension(payload: any[]) {
      const data = [
        'setCustomDimension',
        ...payload,
      ];

      this.pushEvent(data);
    },

    trackPageView(data: any) {
      if (this.getReferrerUrl) {
        this.pushEvent(['setReferrerUrl', this.getReferrerUrl]);
      }

      const slug = data.slug;
      const segmentId = data.segmentId;
      const segmentType = data.segmentType;
      const stageId = data.stageId;
      const sequenceNumber = data.sequenceNumber;

      const referrerUrl = `/${slug}/${sequenceNumber}/${segmentType}/${segmentId}/${stageId}`;
      this.pushEvent(['setCustomUrl', referrerUrl]);
      this.referrerUrl = referrerUrl;

      const title = `${slug}/${segmentType}/stage: ${sequenceNumber}/blockId: ${segmentId}/page: ${stageId}`;
      this.pushEvent(['setDocumentTitle', title]);

      this.pushEvent(['trackPageView']);
      this.pushEvent(['MediaAnalytics::scanForMedia']);
      this.pushEvent(['FormAnalytics::scanForForms']);
      this.pushEvent(['trackContentImpressionsWithinNode']);
    },
  },
});
