<template>
  <div
    class="mb-4"
    style="width: 100%"
  >
    <QuestionTitle
      :question-title="questionTitle"
      :is-required="isRequired"
      :current-number="currentQuestionDisplayNumber"
      :total-number="totalQuestionNumber"
    />
    <div
      class="container-fluid row flex-nowrap"
      style="width: 100%"
    >
      <div class="col-6 radio-ab-border-right">
        <div class="row">
          <div class="col radio-ab-option-title-wrapper ps-0">
            <label class="radio-ab-option-title question-title mt-0">
              <LanguageSelector :to-be-selected="answerOptionA" />
            </label>
          </div>
        </div>
        <div class="row mb-2">
          <div class="radio-ab-option-image-wrapper">
            <img
              v-if="mediaOptionA"
              :src="mediaOptionA"
              class="radio-ab-option-image"
            >
          </div>
        </div>
        <div class="row ps-0 ms-0">
          <span class="radio-ab-short-description">
            <LanguageSelector
              :to-be-selected="shortDescriptionOptionA"
              class=""
            />
          </span>
        </div>
      </div>
      <div class="col-6 radio-ab-border-left">
        <div class="row">
          <div class="col radio-ab-option-title-wrapper ps-0">
            <label class="radio-ab-option-title question-title mt-0">
              <LanguageSelector :to-be-selected="answerOptionB" />
            </label>
          </div>
        </div>
        <div class="row mb-2">
          <div class="radio-ab-option-image-wrapper">
            <img
              v-if="mediaOptionB"
              :src="mediaOptionB"
              class="radio-ab-option-image"
            >
          </div>
        </div>
        <div class="row ps-0 ms-0">
          <span>
            <LanguageSelector
              :to-be-selected="shortDescriptionOptionB"
              class="radio-ab-short-description"
            />
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6 pt-4 radio-ab-border-right">
        <div
          class="radio-ab-option-button-wrapper"
          style="width: 100%; height: 100%"
        >
          <div
            :style="borderColor"
            class="radio-answer-option row flex-nowrap align-items-center ps-0 pt-2 pb-2 question-border"
            @click="userHasChosenAnswer(0)"
          >
            <div class="col-1">
              <input
                :name="currentQuestionData.id"
                :value="answerOptionA"
                :checked="checkedArray[0]"
                class="radio-answer-pseudo-button"
                type="radio"
              >

              <div
                class="radio-answer-button d-flex justify-content-center align-items-center"
              >
                <span
                  :class="{ 'radio-button-active': checkedArray[0] === true }"
                  class="radio-answer-button-inner-circle"
                />
              </div>
            </div>
            <label
              class="answer-option col align-items-center"
              style="cursor: pointer"
            >
              <LanguageSelector :to-be-selected="answerOptionA" />
            </label>
          </div>
        </div>
      </div>
      <div class="col-6 pt-4 radio-ab-border-left">
        <div
          class="radio-ab-option-button-wrapper"
          style="width: 100%; height: 100%"
        >
          <div
            :style="borderColor"
            class="radio-answer-option row flex-nowrap align-items-center ps-0 pt-2 pb-2 question-border"
            @click="userHasChosenAnswer(1)"
          >
            <div class="col-1">
              <input
                :name="currentQuestionData.id"
                :value="answerOptionB"
                :checked="checkedArray[1]"
                class="radio-answer-pseudo-button"
                type="radio"
              >

              <div
                class="radio-answer-button d-flex justify-content-center align-items-center"
              >
                <span
                  :class="{ 'radio-button-active': checkedArray[1] === true }"
                  class="radio-answer-button-inner-circle"
                />
              </div>
            </div>
            <label
              class="answer-option col"
              style="cursor: pointer"
            >
              <LanguageSelector :to-be-selected="answerOptionB" />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div
      :style="textOpacity"
      class="alert-required-message my-2"
    >
      {{ $t('pagination.input_validation_no_selection_message') }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import QuestionTitle from './QuestionTitle.vue';
import { mapState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    QuestionTitle,
  },
  props: {
    currentQuestionData: {
      type: Object,
      required: true,
    },
    showUserInputIsRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentQuestionNumber: {
      type: Number,
      required: true,
    },
    currentQuestionDisplayNumber: {
      type: Number,
      required: true,
    },
    totalQuestionNumber: {
      type: Number,
      required: true,
    },
    currentUserData: {
      type: Object,
      required: true,
    },
  },

  emits: ['sendCurrentUserData'],

  data() {
    return {
      answerData: {},
      checkedArray: [false, false],
    };
  },

  computed: {
    ...mapState(useConsultationStore, [
      'locale',
    ]),

    questionTitle() {
      return this.currentQuestionData.title;
    },

    answerOptions() {
      if (!this.currentQuestionData.answer_options) {
        return [];
      }

      return this.currentQuestionData.answer_options;
    },

    answerOptionA() {
      return this.answerOptions[0].title;
    },

    answerOptionB() {
      return this.answerOptions[1].title;
    },

    mediaOptionA() {
      return this.resolveMediaSource(this.answerOptions[0].media);
    },

    mediaOptionB() {
      return this.resolveMediaSource(this.answerOptions[1].media);
    },

    shortDescriptionOptionA() {
      return this.answerOptions[0].short_description;
    },

    shortDescriptionOptionB() {
      return this.answerOptions[1].short_description;
    },

    isChecked() {
      return this.checkedArray;
    },

    isRequired() {
      return this.currentQuestionData.required;
    },

    borderColor() {
      return this.showUserInputIsRequired
        ? { borderColor: '#D40005' }
        : { borderColor: '#E6E6E6' };
    },

    textOpacity() {
      return this.showUserInputIsRequired ? { opacity: '1' } : { opacity: '0' };
    },
  },

  created() {
    const answerData = this.currentUserData ? { ...this.currentUserData } : {};

    if (answerData.user_has_answered === true) {
      this.checkedArray = answerData.raw_input_radio_ab;
    } else {
      answerData.user_has_answered = false;
      answerData.user_answered_string = null;
      answerData.raw_input_radio_ab = null;
    }

    answerData.id = this.currentQuestionData.id;
    answerData.title = this.questionTitle;
    answerData.required = this.isRequired;
    answerData.field_type = this.currentQuestionData.field_type;
    answerData.question_number = this.currentQuestionNumber;
    answerData.answer_options = this.answerOptions;

    this.answerData = answerData;

    this.$emit('sendCurrentUserData', this.answerData);
  },

  methods: {
    userHasChosenAnswer(index) {
      for (let i = 0; i < this.checkedArray.length; i++) {
        this.checkedArray[i] = false;
      }

      this.checkedArray[index] = true;

      const value = this.answerOptions[index].title[this.locale];

      this.answerData.user_answered_string = value;
      this.answerData.raw_input_radio_ab = this.checkedArray;
      this.answerData.user_has_answered = true;
      this.answerData.answer_id = this.answerOptions[index].id;

      this.$emit('sendCurrentUserData', this.answerData);
      this.alertUser = false;
    },

    resolveMediaSource(media) {
      if (typeof media === 'string') {
        return media;
      }

      if (media[this.locale] !== undefined) {
        return media[this.locale];
      }

      const firstLanguage = Object.keys(media)[0];

      return media[firstLanguage];
    },
  },
});
</script>

<style scoped>
.row {
  padding: 0;
  margin: 0;
}

.radio-ab-border-right {
  border-right-style: solid;
  border-right-width: 1px;
  padding: 0 1rem 0 0;
}

@media (min-width: 1000px) {
  .radio-ab-border-right {
    padding: 0 3rem 0 0;
  }
}

.radio-ab-border-left {
  border-left-style: solid;
  border-left-width: 1px;
  padding: 0 0 0 1rem;
}

@media (min-width: 1000px) {
  .radio-ab-border-left {
    padding: 0 0 0 3rem;
  }
}

.radio-answer-options-ab {
  width: 100%;
  height: 100%;
  max-width: 800px;
  padding-left: 0;
  margin-left: 0;
}

.radio-answer-option-ab {
  cursor: pointer;
  position: relative;
  height: 100%;
  min-height: 45px;
  border-style: none;
  background-color: white;
  box-sizing: border-box;
  border-radius: 25px;
  box-shadow: 0 1px 25px rgba(212, 212, 212, 0.17);
  padding: 1rem;
  margin: 0.5em 0.25rem;
  white-space: nowrap;
}

.radio-answer-option-ab:hover {
  /*	background-color: #E7EAEC;*/
}

.radio-answer-pseudo-button {
  position: absolute;
  opacity: 0;
  display: none;
}

img.radio-ab-option-image {
  width: 100%;
  height: 100%;
  /*max-height: 230px;*/
  /*object-fit: cover;*/
}

.user-completed-task-message {
  color: #afe754;
}
</style>

<style>
.radio-ab-short-description ul {
  list-style-position: outside;
  padding-left: 1rem;
}
</style>
