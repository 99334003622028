<template>
  <div
    class="selection-panel-wrapper d-flex justify-content-center flex-nowrap stay-ltr"
  >
    <div class="mini-delete-button-wrapper d-flex align-items-center ms-auto">
      <font-awesome-layers
        :class="{ 'disabled-button': disabledDecreaseButton }"
        class="fa-lg mini-add-button-circle button-hover-animation button-color-transition"
        @click="functionMiniDeleteButton()"
      >
        <font-awesome-icon icon="circle" />
        <font-awesome-icon
          :style="{ color: 'white' }"
          icon="minus"
          transform="shrink-6"
        />
      </font-awesome-layers>
    </div>
    <div
      class="selection-display-wrapper d-flex align-items-center p-0 ms-2 me-2"
    >
      <input
        :ref="'input' + option.id"
        :value="amountOfPoints"
        :placeholder="valuePoints"
        class="selection-display-value question-border"
        type="number"
        min="0"
        :disabled="skipChoiceTask"
        @blur="(e) => functionInputValue(e)"
      >
    </div>
    <div class="mini-add-button-wrapper d-flex align-items-center">
      <font-awesome-layers
        :class="{ 'disabled-button': disabledIncreaseButton }"
        class="fa-lg mini-add-button-circle button-hover-animation button-color-transition"
        @click="functionMiniAddButton()"
      >
        <font-awesome-icon icon="circle" />
        <font-awesome-icon
          :style="{ color: 'white' }"
          icon="plus"
          transform="shrink-6"
        />
      </font-awesome-layers>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { isNumeric } from '../consultation/Conditions/isNumeric';
import { mapActions, mapState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  props: {
    option: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      amountOfPoints: 0,
    };
  },

  computed: {
    ...mapState(useConsultationStore, [
      'stageId',
      'stagePointsData',
      'getAnswerData',
      'skipChoiceTask',
      'totalSpendPoints',
    ]),

    pointsTarget() {
      return this.stagePointsData.points_target;
    },

    valuePoints() {
      return this.amountOfPoints;
    },

    currentSpendPointsOnOption() {
      const answerData = this.getAnswerData[this.stageId].answers;
      return answerData[this.option.id].quantity;
    },

    pointsComplete() {
      return this.totalSpendPoints >= this.pointsTarget;
    },

    disabledDecreaseButton() {
      return this.skipChoiceTask || this.amountOfPoints === 0;
    },

    disabledIncreaseButton() {
      return this.skipChoiceTask || this.pointsComplete;
    },
  },

  created() {
    const answerData = this.getAnswerData[this.stageId].answers;

    if (answerData[this.option.id]) {
      this.amountOfPoints = answerData[this.option.id].quantity;
    }
  },

  methods: {
    ...mapActions(useConsultationStore, [
      'setAnswerData',
      'calculateOptionEffects',
    ]),

    userHasSelected(points) {
      const answerData = this.getAnswerData[this.stageId].answers;

      const data = {...this.option}
      data.quantity = points;
      data.skipped = this.skipChoiceTask;

      answerData[data.id] = data;

      const payload = {
        stageId: this.stageId,
        data: answerData
      };

      this.amountOfPoints = points;
      this.$refs['input' + this.option.id].value = points;
      this.setAnswerData(payload);
      this.calculateOptionEffects();
    },

    functionMiniDeleteButton() {
      if (this.skipChoiceTask) {
        return;
      }

      if (this.amountOfPoints > 0) {
        const points = this.amountOfPoints - 1;
        this.userHasSelected(points);
      }
    },

    functionInputValue(event) {
      let value = event.target.value;

      if (value === 'string' && value.length === 0) {
        value = 0;
      } else if (!isNumeric(value)) {
        value = this.amountOfPoints;
      }

      value = parseInt(value);

      const sum = (this.totalSpendPoints - this.amountOfPoints) + value;

      if (sum > this.pointsTarget) {
        const pointsWithoutCurrent = this.totalSpendPoints - this.currentSpendPointsOnOption;
        value = this.pointsTarget - pointsWithoutCurrent;
      }

      this.userHasSelected(value);
    },

    functionMiniAddButton() {
      if (this.skipChoiceTask) {
        return;
      }

      if (this.totalSpendPoints < this.pointsTarget) {
        const points = this.amountOfPoints + 1;
        this.userHasSelected(points);
      }
    },
  },
});
</script>

<style scoped>
.selection-panel-wrapper {
  height: fit-content;
}

.button-color-transition {
  transition: color 0.3s ease-out;
}

.selection-display-value {
  display: inline-block;
  border-style: solid;
  border-color: #e9eff4;
  border-width: 1px;
  position: relative;
  text-align: center;
  padding: 1px 4px;
  font-size: 1.2rem;
  resize: none;
  width: 100%;
}

.mini-add-button-circle {
  user-select: none;
  position: relative;
  font-size: 25px;
  color: #205d86;
  border-radius: 50%;
  display: inline-block;
  z-index: 0;
  cursor: pointer;
}

.disabled-button {
  color: #b7b7b7;
  cursor: not-allowed !important;
  transition-property: none !important;
}
</style>
