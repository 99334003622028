<template>
  <ChoiceTaskPickModeButton
    :option="option"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ChoiceTaskPickModeButton from './ChoiceTaskPickModeButton.vue';

export default defineComponent({
  components: {
    ChoiceTaskPickModeButton,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
});
</script>
