import { Condition } from "../../types/Condition";
import { compareAnswerToCondition } from "./compareAnswerToCondition";
import { getQuestionAnswer } from "./getQuestionAnswer";

export function questionConditionShouldApply(condition: Condition, answerData): boolean {
  if (
    condition.conditional_id === answerData.id &&
    answerData.user_has_answered === false
  ) {
    return false;
  }

  if (condition.answer_id && answerData.answer_id) {
    if (Array.isArray(answerData.answer_id)) {
      return answerData.answer_id.includes(condition.answer_id);
    }

    return answerData.answer_id === condition.answer_id;
  }

  const answer = getQuestionAnswer(condition, answerData);

  if (answer === false) {
    return false;
  }

  return compareAnswerToCondition(condition, answer);
};
