<template>
  <div>
    <BaseDialog
      :show="showConfirm"
      :title="title"
      :single-button="false"
      :icons="false"
      :type-of-confirm="typeOfConfirm"
      @confirm-event="confirmSubmit"
      @my-event="closeConfirmWindow"
    >
      <span v-show="message"><LanguageSelector :to-be-selected="message" /></span>
    </BaseDialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useAnalyticsStore } from '../stores/useAnalyticsStore';
import { mapState, mapWritableState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';
import BaseDialog from './BaseDialog.vue';

export default defineComponent({
  components: {
    BaseDialog,
  },

  props: {
    resetOpenState: {
      type: Function,
      required: true,
    },
  },

  setup() {
    const analyticsStore = useAnalyticsStore();
    const consultationStore = useConsultationStore();
    return { analyticsStore, consultationStore };
  },

  computed: {
    ...mapWritableState(useConsultationStore, [
      'showConfirm',
    ]),

    ...mapState(useConsultationStore, [
      'confirmData',
      'getBlockId',
      'getSequenceNumber',
    ]),

    title() {
      if (this.confirmData.hasOwnProperty('title')) {
        return this.confirmData.title;
      }

      return false;
    },

    message() {
      if (this.confirmData.hasOwnProperty('message')) {
        return this.confirmData.message;
      }

      return false;
    },

    typeOfConfirm() {
      return this.confirmData.typeOfConfirm;
    },
  },

  methods: {
    confirmSubmit() {
      //  no need to route to navigation.js for the function
      //  user just wants to leave the application and go back to the previous URL
      if (this.typeOfConfirm === 'confirmUserLeaves') {
        this.analyticsStore.trackUserAction([
          'Confirm stop consultation',
          this.getBlockId,
          this.getSequenceNumber,
        ])
        window.history.back();
        this.showConfirm = false;
      } else {
        this.consultationStore.functionConfirmButtonDialog();
        this.showConfirm = false;
        this.resetOpenState();
      }
    },

    closeConfirmWindow() {
      //  when the user pressed the browser back button but doesn't want to leave the application the history state needs to be updated
      //  otherwise the next time the user presses the browser back button he won't get a warning
      if (this.typeOfConfirm === 'confirmUserLeaves') {
        this.showConfirm = false;
        this.resetOpenState();
      } else {
        this.showConfirm = false;
      }
    },
  },
});
</script>
