<template>
  <ChoiceTaskPointsModeInput
    :option="option"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ChoiceTaskPointsModeInput from './ChoiceTaskPointsModeInput.vue';

export default defineComponent({
  components: {
    ChoiceTaskPointsModeInput,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
});
</script>
