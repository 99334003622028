<template>
  <div
    class="project-card position-relative d-flex flex-nowrap flex-column justify-content-between p-0"
  >
    <div class="project-card-top-section h-100 p-3 pb-2 d-flex flex-column">
      <slot name="top-section" />
    </div>
    <div
      class="project-card-bottom-section h-100 p-3 pt-2 d-flex flex-row flex-nowrap"
    >
      <slot name="bottom-section" />
    </div>
    <slot name="overlay-section" />
  </div>
</template>

<style scoped>
.project-card {
  user-select: none;
  background-color: white;
  box-sizing: border-box;
  border-radius: 25px;
  border: 1px solid #e9eff4;
  box-shadow: 0 1px 25px rgba(212, 212, 212, 0.17);
  transition: all 0.3s ease-out;
  min-height: 200px;
  width: 300px;
}
</style>
