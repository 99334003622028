import axios, { AxiosInstance, AxiosRequestConfig, Method } from 'axios';

export class HttpClient {
  protected http: AxiosInstance;

  constructor(config: AxiosRequestConfig) {
    this.http = axios.create(config);
  }

  public request(method: Method, path: string, data: object) {
    return this.http.request({ method, url: path, data });
  }

  public get(path: string, params: object) {
    return this.http.get(path, { params });
  }

  public post(path: string, data: object) {
    return this.http.post(path, data);
  }

  public put(path: string, data: object) {
    return this.http.put(path, data);
  }

  public delete(path: string) {
    return this.http.delete(path);
  }
}
