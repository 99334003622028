import { ConsultationData } from "../../../types/ConsultationData";
import { InstanceData } from "../../../types/InstanceData";
import { PrimaryBlockType } from "../../../types/PrimaryBlockType";
import { StageLanguageSelection } from "../../../types/StageLanguageSelection";
import { ChoiceTaskAdapter } from "./ChoiceTaskAdapter";
import { ConsentAdapter } from "./ConsentAdapter";
import { DiscreteChoiceAdapter } from "./DiscreteChoiceAdapter";
import { IntroAdapter } from "./IntroAdapter";
import { OutroAdapter } from "./OutroAdapter";
import { SurveyAdapter } from "./SurveyAdapter";

export function InstanceAdapter(data: InstanceData): ConsultationData {
  const segments = data.segments || [];
  delete data.segments;
  const result: ConsultationData = { ...data, stages: [] };

  if (Object.keys(data.settings.locales).length > 1) {
    result.stages.push(languageSelectionStage(data));
  }

  for (const segment of segments) {
    const Adapter = resolveAdapter(segment.block_type);

    if (Adapter === null) {
      continue;
    }

    const stages = Adapter(segment as any);
    result.stages.push(...stages);
  }

  return result
}

function resolveAdapter(type: PrimaryBlockType): Function | never {
  switch (type) {
    case 'consent':
      return ConsentAdapter;
    case 'intro':
      return IntroAdapter;
    case 'outro':
      return OutroAdapter;
    case 'questions':
      return SurveyAdapter;
    case 'research':
      return ChoiceTaskAdapter;
    case 'dce':
      return DiscreteChoiceAdapter;
    default:
      // Sentry capture: new UnknownBlockTypeError(type);
      return null;
  }
}

function languageSelectionStage(data: InstanceData): StageLanguageSelection {
  return {
    id: 'language-selection',
    stageType: 'language-selection',
    blockId: 0,
    blockType: 'language_selection',
    component: 'SegmentLanguageSelection',
    hidden: false,
    fields: {
      default: data.settings.default_locale,
      options: data.settings.locales,
    },
  };
}
