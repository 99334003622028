<script setup lang="ts">
import { computed } from 'vue';
import ChoiceTaskSliderModeCard from './ChoiceTaskSliderModeCard.vue';
import { useConsultationStore } from '../stores/useConsultationStore';

const consultationStore = useConsultationStore();
const stageOptions = computed(() => consultationStore.stageOptions);
</script>

<template>
  <transition-group
    class="row me-0 mt-2 ms-0 p-0"
    name="flip-list"
    tag="div"
  >
    <ChoiceTaskSliderModeCard
      v-for="option in stageOptions"
      :key="option.id"
      :option="option"
    />
  </transition-group>
</template>

<style>
.flip-list-move {
  transition: transform 0.7s;
}
</style>
