import { Option } from "../../types/Option";
import { Property } from "../../types/Property";
import { StageChoiceTask } from "../../types/StageChoiceTask";

export function calculateMultipliedProjectValue(
  property: Property,
  selectedOptions: Option[],
  stage: StageChoiceTask
) {
  let value = property.initial_value || 0;
  const linear = stage.fields.linearPropertyValues;
  const versionData = stage.fields.versionData;

  if (versionData === undefined) {
    return value;
  }

  const versionValues = [];

  if (linear) {
    selectedOptions.forEach((option: Option) => {
      const propertyValue = property.values.find(({ id }) => id === option.id);

      if (propertyValue === undefined) {
        return;
      }

      versionValues.push(propertyValue.value * option.quantity);
    });
  } else {
    selectedOptions.forEach((option: Option) => {
      if (versionData[option.id] === undefined || versionData[option.id][property.id] === undefined) {
        return;
      }

      const attributeValues = versionData[option.id][property.id];
      let currentValue = undefined;

      if (stage.fields.mode === 'slide_to_max') {
        currentValue = attributeValues[option.step];
      } else {
        currentValue = attributeValues[option.quantity];
      }

      if (currentValue !== undefined) {
        versionValues.push(currentValue);
      }
    });
  }

  value = versionValues.reduce((a, b) => a * b, 1)

  return value;
}
