import { Option } from "../../types/Option";
import { Property } from "../../types/Property";

export function getSortedOptions(options: Option[], sortingPropertyId: number, properties: Property[]): Option[] {
  const sortingProperty = properties.find(({ id }) => id === sortingPropertyId);

  if (!sortingProperty) {
    return options;
  }

  const sortValues = sortingProperty.values;

  // filter out options that don't have an property value assigned to it's corresponding id
  const noAssignedValueOptions = options.filter((option) => {
    const searchedOption = sortValues.find(({ id }) => id === option.id);
    if (!searchedOption) {
      return option;
    }
  });

  // filter out projects that do have an property value assigned
  const toSortProjects = options.filter((option) => {
    const searchedProject = sortValues.find(({ id }) => id === option.id);
    if (searchedProject) {
      return option;
    }
  });

  // // sort the sortable options
  const sortedOptions = toSortProjects.sort((optionA, optionB) => {
    const optionAValue = sortValues.find(
      ({ id }) => id === optionA.id
    ).value;
    const optionBValue = sortValues.find(
      ({ id }) => id === optionB.id
    ).value;

    return optionAValue - optionBValue;
  });

  // return the sorted array with the non assigned value projects as 1 array again
  return [...noAssignedValueOptions, ...sortedOptions];
}
