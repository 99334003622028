<template>
  <div class="navigation-button-next-mobile-wrapper">
    <div
      id="navigation-back col"
      class="navigation-back"
    >
      <button
        v-show="canNavigateBack"
        class="navigation-button navigation-button-back"
        @click="functionBack()"
      >
        <font-awesome-icon
          class="navigation-arrows navigation-arrow-back me-2"
          icon="arrow-left"
        />
        <span class="navigation-labels navigation-label-back">
          {{ $t('pagination.back') }}
        </span>
      </button>
    </div>
    <div class="navigation-next col">
      <button
        class="navigation-button"
        :class="{
          'navigation-button-next-disabled': nextDisabled,
          'navigation-button-next': !nextDisabled,
        }"
        @click="functionNext()"
      >
        <span class="navigation-labels navigation-label-next me-2">
          {{ $t('pagination.next') }}
        </span>
        <font-awesome-icon
          class="navigation-arrows navigation-arrow-next"
          icon="arrow-right"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useConsultationStore } from '../stores/useConsultationStore';
import { mapActions, mapState } from 'pinia';

export default defineComponent({
  props: {
    submitFunction: {
      type: Function,
      required: false,
      default: undefined,
    },
  },
  computed: {
    ...mapState(useConsultationStore, [
      'canNavigateBack',
      'canNavigateForward',
      'nextDisabled',
    ]),
  },
  methods: {
    ...mapActions(useConsultationStore, [
      'back',
      'next',
    ]),

    functionBack() {
      this.back();
    },

    functionNext() {
      if (this.submitFunction) {
        const result = this.submitFunction();

        if (result === false) {
          return;
        }
      }

      this.next();
    },
  },
});
</script>

<style scoped>
.navigation-button-next-mobile-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  display: grid;
  height: 56px;
  grid-template-columns: 1fr 1fr;
  background-color: lightgrey;
}

.navigation-next .navigation-button.navigation-button-next,
.navigation-next .navigation-button.navigation-button-next-disabled,
.navigation-back .navigation-button.navigation-button-back,
.navigation-back .navigation-button.navigation-button-back-disabled {
  width: 100%;
  border-radius: 0px;
  height: 56px;
  padding-inline: 1rem;
}

.navigation-next .navigation-button.navigation-button-next,
.navigation-next .navigation-button.navigation-button-next-disabled {
  justify-content: end;
}

.navigation-back .navigation-button.navigation-button-back,
.navigation-back .navigation-button.navigation-button-back-disabled {
  justify-content: start;
}

.navigation-back .navigation-button.navigation-button-back:hover,
.navigation-back .navigation-button.navigation-button-back:active,
.navigation-next .navigation-button.navigation-button-next:hover,
.navigation-next .navigation-button.navigation-button-next:active,
.navigation-back .navigation-button.navigation-button-back {
  color: white;
  background-color: #00a6d6;
  border: none;
}

.navigation-back
  .navigation-button.navigation-button-back
  .navigation-arrow-back,
.navigation-back
  .navigation-button.navigation-button-back-disabled
  .navigation-arrow-back {
  color: white;
}
</style>
