<script lang="ts">
import { defineComponent } from 'vue';
import { h } from 'vue';
import { useConsultationStore } from '../stores/useConsultationStore';
import { mapState } from 'pinia';

export default defineComponent({
  render() {
    const content = this.$attrs.string
      .replace('{{', '<span class="wiki-link" v-pre>{{')
      .replace('}}', '}}</span>');

    const render = {
      name: 'RenderString',
      template: '<div id="string-contents">' + content + '</div>',
      props: {
        string: {
          required: true,
          type: String,
        },
      },
      mounted() {
        const pageLinks = document.querySelectorAll('#string-contents a');

        pageLinks.forEach((link) => {
          link.setAttribute('target', '_blank');
        });
      },
      methods: {
        getWiki(slug) {
          return this.getWikiContent[slug];
        },
      },
      computed: {
        ...mapState(useConsultationStore, [
          'getWikiContent',
        ]),
      },
    };
    return h(render);
  },
});
</script>
