<template>
  <BaseModal
    :show="show"
    :close-function="closeModal"
  >
    <template #header>
      <h1 class="mt-0 mb-3">
        {{ $t('pagination.match_budget_dialog_title') }}
      </h1>
      <p class="dialog-subtitle">
        {{ $t('pagination.match_budget_dialog_message') }}
      </p>
    </template>
    <template #footer>
      <div class="d-flex justify-content-start align-items-center">
        <button
          class="navigation-button navigation-button-back me-4"
          @click="closeModal"
        >
          <font-awesome-icon
            class="navigation-arrows navigation-arrow-back"
            icon="arrow-left"
          />
          <span class="navigation-labels navigation-label-back ms-2">
            {{ $t('pagination.back') }}
          </span>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseModal from './BaseModal.vue';
import { useConsultationStore } from '../stores/useConsultationStore';
import { mapWritableState } from 'pinia';

export default defineComponent({
  components: {
    BaseModal,
  },

  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapWritableState(useConsultationStore, [
      'showPointsLeftModal',
    ])
  },

  methods: {
    closeModal() {
      this.showPointsLeftModal = false;
    },
  },
});
</script>

<style scoped>
.restrictive-attribute-title {
  margin: 0px;
}

.restrictive-attribute-value {
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-dark);
}

.dialog-subtitle {
  font-weight: normal;
}
</style>
