import { Condition, ConditionOperation } from "../../types/Condition";

export function projectConditionShouldApply(condition: Condition, answerData): boolean {
  const quantity = answerData.quantity;
  const match = parseFloat(condition.value);
  const operation: ConditionOperation = condition.operator;

  if (operation === 'equal_to') {
    return quantity === match;
  } else if (operation === 'not_equal_to') {
    return quantity !== match;
  } else if (operation === 'greater_than') {
    return quantity > match;
  } else if (operation === 'lower_than') {
    return quantity < match;
  }

  return false;
};
