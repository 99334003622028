import { AnswerData } from "./AnswerData";

export class ChoiceTaskAnswerData extends AnswerData {
  /**
   * The slug for the choice task form.
   */
  protected endpoint = 'choiceTask';

  public formatData(answers: any) {
    const stageAnswers = answers[`choice-task-${this.blockId}`];
    const selectedProjects = [];
    let skipped = false;

    for (const [id, option] of Object.entries(stageAnswers.answers)) {
      const data = {
        id: option.id,
        quantity: option.quantity,
      }

      if (option.step !== undefined) {
        data.step = option.step;
      }

      if (option.skipped) {
        skipped = true;
      }

      selectedProjects.push(data);
    }

    return {
      blockId: this.blockId,
      answerData: {
        skipped_choice_task: skipped,
        selectedProjects: selectedProjects,
        versionId: stageAnswers.versionId,
        meta: stageAnswers.meta,
      },
    };
  }
}
