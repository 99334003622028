<script setup lang="ts">
import { computed } from 'vue';
import QuestionTitle from './QuestionTitle.vue';

const props = defineProps<{
  question: Record<string, any>;
  currentNumber: number;
  totalNumber: number;
}>();

const questionTitle = computed(() => props.question.title);
const isRequired = computed(() => props.question.required);
</script>

<template>
  <div class="form-group">
    <QuestionTitle
      :question-title="questionTitle"
      :is-required="isRequired"
      :current-number="currentNumber"
      :total-number="totalNumber"
    />

    <slot />
  </div>
</template>
