import { Condition } from "../../types/Condition";
import { questionConditionShouldApply } from "./questionConditionShouldApply";

export function checkQuestions(condition: Condition, answers) {
  const affectorQuestionId = condition.conditional_id;
  let questionAnswerData = false;

  for (const [stageId, answerData] of Object.entries(answers)) {
    if (!stageId.includes('survey')) {
      continue;
    }

    const data = Object.values(answerData.answers);

    const questionAnswer = data.find((q) => {
      if (q.id === affectorQuestionId) {
        return true;
      }

      if (q.field_type === 'matrix') {
        return q.matrix_theses.some((row) => row.id === affectorQuestionId);
      }

      return false;
    });

    if (questionAnswer) {
      questionAnswerData = questionAnswer;
      break;
    }
  }

  if (!questionAnswerData) {
    return false;
  }

  return questionConditionShouldApply(condition, questionAnswerData);
}
