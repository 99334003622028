import { defineStore } from 'pinia';

interface State {
  shareConsultationModalOpen: boolean;
}

export const useOutroStore = defineStore('OutroStore', {
  state: (): State => ({
    shareConsultationModalOpen: false,
  }),

  getters: {
    getShareConsultationModalOpen: ({ shareConsultationModalOpen }) => shareConsultationModalOpen,

    getUrlToShare: () => {
      const url = window.location.origin;
      return url;
    },

    getUrlToCopy: () => {
      const url = window.location.host;
      return `${location.protocol}//${url}`;
    },
  },

  actions: {
    setShareConsultationModalOpen(bool: boolean) {
      this.shareConsultationModalOpen = bool;
    },
  },
});
