<script setup>
import { onMounted } from 'vue';
import SegmentWrapper from '../components/SegmentWrapper.vue';
import { useConsultationStore } from '../stores/useConsultationStore';

// Access the consultation store
const consultationStore = useConsultationStore();
const getDocumentTitle = consultationStore.getDocumentTitle;
const hasFeedback = consultationStore.hasFeedback;

onMounted(() => {
  document.title = getDocumentTitle;

  if (hasFeedback) {
    const jqueryScript = document.createElement('script');
    jqueryScript.setAttribute('src', 'https://code.jquery.com/jquery-3.6.0.min.js');
    jqueryScript.setAttribute('async', 'true');

    jqueryScript.onload = () => {
      const jiraScript = document.createElement('script');
      jiraScript.setAttribute('src', 'https://pve-tudelft.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/sb53l8/b/24/e73395c53c3b10fde2303f4bf74ffbf6/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=nl-nl&collectorId=a5106da7');
      jiraScript.setAttribute('async', 'true');

      jiraScript.onload = () => {
        initializeIssueCollector();
      };

      document.head.appendChild(jiraScript);
    };

    document.head.appendChild(jqueryScript);
  }
});

function initializeIssueCollector() {
  try {
    window.ATL_JQ_PAGE_PROPS = $.extend(window.ATL_JQ_PAGE_PROPS, {
      triggerFunction: function (showCollectorDialog) {
        document.getElementById('feedback-button').addEventListener('click', function (e) {
          e.preventDefault();
          showCollectorDialog();
        });
      },
      // Set the field values
      fieldValues: {
        customfield_10037: window.location.protocol + '//' + window.location.hostname,
      },
    });
  } catch (error) {
    console.error('Error initializing Jira collector:', error);
  }
}
</script>

<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <SegmentWrapper />
  </transition>
</template>


<style scoped>
.fade-enter-active {
  transition: all 1.5s ease;
}

.fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
