<template>
  <div class="p-0 attribute-value-restrictive-container col">
    <div class="m-0 row">
      <div class="p-0 col w-50">
        <div
          class="dashboard-restrictive-attribute-icon-wrapper d-flex justify-content-start"
        >
          <font-awesome-icon
            v-if="restrictiveIcon"
            :style="{ color: '#004876' }"
            :icon="restrictiveIcon"
            class="dashboard-restrictive-attribute-icon mb-2"
          />
        </div>
        <div
          v-show="attributeTitle"
          class="m-0 row"
        >
          <LanguageSelector
            :to-be-selected="attributeTitle"
            class="p-0 m-0 dashboard-restrictive-attribute-titles col d-flex align-items-start"
          />
        </div>
        <div
          v-if="showRestrictiveDescription"
          class="m-0 row d-flex justify-content-start"
        >
          <div
            v-if="isMinRestriction && isMaxRestriction"
            class="dashboard-restrictive-description-wrapper dashboard-restrictive-range ps-0 h-100 pe-2 pb-2"
          >
            <p class="m-0 d-flex">
              <AttributeValueConverter
                :current-attribute-data="attributeData"
                :calculative-dictionary="true"
                :hide-icons="true"
                :raw-value="rangeMinValue"
              />
              &nbsp;
              {{ $t('pagination.to') }}
              &nbsp;
              <AttributeValueConverter
                :current-attribute-data="attributeData"
                :calculative-dictionary="true"
                :hide-icons="true"
                :raw-value="rangeMaxValue"
              />
            </p>
          </div>
          <div
            v-else-if="isMaxRestriction"
            class="dashboard-restrictive-description-wrapper dashboard-restrictive-range h-100 ps-0 pe-2 pb-2"
          >
            <p class="m-0 d-flex">
              {{ $t('pagination.maximum') }}
              &nbsp;
              <AttributeValueConverter
                :current-attribute-data="attributeData"
                :calculative-dictionary="true"
                :hide-icons="true"
                :raw-value="rangeMaxValueDisplayed"
              />
            </p>
          </div>
          <div
            v-else-if="isMinRestriction"
            class="dashboard-restrictive-description-wrapper dashboard-restrictive-range h-100 ps-0 pe-2 pb-2"
          >
            <p class="m-0 d-flex">
              {{ $t('pagination.minimum') }}
              &nbsp;
              <AttributeValueConverter
                :current-attribute-data="attributeData"
                :hide-icons="true"
                :calculative-dictionary="true"
                :raw-value="rangeMinFirstAllowedValue"
              />
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="amountOfSegments !== 0"
        class="p-0 col w-50 d-flex flex-column justify-content-center dashboard-m-12"
      >
        <VueSpeedometer
          :value="speedometerValue"
          :min-value="speedoMinValue"
          :max-value="speedoMaxValue"
          :segments="amountOfSegments"
          :segment-colors="segmentColors"
          :custom-segment-labels="segmentLabels"
          :custom-segment-stops="segmentValues"
          :current-value-text="''"
          :width="150"
          :height="80"
          :ring-width="20"
          :needle-color="'#484848'"
          :needle-transition-duration="800"
          :needle-height-ratio="0.6"
          :fluid-width="false"
          class="w-100 d-flex justify-content-center"
        />
        <div
          class="dashboard-restrictive-range-current-value-wrapper m-0 row justify-content-center ps-2 text-center"
        >
          <AttributeValueConverter
            v-if="!feedbackWarningForEditor"
            :raw-value="currentAttributeValue"
            :current-attribute-data="attributeData"
            :style="{ color: currentValueColor }"
            :hide-icons="true"
            :calculative-dictionary="true"
            class="dashboard-restrictive-range-current-value"
          />
          <span
            v-else
            style="font-size: 0.8rem; color: red"
          >
            {{ feedbackWarningForEditor }}
          </span>
        </div>
      </div>
      <div
        v-else
        class="alert alert-warning"
      >
        No restrictive value for restrictive property
      </div>
      <div
        class="flexible-range-warning-wrapper justify-content-start d-flex align-items-center flex-nowrap p-0"
        :style="{ opacity: opacityFlexibleWarning }"
      >
        <font-awesome-icon
          :style="{ color: warningColor }"
          class="flexible-range-warning-logo"
          icon="exclamation-circle"
        />
        <span class="mx-2 flexible-range-warning-message"><LanguageSelector :to-be-selected="message" /></span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VueSpeedometer from 'vue-speedometer';
import { restrictiveSegments } from '../consultation/Properties/restrictiveSegments';
import { restrictiveSegmentColors } from '../consultation/Properties/restrictiveSegmentColors';
import { mapState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';
import { restrictiveColorScheme } from '../consultation/Properties/restrictiveColorScheme';

export default defineComponent({
  components: { VueSpeedometer },
  props: {
    currentAttributeData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      warningMessage: null,
      warningColor: null,
      currentWarningMessageVariable: null,
    };
  },

  computed: {
    ...mapState(useConsultationStore, [
      'locale'
    ]),

    attributeData() {
      return this.currentAttributeData;
    },

    currentAttributeValue() {
      return this.currentAttributeData.current_calculated_value;
    },

    currentAttributeValueExceedsRestriction() {
      return this.currentAttributeData.current_calculated_value_exceeds_restriction
    },

    showRestrictiveDescription() {
      return this.attributeData.hidden_restrictive_value_label !== true;
    },

    attributeTitle() {
      if (this.attributeData.hasOwnProperty('short_title')) {
        return this.attributeData.short_title;
      }

      return false;
    },

    validMessage() {
      const { valid_value_message } = this.attributeData;

      if (
        typeof valid_value_message === 'object' &&
        valid_value_message !== null &&
        valid_value_message.hasOwnProperty(this.locale)
      ) {
        return valid_value_message[this.locale];
      }

      return false;
    },

    hasValidMessage() {
      return this.validMessage !== false;
    },

    message() {
      if (this.warningMessage === null) {
        return '';
      } else if (typeof this.warningMessage === 'string') {
        return this.warningMessage;
      }

      const exceeds = this.attributeData.current_calculated_value_exceeds_restriction;
      let fallback = '';

      if (exceeds === 'min') {
        fallback = this.$t('pagination.choice_task_under_restrictive_warning_message');
      } else if (exceeds === 'max') {
        fallback = this.$t('pagination.choice_task_over_restrictive_warning_message');
      } else if (exceeds === null && this.hasValidMessage) {
        return this.validMessage;
      }

      return this.warningMessage[this.locale] || fallback;
    },

    speedometerValue() {
      const value = this.currentAttributeValue;
      const { restrictive_min_scale, restrictive_max_scale } = this.attributeData;

      if (
        Number.isInteger(restrictive_min_scale) &&
        value < restrictive_min_scale
      ) {
        return restrictive_min_scale;
      } else if (
        Number.isInteger(restrictive_max_scale) &&
        value > restrictive_max_scale
      ) {
        return restrictive_max_scale;
      }

      return value;
    },

    rangeMinFirstAllowedValue() {
      if (this.isMinRestriction) {
        return this.attributeData.restrictive_min_value;
      }

      if (Number.isInteger(this.attributeData.restrictive_min_scale)) {
        return this.attributeData.restrictive_min_scale;
      }

      return this.attributeData.total_possible_min_value;
    },

    rangeMinValue() {
      if (this.isMinRestriction) {
        return this.attributeData.restrictive_min_value;
      }

      if (Number.isInteger(this.attributeData.restrictive_min_scale)) {
        return this.attributeData.restrictive_min_scale;
      }

      return this.attributeData.total_possible_min_value;
    },

    rangeMaxValue() {
      if (this.isMaxRestriction) {
        return this.attributeData.restrictive_max_value;
      }

      if (Number.isInteger(this.attributeData.restrictive_max_scale)) {
        return this.attributeData.restrictive_max_scale;
      }

      return this.attributeData.total_possible_max_value;
    },

    rangeMaxValueDisplayed() {
      if (this.isMaxRestriction) {
        return this.attributeData.restrictive_max_value;
      }

      return null;
    },

    rangeFlexMinValue() {
      if (this.isMinFlexRestriction) {
        return this.attributeData.flexible_min_value;
      }

      return null;
    },

    rangeFlexMaxValue() {
      if (this.isMaxFlexRestriction) {
        return this.attributeData.flexible_max_value;
      }

      return null;
    },

    isMinRestriction() {
      if (this.attributeData.restrictive_min_value != null) {
        return true;
      }

      return false;
    },

    isMaxRestriction() {
      if (this.attributeData.restrictive_max_value != null) {
        return true;
      }

      return false;
    },

    isMinFlexRestriction() {
      //	min flex restriction is only active when there is a minimum restriction
      if (this.attributeData.flexible_min_value != null && this.isMinRestriction) {
        return true;
      }

      return false;
    },

    isMaxFlexRestriction() {
      //	max flex restriction is only active when there is a maximim restriction
      if (this.attributeData.flexible_max_value != null && this.isMaxRestriction) {
        return true;
      }

      return false;
    },

    absoluteMinValue() {
      if (Number.isInteger(this.attributeData.restrictive_min_scale)) {
        return this.attributeData.restrictive_min_scale;
      }

      return this.attributeData.total_possible_min_value;
    },

    absoluteMaxValue() {
      if (Number.isInteger(this.attributeData.restrictive_max_scale)) {
        return this.attributeData.restrictive_max_scale;
      }

      return this.attributeData.total_possible_max_value;
    },

    speedoMinValue() {
      if (Number.isInteger(this.attributeData.restrictive_min_scale)) {
        return this.attributeData.restrictive_min_scale;
      }

      //	See explanation at speedoMaxValue (same principle but then with the minimum values / restrictions)
      if (this.isMinRestriction) {
        if (this.absoluteMinValue < this.rangeMinValue) {
          return this.absoluteMinValue;
        } else {
          if (this.rangeMinValue > 0) {
            return Math.floor(this.rangeMinValue / 2);
          } else {
            return this.rangeMinValue * 2;
          }
        }
      }

      return this.absoluteMinValue;
    },

    speedoMaxValue() {
      if (Number.isInteger(this.attributeData.restrictive_max_scale)) {
        return this.attributeData.restrictive_max_scale;
      }

      //	When there is a max restriction the maximum displayed value (in the speedometer the right end) is either:
      //	The maximum reachable value (in normal cases) or
      //	When the editor set the max restriction above the reachable max value (could occur and is valid) then the speedometer range is extended
      //	(Hence the rangeMaxValue * 2).
      //	This has been set otherwise the red segment looks out of proportion small and the user won't get an indication of the max limit
      if (this.isMaxRestriction) {
        if (this.absoluteMaxValue >= this.rangeMaxValue) {
          return this.absoluteMaxValue;
        } else {
          return this.rangeMaxValue * 2;
        }
      }

      return this.absoluteMaxValue;
    },

    amountOfSegments() {
      return this.segmentValues.length;
    },

    segmentValues() {
      const segments = restrictiveSegments(this.attributeData);
      return segments;
    },

    segmentColors() {
      return restrictiveSegmentColors(this.attributeData);
    },

    segmentLabels() {
      const labelCount = this.amountOfSegments - 1;

      return Array(labelCount).fill({
        text: '',
        position: 'OUTSIDE',
      });
    },

    restrictiveIcon() {
      if (this.attributeData.restrictive_icon) {
        return this.attributeData.restrictive_icon;
      }

      return false;
    },

    prefixLabel() {
      if (this.attributeData.unit_prefix) {
        return this.attributeData.unit_prefix;
      }

      return '';
    },

    postfixLabel() {
      if (this.attributeData.unit_postfix) {
        return this.attributeData.unit_postfix;
      }

      return '';
    },

    noRestrictiveValueSet() {
      if (!this.isMinRestriction && !this.isMaxRestriction) {
        return true;
      }

      return false;
    },

    rangeIsNotReachable() {
      if (this.isMinRestriction && this.isMaxRestriction) {
        if (this.rangeMinValue > this.absoluteMaxValue) {
          return true;
        } else if (this.rangeMaxValue < this.absoluteMinValue) {
          return true;
        } else if (
          this.rangeMinValue < this.absoluteMinValue &&
          this.rangeMaxValue > this.absoluteMaxValue
        ) {
          return true;
        } else {
          return false;
        }
      }

      return false;
    },

    flexMinIsLessThanRestrictMin() {
      if (
        this.isMinRestriction &&
        this.isMinFlexRestriction &&
        this.rangeFlexMinValue < this.rangeMinValue
      ) {
        return true;
      }

      return false;
    },

    flexMaxIsGreaterThanRestrictMax() {
      if (
        this.isMaxRestriction &&
        this.isMaxFlexRestriction &&
        this.rangeFlexMaxValue > this.rangeMaxValue
      ) {
        return true;
      }

      return false;
    },

    feedbackWarningForEditor() {
      if (this.rangeIsNotReachable) {
        return this.$t('pagination.choice_task_restrictive_range_not_reachable');
      } else if (this.flexMinIsLessThanRestrictMin) {
        return this.$t('pagination.choice_task_flex_min_is_less_than_restrict_min');
      } else if (this.flexMaxIsGreaterThanRestrictMax) {
        return this.$t('pagination.choice_task_flex_max_is_greater_than_restrict_max');
      }

      return null;
    },

    currentValueColor() {
      const scheme = restrictiveColorScheme(this.attributeData.neutral_speedometer_colors);

      if (this.valueIsInRestrictiveRange) {
        return scheme.restrictive; // Red
      } else if (this.valueIsInFlexibleRange) {
        return scheme.flexible; // Orange
      }

      return scheme.allowed; // Green
    },

    currentWarningLogoColor() {
      const scheme = restrictiveColorScheme(this.attributeData.neutral_speedometer_colors);

      if (this.valueIsInRestrictiveRange) {
        return scheme.restrictive; // Red
      } else if (this.valueIsInFlexibleRange) {
        return scheme.flexible; // Orange
      } else if (this.currentAttributeValueExceedsRestriction === null && this.hasValidMessage) {
        return scheme.allowed; // Green
      }

      return null;
    },

    valueIsInFlexibleRange() {
      const exceeds = this.currentAttributeValueExceedsRestriction;
      return exceeds === 'flex-min' || exceeds === 'flex-max';
    },

    valueIsInRestrictiveRange() {
      const exceeds = this.currentAttributeValueExceedsRestriction;
      return exceeds === 'min' || exceeds === 'max';
    },

    currentWarningMessage() {
      const exceeds = this.currentAttributeValueExceedsRestriction;

      if (exceeds === 'flex-min') {
        return this.attributeData.flexible_min_message;
      } else if (exceeds === 'flex-max') {
        return this.attributeData.flexible_max_message;
      } else if (exceeds === 'min') {
        const minMessage = this.attributeData.minimum_value_message;
        const minLabel = this.$t('pagination.choice_task_under_restrictive_warning_message');
        return minMessage || minLabel;
      } else if (exceeds === 'max') {
        const maxMessage = this.attributeData.maximum_value_message;
        const maxLabel = this.$t('pagination.choice_task_over_restrictive_warning_message');
        return maxMessage || maxLabel;
      } else if (exceeds === null && this.hasValidMessage) {
        return this.validMessage;
      }

      return null;
    },

    opacityFlexibleWarning() {
      return this.currentWarningMessage ? '1' : '0';
    },
  },

  watch: {
    currentWarningMessage() {
      if (this.currentWarningMessage !== null) {
        this.warningMessage = this.currentWarningMessage;
      }
    },

    currentWarningLogoColor() {
      if (this.currentWarningLogoColor !== null) {
        this.warningColor = this.currentWarningLogoColor;
      }
    },
  },

  created() {
    // initialize warning message and color here because the watchers don't trigger any warnings / color when the component is created
    this.warningMessage = this.currentWarningMessage;
    this.warningColor = this.currentWarningLogoColor;
  },
});
</script>

<style scoped>
.attribute-value-restrictive-container {
  height: 100%;
  width: 100%;
  background-color: white;
}

.dashboard-restrictive-attribute-icon {
  font-size: 1.4rem;
}

.dashboard-restrictive-attribute-titles,
.dashboard-restrictive-attribute-titles span {
  font-size: 16px;
  color: var(--text-color-heading);
  line-height: 22px;
  font-weight: bold;
}

.dashboard-restrictive-range {
  font-size: 16px;
  color: var(--text-color-content);
  line-height: 20px;
  font-weight: normal;
}

.dashboard-restrictive-range-current-value-wrapper {
  overflow-wrap: break-word;
}

.dashboard-restrictive-description-wrapper {
  overflow-wrap: break-word;
}

.dashboard-restrictive-range-current-value {
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  transition: color 0.5s ease-out;
}

.flexible-range-warning-message {
  transition: all 0.3s ease;
  max-height: 40px;
  font-size: 15px;
  color: var(--text-color-content);
  line-height: 20px;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flexible-range-warning-wrapper {
  width: 100%;
  height: 40px;
}
</style>

<style>
.dashboard-restrictive-range-current-value span.avc-value {
  word-break: break-word;
  hyphens: auto;
}
</style>
