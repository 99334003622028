import { Condition } from "../../types/Condition";
import { checkProject } from "./checkProject";
import { checkQuestions } from "./checkQuestions";

export function hideCurrentEntity(conditions: Condition[], answers) {
  let hideBlockApplied = false;
  let showBlockApplied = false;
  let showConditionAvailable = false;

  for (const condition of conditions) {
    let shouldApply = false;

    // Explicitly store that a show condition is available in the list of conditions
    showConditionAvailable = condition.action === 'show' ? true : showConditionAvailable;

    // Check if the condition should apply.
    if (condition.conditional_type === 'App\\Models\\Option') {
      shouldApply = checkProject(condition, answers);
    } else if (condition.conditional_type === 'App\\Models\\Question') {
      shouldApply = checkQuestions(condition, answers);
    } else {
      continue;
    }

    // The 'show' condition has priority above the 'hide' condition
    // So when 1 'show' condition is met we can exit this loop
    if (shouldApply && condition.action === 'show') {
      showBlockApplied = true;
      break;
    }

    // Else we store if a 'show' condition is not met / 'hide' condition is met
    if (!shouldApply && condition.action === 'show') {
      showBlockApplied = false;
    } else if (shouldApply && condition.action === 'hide') {
      hideBlockApplied = true;
    }
  }

  // Check if the block needs to be hidden
  if (showConditionAvailable && showBlockApplied) {
    return false;
  } else if (showConditionAvailable && !showBlockApplied) {
    return true;
  } else if (hideBlockApplied) {
    return true;
  } else if (!hideBlockApplied) {
    return false;
  }
}
