import { defineStore } from 'pinia';
import { ConsultationSource } from '../consultation/Source/ConsultationSource';

interface State {
  _loading: boolean;
  _error: boolean;
  _empty: boolean | undefined;
}

export const useFetchInstanceStore = defineStore('FetchInstanceStore', {
  state: (): State => {
    return {
      _loading: false,
      _error: false,
      _empty: undefined,
    };
  },

  getters: {
    loading: ({ _loading }) => _loading,
    hasError: ({ _error }) => _error !== false,
    error: ({ _error }) => _error,
    isEmpty: ({ _empty }) => _empty,
  },

  actions: {
    async fetchInstance(slug: string) {
      this._loading = true;

      let response;
      try {
        response = await ConsultationSource.fetch(slug);
        this._empty = response.data.segments.length === 0;
        this._error = false;
      } catch (error) {
        this._error = error;
      } finally {
        this._loading = false;
      }

      if (this._error) {
        return undefined;
      }

      return response.data
    },
  },
});
