<template>
  <div class="checkbox-answer-option">
    <label
      class="checkbox-wrapper position-relative d-flex align-items-center"
      @pointerup="userClickedCheckbox()"
    >
      <input
        id="index"
        class="checkbox-input"
        type="checkbox"
        value="answer"
      >
      <div
        class="checkmark-wrapper d-flex align-items-center position-relative me-3"
      >
        <span class="checkbox" />
        <span
          v-show="checked"
          class="checkmark"
        />
      </div>
      <span class="answer-option">
        {{ $t('pagination.choice_task_skip_choice_task_checkbox_title') }}
      </span>
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useConsultationStore } from '../stores/useConsultationStore';
import { mapActions, mapState, mapWritableState } from 'pinia';

export default defineComponent({
  data() {
    return {
      checked: false,
    };
  },

  computed: {
    ...mapWritableState(useConsultationStore, [
      'skipChoiceTask'
    ]),

    ...mapState(useConsultationStore, [
      'stage',
      'stageId',
      'getAnswerData'
    ]),

    skipChoiceTaskQuestionObject() {
      return null;

      // TODO: this gives an error since block ID is null
      // const currentBlock = this.blockData.find(
      //   (block) => block.id === this.blockId
      // );

      // if (!currentBlock) {
      //   log.error('Could not find block with id: ' + this.blockId);
      //   return;
      // }

      // if (!currentBlock.stage_data.questions) {
      //   log.error('Could not find questions in block with id: ' + this.blockId);
      //   return;
      // }

      // return currentBlock.stage_data.questions.find(
      //   (question) => question.field_type === 'skip_choice_task'
      // );
    },
  },
  created() {
    this.checked = this.skipChoiceTask;
  },

  methods: {
    ...mapActions(useConsultationStore, [
      'setAnswerData'
    ]),

    async userClickedCheckbox() {
      this.checked = !this.checked;
      this.skipChoiceTask = this.checked;

      const answerData = this.getAnswerData[this.stageId].answers;

      Object.values(answerData).forEach((option) => {
        option.skipped = this.checked;
      });

      const payload = {
        stageId: this.stageId,
        data: answerData
      };

      this.setAnswerData(payload);

      // const question = this.skipChoiceTaskQuestionObject;

      // if (question) {
      //   await this['conditions/setConditionalValue']({
      //     question,
      //     value: this.checked,
      //   });
      //   await this['conditions/processBlockConditions']();
      // }
    },
  },
});
</script>

<style scoped>
.checkbox-wrapper {
  height: 100%;
  position: relative;
  cursor: pointer;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-wrapper input {
  position: relative;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark-wrapper {
  min-height: 19px;
  min-width: 19px;
}

/* Create a custom checkbox */
.checkbox {
  box-sizing: content-box;
  position: absolute;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: white;
  border: solid #e9eff4;
  border-radius: 6px;
}

/* On mouse-over, add a grey background color */
.checkbox-wrapper:hover input ~ .checkmark {
  background-color: #ccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark {
  box-sizing: content-box;
  position: absolute;
  left: 0;
  top: -2px;
}

/* Style the checkmark/indicator */
.checkbox-wrapper .checkmark {
  left: 5px;
  bottom: 2px;
  width: 5px;
  height: 14px;
  border: solid #fd1f9b;
  border-radius: 2px;
  border-width: 0 5px 5px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.checkbox-answer-option {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  height: 100%;
  min-height: 45px;
  box-sizing: border-box;
  white-space: nowrap;
}

.answer-option {
  color: var(--text-color-content);
}
</style>
