import { AnswerData } from "./AnswerData";

export class ChoiceTaskMotivationAnswerData extends AnswerData {
  /**
   * The slug for the choice task form.
   */
  protected endpoint = 'choiceTask';

  public formatData(answers: any) {
    const options = {};
    let versionId = -1;
    let skipped = false;
    let meta = {};

    for (const [key, answerData] of Object.entries(answers)) {
      if (key.includes('choice-task')) {
        versionId = answerData.versionId;
        meta = answerData.meta;
      }

      for (const [id, option] of Object.entries(answerData.answers)) {
        if (options[option.id] === undefined) {
          options[option.id] = {
            id: option.id,
            step: option.step,
            quantity: option.quantity,
            user_motivation: option.user_motivation,
            last_question: option.last_question,
          }
        } else {
          options[option.id] = {
            ...option[option.id],
            id: option.id,
            step: option.step,
            quantity: option.quantity,
            user_motivation: option.user_motivation,
            last_question: option.last_question,
          }
        }

        if (option.skipped === true) {
          skipped = true;
        }
      }
    }

    const motivations = Object.values(options)

    return {
      blockId: this.blockId,
      answerData: {
        skipped_choice_task: skipped,
        selectedProjects: motivations,
        versionId: versionId,
        meta: meta,
      },
    };
  }
}
