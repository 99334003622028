<template>
  <div
    v-if="optionCardAttributes"
    class="d-flex align-items-start align-self-end"
    :class="{ 'flex-column': hasSoftProperties }"
  >
    <div
      v-for="property in optionCardAttributes"
      :key="property.id"
      class="restrictive-attributes-wrapper me-2 d-flex align-items-center"
    >
      <font-awesome-icon
        v-if="returnRestrictiveIcon(property)"
        :style="{ color: '#6d6d6d' }"
        :icon="returnRestrictiveIcon(property)"
        class="fa-1x me-1"
      />
      <p class="restrictive-attribute-value p-0 m-0">
        <AttributeValueConverter
          :current-attribute-data="property"
          :option-id="optionId"
        />
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { restrictiveProperties } from '../consultation/Properties/restrictiveProperties';
import { optionCardProperties } from '../consultation/Properties/optionCardProperties';
import { useConsultationStore } from '../stores/useConsultationStore';
import { mapState } from 'pinia';

export default defineComponent({
  props: {
    optionId: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapState(useConsultationStore, [
      'locale',
      'stageProperties',
    ]),

    hasSoftProperties() {
      for (const property of this.optionCardAttributes) {
        const dict = property.attribute_value_dictionary;

        if (typeof dict === 'object' && dict[this.locale] !== undefined) {
          return true;
        }
      }

      return false
    },

    optionCardAttributes() {
      return optionCardProperties(this.restrictions)
    },

    restrictions() {
      return restrictiveProperties(this.stageProperties);
    },
  },

  methods: {
    returnRestrictiveIcon(attribute) {
      if (!attribute.restrictive_icon) {
        return false;
      }

      return attribute.restrictive_icon;
    },
  },
});
</script>
