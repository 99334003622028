import './assets/style/main';
import { createApp } from 'vue';
import { createPinia } from 'pinia'
import App from './App.vue';
import VueMatomo from 'vue-matomo';
import vueVimeoPlayer from 'vue-vimeo-player';
import router from './router';
import { MatomoConfig } from './consultation/Matomo';
import { i18n } from './i18n/i18n';
import translate from './plugins/translate'
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faLinkedinIn, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

library.add(fas);
library.add(faWhatsapp)
library.add(faLinkedinIn)
library.add(faFacebookF)
library.add(faTwitter)

// //  Base components
import BaseWiki from './components/BaseWiki.vue';

// //  utility components
import AttributeValueConverter from './components/AttributeValueConverter.vue';
import LanguageSelector from './components/LanguageSelector.vue';

import { initializeSentry } from './initializeSentry';

import log from 'loglevel';
const logLevel = import.meta.env.VITE_LOG_LEVEL
  ? import.meta.env.VITE_LOG_LEVEL
  : 'INFO';
log.setLevel(logLevel);

const pinia = createPinia();
const app = createApp(App);

// Initialize Sentry error logging
import { getCurrentEnvironment, Environments } from './settings';
if (getCurrentEnvironment() === Environments.PRODUCTION) {
  initializeSentry(app, router);
}

// Add plugins to the app
app.use(pinia);
app.use(router);
app.use(i18n);
app.use(translate); // Add local translation plugin for translatable consultation fields
app.use(vueVimeoPlayer);
app.use(VueMatomo, MatomoConfig);

// Load global components
app.component('BaseWiki', BaseWiki);
app.component('LanguageSelector', LanguageSelector);
app.component('AttributeValueConverter', AttributeValueConverter);

// Load 3rd party global components
app.component('FontAwesomeIcon', FontAwesomeIcon);
app.component('FontAwesomeLayers', FontAwesomeLayers);
app.component('FontAwesomeLayersText', FontAwesomeLayersText);

// And mount!
app.mount('#init');
