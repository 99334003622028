<template>
  <button @click="$emit('my-event')">
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['my-event'],
});
</script>

<style scoped>
button {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  background-color: #017cf7;
  border-radius: 15px;
  border-style: none;
  box-shadow: 0 2px 4px rgba(119, 119, 119, 0.29);
  color: white;
  padding: 0.5rem 2rem;
  cursor: pointer;
}

button:hover,
button:active {
  background-color: #5c0556;
  border-color: #5c0556;
  background-color: var(--bs-success);
  color: black;
}
</style>
