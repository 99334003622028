<template>
  <div>
    <BaseDialog
      :show="remoteShow"
      :title="title"
      :icons="showIcon"
      :single-button="true"
      @my-event="closeWarningWindow"
    >
      <template #icons>
        <div v-if="showIcon">
          <div
            v-if="showDoubleIcon"
            class="d-flex justify-content-center fa-3x"
          >
            <div class="me-3">
              <font-awesome-layers class="mobile-icon">
                <font-awesome-icon icon="mobile-alt" />
                <font-awesome-icon
                  icon="circle"
                  transform="shrink-6 right-5 up-5"
                  style="color: white"
                />
                <font-awesome-icon
                  icon="times-circle"
                  transform="shrink-6 right-5 up-5"
                  style="color: #d31300"
                />
              </font-awesome-layers>
            </div>
            <div>
              <font-awesome-layers class="laptop-icon">
                <font-awesome-icon icon="laptop" />
                <font-awesome-icon
                  icon="circle"
                  transform="shrink-6 right-10 up-6"
                  style="color: white"
                />
                <font-awesome-icon
                  icon="check-circle"
                  transform="shrink-6 right-10 up-6"
                  style="color: #b6e723"
                />
              </font-awesome-layers>
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-content-center fa-3x"
          >
            <div>
              <font-awesome-layers class="mobile-landscape-icon">
                <font-awesome-icon
                  icon="mobile-alt"
                  class="fa-2x"
                  transform="rotate-90 up-3"
                />
                <font-awesome-icon
                  icon="sync"
                  transform="shrink-5 rotate-90 right-5 up-6"
                />
              </font-awesome-layers>
            </div>
          </div>
        </div>
      </template>
      <span v-show="message"><LanguageSelector :to-be-selected="message" /></span>
    </BaseDialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useConsultationStore } from '../stores/useConsultationStore';
import { mapState, mapWritableState } from 'pinia';
import BaseDialog from './BaseDialog.vue';

export default defineComponent({
  components: {
    BaseDialog,
  },
  computed: {
    ...mapWritableState(useConsultationStore, [
      'showWarning',
    ]),

    ...mapState(useConsultationStore, [
      'warningData',
    ]),

    showIcon() {
      return this.warningData.showIcon;
    },

    showDoubleIcon() {
      return this.warningData.showDoubleIcon;
    },

    title() {
      if (this.warningData.hasOwnProperty('title')) {
        return this.warningData.title;
      }

      return false;
    },

    message() {
      if (this.warningData.hasOwnProperty('message')) {
        return this.warningData.message;
      }

      return false;
    },

    remoteShow() {
      return this.showWarning;
    },
  },
  methods: {
    closeWarningWindow() {
      this.showWarning = false;
    },
  },
});
</script>
