import { BlockOutro } from "../../../types/BlockOutro";

export const sharingPlatforms = {
  WHATSAPP: {
    name: 'WhatsApp',
    color: '#23D366',
    icon: 'whatsapp',
    share_url: 'https://api.whatsapp.com/send?text=',
  },
  LINKEDIN: {
    name: 'LinkedIn',
    color: '#0177B5',
    icon: 'linkedin-in',
    share_url: 'https://www.linkedin.com/shareArticle?url=',
  },
  FACEBOOK: {
    name: 'Facebook',
    color: '#4267B2',
    icon: 'facebook-f',
    share_url: 'https://www.facebook.com/sharer/sharer.php?u=',
  },
  TWITTER: {
    name: 'Twitter',
    color: '#1EA1F2',
    icon: 'twitter',
    share_url: 'https://twitter.com/share?url=',
  },
  EMAIL: {
    name: 'E-mail',
    color: '#484848',
    icon: 'envelope',
    share_url: 'mailto:',
  },
};


export function OutroAdapter(data: BlockOutro) {
  if (data.stages.length === 0) {
    // No stages, return empty array
    return [];
  }

  const stages = [];

  for (const [index, stage] of data.stages.entries()) {
    const isOutro = index === data.stages.length - 1;

    if (isOutro) {
      stages.push({
        id: `outro-${stage.id}`,
        stageId: stage.id,
        stageType: 'intro',
        segmentType: 'Page',
        blockId: data.id,
        blockType: data.block_type,
        component: 'SegmentOutro',
        hidden: false,
        fields: {
          title: stage.title,
          content: stage.content,
          socialShare: data.settings.has_social_share,
          panelRedirectUrl: data.settings.panel_redirect_url,
          nonPanelRedirectUrl: data.settings.non_panel_redirect_url,
          panelMessage: data.settings.panel_message,
          nonPanelMessage: data.settings.non_panel_message,
          mailchimpEmbedCode: data.settings.mailchimp_embed_code,
          lapostaEmbedLink: data.settings.laposta_embed_link,
          sharing: sharingPlatforms,
        }
      });
    } else {
      stages.push({
        id: `intro-${stage.id}`,
        stageId: stage.id,
        stageType: 'intro',
        segmentType: 'Page',
        blockId: data.id,
        blockType: data.block_type,
        component: 'PageSegment',
        hidden: false,
        fields: {
          title: stage.title,
          content: stage.content,
          video: stage.video,
          videoMobile: stage.video_mobile,
        }
      });
    }
  }

  return stages;
}
