<template>
  <QuestionWrapper
    :question="currentQuestionData"
    :current-number="currentQuestionDisplayNumber"
    :total-number="totalQuestionNumber"
  >
    <input
      v-model.trim="answerData.user_answered_string"
      :style="borderColor"
      :placeholder="placeholder"
      autocomplete="off"
      type="text"
      class="question-border input-text"
      @change="userHasChosenAnswer(answerData.user_answered_string)"
      @input="userHasChosenAnswer(answerData.user_answered_string)"
      @click="triggerCheckPreviousRequired()"
    >
    <div
      :style="textOpacity"
      class="alert-required-message my-2"
    >
      {{ validationMessage }}
    </div>
  </QuestionWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import log from 'loglevel';
import QuestionWrapper from './QuestionWrapper.vue';
import { validateAnswer } from '../consultation/Validation/validateAnswer';
import { mapState } from 'pinia';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    QuestionWrapper,
  },
  props: {
    currentQuestionData: {
      type: Object,
      required: true,
    },
    showUserInputIsRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentQuestionNumber: {
      type: Number,
      required: true,
    },
    currentQuestionDisplayNumber: {
      type: Number,
      required: true,
    },
    totalQuestionNumber: {
      type: Number,
      required: true,
    },
    currentUserData: {
      type: Object,
      required: true,
    },
    validationLabel: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  emits: ['sendCurrentUserData'],

  data() {
    return {
      answerData: {},
    };
  },

  computed: {
    ...mapState(useConsultationStore, [
      'locale'
    ]),

    placeholder() {
      if (this.currentQuestionData.placeholder === undefined) {
        return '';
      }

      return this.currentQuestionData.placeholder[this.locale];
    },

    validationMessage() {
      if (this.showUserInputIsRequired) {
        return this.validationLabel;
      }

      if (this.currentQuestionData.validation !== undefined) {
        return this.currentQuestionData.validation;
      }

      return '';
    },

    hasErrors() {
      if (this.showUserInputIsRequired) {
        return true;
      }

      if (this.currentQuestionData.validation !== undefined) {
        return true;
      }

      return false;
    },

    borderColor() {
      return this.hasErrors
        ? { borderColor: '#D40005' }
        : { borderColor: '#E6E6E6' };
    },

    textOpacity() {
      return this.hasErrors ? { opacity: '1' } : { opacity: '0' };
    },
  },

  created() {
    this.answerData = this.currentQuestionData;

    // This should always be the case since in the store we create the array with userData for questions
    // with at least an id and user_answered: false
    if (this.currentUserData) {
      if (
        this.currentUserData.user_has_answered &&
        this.currentUserData.raw_input_text
      ) {
        this.userHasChosenAnswer(this.currentUserData.raw_input_text);
      } else {
        this.answerData.user_has_answered = false;
        this.answerData.user_answered_string = null;
        this.answerData.raw_input_text = null;
      }
    } else {
      log.warn(
        'InputTypeText: Something is wrong with the creation of userDataQuestions'
      );
      this.answerData.user_has_answered = false;
      this.answerData.user_answered_string = null;
      this.answerData.raw_input_text = null;
    }

    this.$emit('sendCurrentUserData', this.answerData);
  },

  methods: {
    userHasChosenAnswer(value) {
      if (value === '' || value === null) {
        this.answerData.user_answered_string = null;
        this.answerData.raw_input_text = null;
        this.answerData.user_has_answered = false;
        this.alertUser = true;
      } else {
        this.alertUser = false;
        this.answerData.user_has_answered = true;
        this.answerData.raw_input_text = value;
        this.answerData.user_answered_string = value;
      }

      this.validate();

      this.$emit('sendCurrentUserData', this.answerData);
    },

    validate() {
      validateAnswer(this.answerData.user_answered_string, this.currentQuestionData, this.locale);
    },

    triggerCheckPreviousRequired() {
      this.answerData.question_number = this.currentQuestionNumber;
      this.$emit('sendCurrentUserData', this.answerData);
    },
  },
});
</script>

<style scoped>
label {
  width: 100%;
}

input.form-control {
  resize: none;
  max-width: 800px;
}
</style>
