<template>
  <div>
    <BaseDialog
      :show="remoteShow"
      :title="title"
      :single-button="true"
      @my-event="closeHelpWindow"
    >
      <span v-show="content">
        <LanguageSelector :to-be-selected="content" />
      </span>
    </BaseDialog>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import { useConsultationStore } from '../stores/useConsultationStore';
import BaseDialog from './BaseDialog.vue';

export default defineComponent({
  components: {
    BaseDialog,
  },
  setup() {
    const consultationStore = useConsultationStore();
    return { consultationStore };
  },
  computed: {
    ...mapState(useConsultationStore, [
      'infoMode',
      'showHelp',
      'showPrivacyStatement',
      'getHelpData',
      'getPrivacyStatement',
      'getAccessibilityInfo',
      'showAccessibilityInfo'
    ]),

    content() {
      if (this.infoMode === 'help') {
        return this.getHelpData;
      } else if (this.infoMode === 'statement') {
        return this.getPrivacyStatement;
      } else if (this.infoMode === 'accessibility') {
        return this.getAccessibilityInfo;
      }

      return false;
    },

    title() {
      if (this.infoMode === 'help') {
        return this.$t('pagination.help');
      } else if (this.infoMode === 'statement') {
        return this.$t('privacyStatement')
      } else if (this.infoMode === 'accessibility') {
        return this.$t('accessibility_info.title')
      }

      return false;
    },

    remoteShow() {
      if (this.infoMode === 'help') {
        // this.setShowPrivacyStatement(false);
        return this.showHelp;
      } else if (this.infoMode === 'statement') {
        // this.setShowHelp(false);
        return this.showPrivacyStatement;
      } else if (this.infoMode === 'accessibility') {
        return this.showAccessibilityInfo;
      } else {
        return false;
      }
    },
  },

  methods: {
    closeHelpWindow() {
      this.consultationStore.$patch({
        showHelp: false,
        showPrivacyStatement: false,
        showAccessibilityInfo: false,
      });
    },
  },
});
</script>
